export const required = (value: string) => {
  return (value && typeof value === 'string' && !value.match(/^\s+$/)) ||
    (value && typeof value !== 'string')
    ? undefined
    : 'Required';
};

export const requiredArr = (value: any) => {
  debugger;
};
export const email = (value: string) =>
  value === '' ||
  value === null ||
  value === undefined ||
  (value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
    ? undefined
    : 'Enter a valid e-mail address';

export const password = (value: string) =>
  value === '' ||
  value === null ||
  value === undefined ||
  (value && value.length >= 8)
    ? undefined
    : `The password must be at least 8 characters.`;

export const zip = (value: string) =>
  value === '' ||
  value === null ||
  value === undefined ||
  (value.length === 5 && /[0-9]$/i.test(value))
    ? undefined
    : 'Enter a valid zip';

export const passwordsCheck = (value: string, values: any) =>
  value !== values.password ? 'Passwords do not match' : undefined;

export const dataCheck = (value: string, values: any) => {
  const endDate = new Date(value);
  const startDate = new Date(values.startDate);

  return +endDate <= +startDate
    ? 'End data should be greater than start data'
    : undefined;
};
