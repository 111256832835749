import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form, submit } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';
import { StateType } from '../../../../../redux/store';
import styles from './NotificationsTab.module.scss';
import {
  getCategories,
  createLiveClass,
} from '../../../../../redux/actions/liveClassesActions';
import AutocompleteInput from '../../../../common/AutocompleteInput/AutocompleteInput';

import Checkbox from '@material-ui/core/Checkbox';
import SelectableCheckbox from '../../../../common/SelectableCheckbox/SelectableCheckbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
  setNotifications,
  getNotifications,
} from '../../../../../redux/actions/userActions';
import PersonIcon from '@material-ui/icons/Person';
import Button from '../../../../common/Button/Button';
import UserPhoto from '../UserPhoto/UserPhoto';

export type NotificationsTabType = {
  classChanges: boolean;
  classCancellation: boolean;
};

const NotificationsTab: React.FC<InjectedFormProps<NotificationsTabType>> = ({
  handleSubmit,
}) => {
  const [changes, setChanges] = useState(false);
  const [cancel, setCancel] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { categories, newClass } = useSelector(
    (state: StateType) => state.liveClasses
  );
  const { message } = useSelector((state: StateType) => state.alert);
  const { notifications, user } = useSelector((state: StateType) => state.user);

  useEffect(() => {
    notifications.canceled_by_instructor &&
      setCancel(Boolean(notifications.canceled_by_instructor));
    notifications.class_changed &&
      setChanges(Boolean(notifications.class_changed));
  }, [notifications]);

  const getNotificationsAndSetTab = async () => {
    await dispatch(getNotifications());
  };

  const onSubmit = () => {
    handleSubmit((value) => {
      dispatch(
        setNotifications({
          class_changed: Number(Boolean(value.classChanges)),
          canceled_by_instructor: Number(Boolean(value.classCancellation)),
        })
      );
    })();
  };

  const FrequencyCheck = ({ input, checked, setTrueFalse }: any) => {
    useEffect(() => {
      input && input.onChange(checked);
    }, []);

    return (
      <div className={styles.frequencyWrapper}>
        <div>
          <Checkbox
            checked={checked}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            onChange={(e: any) => {
              input && input.onChange(!checked);
              setTrueFalse();
            }}
            classes={{ checked: styles.checkedBox }}
          />
          <p>By email</p>
        </div>
      </div>
    );
  };

  return (
    <Form onSubmit={onSubmit} className={styles.form}>
      <div className={styles.inputs}>
        <h3>Live class changes:</h3>
        <Field
          component={FrequencyCheck}
          name='classChanges'
          label=''
          type='checkbox'
          validate={[]}
          checked={changes}
          setTrueFalse={() => setChanges(!changes)}
        />
        <h3>Sign up cancellation:</h3>
        <Field
          component={FrequencyCheck}
          name='classCancellation'
          label=''
          type='checkbox'
          validate={[]}
          checked={cancel}
          setTrueFalse={() => setCancel(!cancel)}
        />
      </div>
    </Form>
  );
};

export default reduxForm<NotificationsTabType>({
  form: 'notificationsTab',
})(NotificationsTab);
