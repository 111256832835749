import React from "react";
import { Redirect } from "react-router-dom";

const DefaultRoute: React.FC = () => {
  let path = "user/home";

  return <Redirect to={path} />;
};

export default DefaultRoute;
