import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { Router, Route, BrowserRouter, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import App from './App';
import store from './redux/store';
import { theme } from './assets/styles/theme';

import './index.scss';
import * as serviceWorker from './serviceWorker';

import defaultExport from 'laravel-echo';

/* WebSockets connection */
export const Pusher = require('pusher-js');

export const Echo = new defaultExport({
  broadcaster: 'pusher',
  key: process.env.REACT_APP_PUSHER_APP_KEY ?? 'goteach',
  wsHost: process.env.REACT_APP_WS_HOST ?? 'localhost',
  wsPort: process.env.REACT_APP_WS_PORT ?? 6001,
  wssPort: process.env.REACT_APP_WS_PORT ?? 6001,
  forceTLS: process.env.REACT_APP_WS_FORCE_TLS !== 'false',
  disableStats: true,
  authEndpoint: `${process.env.REACT_APP_SERVER_HOST}/api/v1/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  },
});
/* End of WebSockets connection */

const createHistory = require('history').createBrowserHistory;
export const history = createHistory();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <App />
        </Router>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
