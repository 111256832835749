import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StateType } from '../../../../../redux/store';
import {
  subscribeToClass,
  getUserClasses,
  getClass,
  getClassUsersInfo,
} from '../../../../../redux/actions/liveClassesActions';
import styles from './ModalWindow.module.scss';
import Modal from '@material-ui/core/Modal';
import Button from '../../../../common/Button/Button';
import ReplenishForm from '../../../../pages/MainPage/Wallet/ReplenishForm/ReplenishForm';
import { submit } from 'redux-form';
import MainLoader from '../../../../common/MainLoader/MainLoader';
import { clearStripeObj } from '../../../../../redux/reducers/user';
import { ReactComponent as Coins } from '../../../../../assets/svg/Coins.svg';

const ModalWindow: React.FC<any> = ({ open, signUpFunc, selectedClass, price, closeModal, customClass }) => {
  const { balance, balanceReplenished, user, stripe } = useSelector((state: StateType) => state.user);
  const { replenishForm } = useSelector((state: StateType) => state.form);
  const [showWallet, setShowWallet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [difference, setDifference] = useState(0);
  const [disable, setDisable] = useState(false);
  const [insufficientBalance, setInsufBalance] = useState(false);
  const [doSubscribe, setDoSubscribe] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (replenishForm?.values?.balance) {
      replenishForm.values.balance >= difference ? setDisable(false) : setDisable(true);
    }
  }, [replenishForm]);

  useEffect(() => {
    if (showWallet) {
      setDifference(selectedClass.price - balance);
      setDisable(true);
      if (!user.stripe_customer) {
        replenishForm?.values?.balance > difference ? setDisable(false) : setDisable(true);
      } else {
        setDisable(true);
      }
    }
  }, [showWallet]);

  // useEffect below checks the incoming data after click on 'Connect stripe'
  useEffect(() => {
    if (stripe && stripe.url) {
      linkHandler();
    }
  }, [stripe]);

  const linkHandler = async () => {
    await window.open(stripe.url);
    await dispatch(clearStripeObj());
  };

  useEffect(() => {
    if (selectedClass.price) {
      balance < selectedClass.price ? setInsufBalance(true) : setInsufBalance(false);
    }
    return () => {
      setShowWallet(false);
      setDifference(0);
      setDisable(false);
      setInsufBalance(false);
    };
  }, [open]);

  const handleCloseModal = () => {
    closeModal();
  };

  useEffect(() => {
    if (doSubscribe && balanceReplenished && selectedClass.id && balance >= selectedClass.price) {
      doSubscribeScope(selectedClass.id);
      setDoSubscribe(false);
    }
  }, [doSubscribe, balanceReplenished, selectedClass, balance]);

  const doSubscribeScope = async (selectedClassId: any) => {
    await dispatch(subscribeToClass(selectedClass.id));
    await dispatch(getUserClasses(null));
    await dispatch(getClassUsersInfo(selectedClass.id));
    closeModal();
    signUpFunc(true);
  };

  const closeModalAndSubscribe = async (showWallet?: boolean) => {
    // await setLoading(true);
    if (showWallet) {
      await dispatch(submit('replenishForm'));
      setDoSubscribe(true);
    } else {
      selectedClass.id && doSubscribeScope(selectedClass.id);
    }
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className={`${customClass} ${styles.modalRoot}`}>
      <div className={styles.modalWindow}>
        <MainLoader loading={loading} />
        <Coins className={`${styles.coins} ${showWallet ? styles.showWalletCoin : ''}`} />
        <div className={`${styles.internalWrapper} ${showWallet ? styles.paddingStripe : ''}`}>
          <div className={styles.info}>
            <h2>Sign up for the class</h2>
            {selectedClass.price && (
              <>
                <div className={styles.classPrice}>
                  <p>Live class price:</p>
                  <span />
                  <p>${selectedClass.price}</p>
                </div>

                <div className={styles.balanceAmount}>
                  <p>Your balance:</p>
                  <span /> <p>${balance - selectedClass.price}</p>
                </div>

                <div className={styles.totalAmount}>
                  <p>Total:</p>
                  <span /> <p>${selectedClass.price}</p>
                </div>
              </>
            )}
          </div>
          {/*  {insufficientBalance && 'You have insufficient balance'} */}
          {showWallet && (
            <div className={`${styles.replenishForm} ${user.stripe_customer ? styles.hideButton : styles.showButton}`}>
              <p>You need at least {difference}$ to cover the difference</p>
              <ReplenishForm minValue={difference} />
            </div>
          )}
          <div className={styles.buttonsWrapper}>
            {(!showWallet || (showWallet && user.stripe_customer)) && (
              <Button
                color='primary'
                variant='contained'
                disabled={disable}
                onClick={() => {
                  insufficientBalance ? setShowWallet(true) : closeModalAndSubscribe();

                  user.stripe_customer && showWallet && closeModalAndSubscribe(showWallet);
                }}
                customClass={styles.signUpButton}
              >
                {insufficientBalance ? 'Pay with credit card' : 'Sign up'}
              </Button>
            )}
          </div>
          <div className={styles.securePayment}>
            <img src={require('../../../../../assets/images/security.png')} alt='security' />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalWindow;
