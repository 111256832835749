import React from 'react';
import styles from './StatusFlag.module.scss';
import { ReactComponent as Upcoming } from '../../../assets/svg/liveClasses/clock.svg';
import { ReactComponent as Running } from '../../../assets/svg/liveClasses/signal.svg';
import { ReactComponent as Failed } from '../../../assets/svg/liveClasses/warning.svg';
import { ReactComponent as Canceled } from '../../../assets/svg/liveClasses/canceled.svg';
import { ReactComponent as Passed } from '../../../assets/svg/liveClasses/pin.svg';

type StatusFlagType = {
  customStyles?: Object;
  status: string;
};

const StatusFlag: React.FC<StatusFlagType> = ({ customStyles, status }) => {
  const StatusIcon = () => {
    switch (status) {
      case 'Upcoming':
        return <Upcoming />;
      case 'Running':
        return <Running />;

      case 'Failed':
        return <Failed />;
      case 'Passed':
        return <Passed />;
      case 'Canceled':
        return <Canceled />;

      default:
        return <Upcoming />;
    }
  };

  return (
    <div
      className={`${styles.statusFlag} ${styles[status]} ${
        customStyles && customStyles
      }`}
    >
      <StatusIcon />
      {status}
    </div>
  );
};

export default StatusFlag;
