import React, { useEffect, useState, ChangeEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { StateType } from '../../../../../../redux/store';
import FileInput from '../../../../../common/FileInput/FileInput';
import { updateProfile } from '../../../../../../redux/actions/userActions';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';

import styles from './UserPhotoForm.module.scss';

export type UserPhotoType = {
  photo: string;
};

const UserPhotoForm: React.FC<InjectedFormProps<UserPhotoType>> = ({
  handleSubmit,
}) => {
  const [file, setFile] = useState({} as any);
  const [photoString, setPhoto] = useState('' as any);
  const [formObj, setFormObj] = useState<UserPhotoType>({} as UserPhotoType);
  const { user } = useSelector((state: StateType) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setFormObj({} as UserPhotoType);
    };
  }, []);

  useEffect(() => {
    if (user.photo) {
      setFormObj({
        photo: user.photo as string,
      });
    }
  }, [user]);

  const onSubmit = () => {
    handleSubmit((value) => {
      dispatch(updateProfile(value));
    })();
  };

  return (
    <Form onSubmit={onSubmit} className={styles.form}>
      <Tooltip
        title={'Delete photo'}
        className={styles.deleteButton}
        classes={{ tooltip: styles.tooltip }}
      >
        <IconButton
          aria-label='delete'
          onClick={() =>
            user.photo && dispatch(updateProfile({ clear_photo: 'yes' }))
          }
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
      <Field
        name='photo'
        component={FileInput}
        type='file'
        onChangeField={(e: any) => setFile(e)}
        setPhoto={(photo: string) => {
          setPhoto(photo);
        }}
        photoPreview={user.md_photo}
        isUserPhoto
        defaultPreview={user.photo}
      />
    </Form>
  );
};

export default reduxForm<UserPhotoType>({
  form: 'userPhoto',
})(UserPhotoForm);
