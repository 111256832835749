import React, { useState, useRef, useEffect } from 'react';
import styles from './ChatInput.module.scss';
import { Field, reduxForm, InjectedFormProps, Form, reset, untouch } from 'redux-form';
import Input from '../../../../../../common/Input/Input';
import Picker, { IEmojiPickerProps } from 'emoji-picker-react';
import { IconButton, Button, Tooltip, ClickAwayListener, Popper } from '@material-ui/core';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import {useDispatch, useSelector} from 'react-redux';
import { updateChatMessages } from '../../../../../../../redux/reducers/liveClasses';
import { required } from '../../../../../../../helpers/validate';
import {sendClassChatMessage} from "../../../../../../../redux/actions/liveClassesActions";
import {StateType} from "../../../../../../../redux/store";

type ChatInputType = {
  chatInput: string;
};

const ChatInput: React.FC<InjectedFormProps<ChatInputType>> = ({ handleSubmit }) => {
  const [chosenEmoji, setChosenEmoji] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const [useSystemEmoji, setUseSystemEmoji] = useState(false);
  const [clearValue, setClearValue] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const [anchorPoper, setAnchorPoper] = useState<any>();
  const buttonRef: any = useRef();
  const dispatch = useDispatch();

  const { liveClass } = useSelector((state: StateType) => state.liveClasses);

  useEffect(() => {
    const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    isMac ? setUseSystemEmoji(true) : setUseSystemEmoji(false);
  }, []);

  useEffect(() => {
    if (chosenEmoji) {
      setChatMessage(chatMessage + chosenEmoji);
    }
  }, [chosenEmoji]);

  const onEmojiClick = (event: any, emojiObject: any) => {
    setChosenEmoji(emojiObject.emoji);
    setShowPicker(false);
  };

  const EmojiPicker = () => {
    const pickerStyles: IEmojiPickerProps['pickerStyle'] = {
      zIndex: '20',
      marginTop: '-270px',
      marginLeft: '-20px',
    };
    return <Picker onEmojiClick={onEmojiClick} pickerStyle={pickerStyles} disableAutoFocus native={useSystemEmoji} />;
  };
  const handleTooltipClose = () => {
    setShowPicker(false);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    handleSubmit(async (value: any) => {
      await setChatMessage('');
      await setClearValue(true);
      await setChosenEmoji('');
      value.chatInput && (await dispatch(sendClassChatMessage({classId: liveClass.id, fields: {content: value.chatInput}})));
      await dispatch(untouch('chatInput', 'chatInput'));
      await setClearValue(false);
    })();
  };

  const handleFocusInputKeyDown = (event: any, value:string) => {
    if (event.keyCode === 13) {
      onSubmit(event);
    }
  }

  return (
    <Form className={styles.wrapper} onSubmit={onSubmit} onKeyDown={(event: any) => handleFocusInputKeyDown(event, chatMessage)}>
      <Field
        component={Input}
        name='chatInput'
        label='Text your message'
        type='text'
        maxLength={300}
        parentStyle={styles.input}
        isMultiline={true}
        rows={5}
        defaultValue={chatMessage}
        handleChange={(e: any) => setChatMessage(e)}
        clearValue={clearValue}
      />
      <ClickAwayListener onClickAway={() => handleTooltipClose()}>
        <div className={styles.buttonsWrapper}>
          <Popper open={showPicker} placement={'left-start'} anchorEl={anchorPoper} className={styles.pickerRoot}>
            <EmojiPicker />
          </Popper>

          <IconButton
            ref={buttonRef}
            onClick={(event) => {
              setShowPicker(!showPicker);
              setAnchorPoper(event.currentTarget);
            }}
          >
            <EmojiEmotionsIcon />
          </IconButton>

          <Button type={'submit'}>Send</Button>
        </div>
      </ClickAwayListener>
    </Form>
  );
};

export default reduxForm<ChatInputType>({
  form: 'chatInput',
})(ChatInput);
