import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { StateType } from '../../../../../redux/store';
import styles from './AsideFilter.module.scss';
import SelectedInput from '../../../../common/SelectedInput/SelectedInput';
import { LocalizationProvider, StaticDateRangePicker, DateRange, DateRangeDelimiter } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import Slider from '@material-ui/core/Slider';
import { filterClasses } from '../../../../../redux/actions/liveClassesActions';
import queryString from 'query-string';
import { capitalizeFirstLetter, dateWithTimezone } from '../../../../../helpers/functions';
import { resetFiltersStorage, updateFiltersStorage } from '../../../../../helpers/liveClassesfilters';
import TextField from '@material-ui/core/TextField';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

export type AsideFilterType = {
  status: Array<string>;
  // rating: Array<number>;
  categories: Array<number>; //ids of categories
  start_date: string;
  start_time: string;
  end_date: string;
  end_time: string;
  price: Array<number>;
  levels: Array<number>;
  limit: number;
  offset: number;
};

const AsideFilter: React.FC<InjectedFormProps<AsideFilterType>> = ({ handleSubmit }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { categories, levels } = useSelector((state: StateType) => state.liveClasses);
  const { user } = useSelector((state: StateType) => state.user);
  const [dateValue, setChangeDate] = useState(new Date());
  const [time, setTime] = React.useState<number[]>([0, 1440]);
  const [price, setPrice] = React.useState<number[]>([0, 1000]);
  const [changedCategories, setCategories] = useState<any>([]);
  const [changedLevels, setLevels] = useState<any>([]);
  const [currentLevel, setCurrLevel] = useState('');
  const [currentCategory, setCurrCategory] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');

  let query = queryString.parse(useLocation().search, {
    arrayFormat: 'bracket',
  });

  const todayEl = document.getElementsByClassName('MuiPickersDay-today')[0];

  const dateFormats = {
    month: 'MMM',
  };

  const filterInitialValues = {
    status: [],
    // rating: [0, 5],
    categories: [],
    start_date: '',
    start_time: '',
    end_date: '',
    end_time: '',
    price: [0, 1000],
    levels: [],
    offset: 0,
    limit: 5,
  };

  const [filterObj, setFilterObj] = useState<AsideFilterType>(filterInitialValues);

  const [valueRange, setValueRange] = useState<DateRange<Date>>([new Date(), new Date()]);
  const [instance, setInstance] = useState(moment());

  useEffect(() => {
    if (user?.timezone) {
      let currDate = moment().add('hour').format('YYYY-MM-DD HH:mm');
      let currTime = moment().add('hour').format('HH:mm');
      let currDateNtime = moment(dateWithTimezone(currDate, currTime, user?.timezone).format('YYYY-MM-DD HH:mm:ss'));
      setInstance(currDateNtime);
      setValueRange([currDateNtime.toDate(), currDateNtime.toDate()]);
    }
    return () => {
      resetFiltersStorage();
    };
  }, []);

  useEffect(() => {
    todayEl && handleTodayPicker();
  }, [todayEl]);

  useEffect(() => {}, [user]);

  useEffect(() => {
    const filters = { ...filterInitialValues, ...query };
    dispatch(
      filterClasses({
        filter: filters,
        /*         offset: 5, */
      })
    );

    setFilterObj(filters);
  }, [location]);

  useEffect(() => {
    if (filterObj.start_date) {
      const startDate = moment(filterObj.start_date).toDate();
      const endDate = moment(filterObj.end_date).toDate();

      setValueRange([startDate, endDate]);
    }
  }, [filterObj.start_date, filterObj.end_date]);

  useEffect(() => {
    if (filterObj.price) {
      setPrice(filterObj.price);
    }
  }, [filterObj.price]);

  useEffect(() => {
    categories && setCategories([{ id: 'any', name: 'Any category' }, ...categories]);
    levels && setLevels([{ id: 'any', name: 'Any levels' }, ...levels]);
  }, [categories, levels]);

  const queryStr = (obj: any) => {
    const str = queryString.stringify(obj, { arrayFormat: 'bracket' });
    history.replace(`live-classes?${str}`);
  };

  const handleTodayPicker = () => {
    const today = instance.format('MMM DD, yyyy');
    const wrongToday = moment(new Date()).format('MMM DD, yyyy');

    if (today !== wrongToday) {
      const correctDay = document.querySelectorAll(`[aria-label="${today}"]`)[0];
      todayEl?.classList.remove('MuiPickersDay-today');
      correctDay?.classList.add('MuiPickersDay-today');
    }
  };
  handleTodayPicker();
  const onSubmit = (e: any, fieldName: any) => {
    switch (fieldName) {
      case 'status':
        setCurrentStatus(e.name);
        queryStr({
          ...filterObj,
          status: e.id === 'any' ? [] : [e.name],
          limit: 5,
          offset: 0,
        });
        break;
      case 'levels':
        setCurrLevel(e.name);
        queryStr({
          ...filterObj,
          levels: e.id === 'any' ? [] : [e.id],
        });
        break;
      case 'categories':
        setCurrCategory(e.name);
        queryStr({
          ...filterObj,
          categories: e.id === 'any' ? [] : [e.id],
        });
        break;

      default:
        break;
    }
  };

  const transformDate = (date: any) => {
    const handledDate = moment(date).format('YYYY-MM-DD');

    return date && handledDate;
  };

  const handleTime = (event: any, newValue: any) => {
    setTime(newValue as number[]);

    queryStr({
      ...filterObj,
      start_date: `${transformDate(valueRange[0])}`,
      start_time: `${timeConvert(newValue[0])}`,
      end_date: `${transformDate(valueRange[1])}`,
      end_time: `${timeConvert(newValue[1])}`,
    });
  };
  const handleDate = (e: any) => {
    const start = e[0]._d;
    const end = e[1]._d;

    queryStr({
      ...filterObj,
      start_date: `${transformDate(start)}`,
      start_time: `${timeConvert(time[0])}`,
      end_date: `${transformDate(end)}`,
      end_time: `${timeConvert(time[1])}`,
    });
  };

  const handlePrice = (event: any, newValue: number | number[]) => {
    setPrice(newValue as number[]);

    queryStr({
      ...filterObj,
      price: [...(newValue as number[])],
    });
  };

  const status = [
    { id: 'any', name: 'Any status' },
    { id: 'Upcoming', name: 'Upcoming' },
    { id: 'Running', name: 'Running' },
    // { id: 2, name: 'Canceled' },
    { id: 'Passed', name: 'Passed' },
  ];

  const timeConvert = (min: number, amPm?: boolean) => {
    let hours = min / 60;
    let roundedHours = Math.floor(hours);
    let minutes = (hours - roundedHours) * 60;
    let roundedMins = `${Math.round(minutes)}`;

    return amPm
      ? (roundedHours > 12 && roundedHours !== 24 ? roundedHours - 12 : roundedHours === 24 ? 0 : roundedHours) +
          ' : ' +
          (roundedMins.length > 1 ? roundedMins : `${'0' + roundedMins}`) +
          (roundedHours >= 12 && roundedHours !== 24 ? 'PM' : 'AM')
      : (`${roundedHours}`.length > 1 ? roundedHours : `${'0' + roundedHours}`) +
          ':' +
          (roundedMins.length > 1 ? roundedMins : `${'0' + roundedMins}`);
  };

  return (
    <aside className={`${styles.wrapper}`}>
      <Form onSubmit={onSubmit} className={styles.form}>
        <div className={styles.filterBlock}>
          <h5>Status</h5>
          <Field
            component={() => (
              <SelectedInput
                name={currentStatus ? currentStatus : 'Any status'}
                onChange={(e: any) => {
                  onSubmit(e, 'status');
                }}
                data={status}
                defaultValue={filterObj.status ? filterObj.status[0] : []}
                listStyle={styles.selectList}
              />
            )}
            name=''
            label=''
            type='text'
          />
        </div>
        <div className={styles.filterBlock}>
          <h5>Level</h5>
          <Field
            component={() => (
              <SelectedInput
                name={currentLevel ? currentLevel : 'Any level'}
                onChange={(e: any) => {
                  onSubmit(e, 'levels');
                }}
                data={changedLevels}
                defaultValue={filterObj.levels ? filterObj.levels[0] : []}
                listStyle={styles.selectList}
              />
            )}
            name='level'
            label='level'
            type='text'
          />
        </div>
        <div className={styles.filterBlock}>
          <h5>Category</h5>
          <Field
            component={() => (
              <SelectedInput
                name={currentCategory ? currentCategory : 'Any category'}
                onChange={(e: any) => {
                  onSubmit(e, 'categories');
                }}
                data={changedCategories}
                defaultValue={filterObj.categories ? filterObj.categories[0] : []}
                listStyle={styles.selectList}
              />
            )}
            name='category'
            label='category'
            type='text'
          />
        </div>
        <div className={styles.datePicker}>
          {dateValue && (
            <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentUtils} dateFormats={dateFormats}>
              <StaticDateRangePicker
                toolbarFormat='EEEEEE - MM/dd/yyyy'
                inputFormat='EEEEEE - MM/dd/yyyy'
                showToolbar={false}
                orientation='portrait'
                value={valueRange}
                onChange={(e: any) => {
                  e[0] && e[1] && handleDate(e);
                  setValueRange(e);
                }}
                className={styles.datePickerWrapper}
                renderInput={(startProps, endProps) => {
                  return (
                    <>
                      <TextField {...startProps} />
                      <DateRangeDelimiter> to </DateRangeDelimiter>
                      <TextField {...endProps} />
                    </>
                  );
                }}
                leftArrowButtonProps={{
                  classes: {
                    root: styles.buttonRoot,
                  },
                }}
                rightArrowButtonProps={{
                  classes: {
                    root: styles.buttonRoot,
                  },
                }}
              />
            </LocalizationProvider>
          )}
        </div>
        <div className={styles.timePicker}>
          <div className={styles.titleSliderPicker}>
            <span>Time</span>
            <span>
              {timeConvert(time[0], true)} - {timeConvert(time[1], true)}
            </span>
          </div>
          <Slider
            value={time ? time : [0, 1440]}
            max={1440}
            onChange={handleTime}
            valueLabelDisplay='off'
            aria-labelledby='range-slider'
            classes={{ root: styles.sliderRoot }}
          />
        </div>
        <div className={styles.pricePicker}>
          <div className={styles.titleSliderPicker}>
            <span>Price</span>
            <span>
              {price[0] === 0 ? 'Free' : price[0] + '$'} - {price[1] + '$'}
            </span>
          </div>
          <Slider
            value={price ? price : [0, 1000]}
            max={1000}
            onChange={handlePrice}
            valueLabelDisplay='off'
            aria-labelledby='range-slider'
            classes={{ root: styles.sliderRoot }}
          />
        </div>

        {/*   {errors && <div className='error'>{errors}</div>} */}
      </Form>
    </aside>
  );
};

export default reduxForm<AsideFilterType>({
  form: 'liveClassesFilter',
})(AsideFilter);
