import { debug } from 'console';
import { promo } from './../../api/promo';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateFiltersStorage } from '../../helpers/liveClassesfilters';
import { stream } from '../../api/stream';

export const getCodes = createAsyncThunk(
  'promo-codes',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await promo.getCodes();
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const applyCode = createAsyncThunk(
  'promo-codes/apply',
  async (values: any | {} | null, thunkAPI) => {
    try {
      return await promo.applyCode(values);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
