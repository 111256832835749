import { liveClasses } from "./../../api/liveClasses";
import {
  getCategories,
  getClasses,
  getLevels,
  filterClasses,
  getClass,
  getInstrClasses,
  getUserClasses,
  subscribeToClass,
  unsubscribeToClass,
  cancelInstructorClass,
  createStreamSession,
  joinClass,
  leaveClass,
  getClassUsers,
  saveStream,
  updateClass,
  editClass,
  createLiveClass,
  getClassUsersInfo,
  getClassCommission,
  getCategoriesClasses,
  getClassChatMessages,
  sendClassChatMessage,
} from "./../actions/liveClassesActions";
import { createSlice } from "@reduxjs/toolkit";
import { UserType } from "./user";
import { getFilters } from "../../helpers/liveClassesfilters";

type NewClassType = {
  information: {
    title: string;
    description: string;
    levels: Array<any>;
    photo?: any;
  };
  data: {
    oneTime: boolean;
    weekdays?: Array<any>;
    startDate: string;
    endDate: string;
    time: string;
    duration: string;
    price: string;
  };
  categories: Array<{ id: number; name: string }>;
};

export type CategoryLevelType = {
  id: number;
  laravel_through_key?: number;
  name: string;
};

export type CategoryClassesType = {
  id: number;
  name: string;
  photo: string;
  liveClassesCount: number;
  liveClasses: Array<LiveClassType>;
};

export type LiveClassType = {
  categories: Array<CategoryLevelType>;
  description: string;
  duration: string;
  id: number;
  levels: Array<CategoryLevelType>;
  gtm_start_datetime: string;
  owner: number;
  photo: null | unknown;
  sm_photo: null | unknown;
  md_photo: null | unknown;
  price: number;
  schedule_id: null | number;
  start_date: string;
  start_time: string;
  status: string;
  is_joinable: boolean;
  is_pending: boolean;
  title: string;
  user: UserType;
  weekday: string;
  session_id: string;
  token: string;
  stream_id: string;
  audio_device_id: string;
  video_device_id: string;
  users: [];
  class_users_info: [];
};

type ChatMessageType = {
  id: number;
  user_id: number;
  content: string;
  timestamp: string;
};

type LiveClassesStateType = {
  liveClasses: Array<LiveClassType>;
  categoriesClasses: Array<CategoryClassesType>;
  liveClass: LiveClassType;
  liveClassUsers: [];
  categories: Array<CategoryLevelType>;
  levels: Array<CategoryLevelType> | null;
  errors: any;
  newClass: NewClassType;
  instrClasses: Array<LiveClassType>;
  userClasses: Array<LiveClassType>;
  isFullscreen: boolean;
  disableLoadmore: boolean;
  commission: number;
  isLoading: boolean;
  chat: Array<ChatMessageType>;
};

const initialState: LiveClassesStateType = {
  liveClasses: [] as Array<LiveClassType>,
  categoriesClasses: [] as Array<CategoryClassesType>,
  liveClass: {} as LiveClassType,
  liveClassUsers: [],
  categories: [] as Array<CategoryLevelType>,
  levels: null,
  errors: null,
  newClass: {} as NewClassType,
  instrClasses: [],
  userClasses: [],
  isFullscreen: false,
  disableLoadmore: false,
  commission: 10,
  isLoading: false,
  chat: [] as Array<ChatMessageType>,
};

const liveClassesSlice = createSlice({
  name: "liveClasses",
  initialState,
  reducers: {
    resetClasses(state) {
      state.liveClasses = [] as Array<LiveClassType>;
    },
    setNewClass(state, { payload }) {
      return {
        ...state,
        newClass: { ...state.newClass, ...payload },
      };
    },
    resetNewClass(state) {
      return {
        ...state,
        newClass: {} as NewClassType,
      };
    },
    setClass(state, { payload }) {
      return {
        ...state,
        liveClass: { ...state.liveClass, ...payload },
      };
    },
    setStreamUsers(state, { payload }) {
      return {
        ...state,
        liveClassUsers: payload,
      };
    },
    setFullscreen(state, { payload }) {
      return {
        ...state,
        isFullscreen: payload,
      };
    },
    setCurrStatus(state, { payload }) {
      return {
        ...state,
        currStatus: payload,
      };
    },
    clearInstrClasses(state) {
      return {
        ...state,
        instrClasses: [] as Array<LiveClassType>,
      };
    },
    updateLiveClasses(state, { payload }) {
      let isAffected = false;
      const updatedLiveClasses = state.liveClasses.map((el: any) => {
        if (el.id === payload.id) {
          isAffected = true;
          return { ...el, ...payload };
        } else {
          return el;
        }
      });
      if (!isAffected) {
        // updatedLiveClasses.push(payload);
      }
      return {
        ...state,
        liveClasses: updatedLiveClasses,
      };
    },
    updateUserClasses(state, { payload }) {
      let isAffected = false;
      const updatedUserClasses = state.userClasses.map((el: any) => {
        if (el.id === payload.id) {
          isAffected = true;
          return { ...el, ...payload };
        } else {
          return el;
        }
      });
      if (!isAffected) {
        // updatedUserClasses.push(payload);
      }
      return {
        ...state,
        userClasses: updatedUserClasses,
      };
    },
    updateInstrClasses(state, { payload }) {
      let isAffected = false;
      const updatedInstrClasses = state.instrClasses.map((el: any) => {
        if (el.id === payload.id) {
          isAffected = true;
          return { ...el, ...payload };
        } else {
          return el;
        }
      });
      if (!isAffected) {
        // updatedUserClasses.push(payload);
      }
      return {
        ...state,
        instrClasses: updatedInstrClasses,
      };
    },
    updateChatMessages(state, { payload }) {
      const messagesArr = [...state.chat, payload];

      return { ...state, chat: messagesArr };
    },
    resetChat(state) {
      return {
        ...state,
        chat: {} as Array<ChatMessageType>,
      };
    },
  },
  extraReducers: {
    [getClasses.pending.toString()]: (state) => {
      state.errors = null;
    },
    [getClasses.fulfilled.toString()]: (state, { payload }) => {
      state.liveClasses = [...payload];
    },
    [getClasses.rejected.toString()]: (state, { payload }) => {
      state.errors = payload?.errors;
    },
    [getCategories.pending.toString()]: (state) => {
      state.errors = null;
    },
    [getCategories.fulfilled.toString()]: (state, { payload }) => {
      state.categories = [...payload];
    },
    [getCategories.rejected.toString()]: (state, { payload }) => {
      state.errors = payload?.errors;
    },
    [getLevels.pending.toString()]: (state) => {
      state.errors = null;
    },
    [getLevels.fulfilled.toString()]: (state, { payload }) => {
      state.levels = [...payload];
    },
    [getLevels.rejected.toString()]: (state, { payload }) => {
      state.errors = payload?.errors;
    },
    [filterClasses.pending.toString()]: (state) => {
      state.errors = null;
    },
    [filterClasses.fulfilled.toString()]: (state, { payload }) => {
      const filtersLocal = getFilters();

      state.disableLoadmore = payload.count < 5 ? true : false;
      state.liveClasses =
        filtersLocal && filtersLocal?.offset >= 5
          ? [...state.liveClasses, ...payload.data]
          : [...payload.data];
    },
    [filterClasses.rejected.toString()]: (state, { payload }) => {
      state.errors = payload?.errors;
    },
    [getClass.pending.toString()]: (state) => {
      state.errors = null;
    },
    [getClass.fulfilled.toString()]: (state, { payload }) => {
      state.liveClass = { ...payload };
      state.errors = null;
    },
    [getClass.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
    },
    [getInstrClasses.pending.toString()]: (state) => {
      state.errors = null;
    },
    [getInstrClasses.fulfilled.toString()]: (state, { payload }) => {
      state.instrClasses = [...payload];
      state.errors = null;
    },
    [getInstrClasses.rejected.toString()]: (state, { payload }) => {
      state.instrClasses = [];
      state.errors = payload;
    },
    [getUserClasses.pending.toString()]: (state) => {
      state.errors = null;
    },
    [getUserClasses.fulfilled.toString()]: (state, { payload }) => {
      state.userClasses = [...payload];
      state.errors = null;
    },
    [getUserClasses.rejected.toString()]: (state, { payload }) => {
      state.userClasses = [];
      state.errors = payload;
    },
    [getCategoriesClasses.pending.toString()]: (state) => {
      state.errors = null;
    },
    [getCategoriesClasses.fulfilled.toString()]: (state, { payload }) => {
      state.categoriesClasses = payload;
      state.errors = null;
    },
    [getCategoriesClasses.rejected.toString()]: (state, { payload }) => {
      state.userClasses = [];
      state.errors = payload;
    },
    [subscribeToClass.pending.toString()]: (state) => {
      state.errors = null;
    },
    [subscribeToClass.fulfilled.toString()]: (state, { payload }) => {
      // state.userClasses = [...state.userClasses];
      state.errors = null;
    },
    [subscribeToClass.rejected.toString()]: (state, { payload }) => {
      //  state.userClasses = [];
      state.errors = payload;
    },
    [unsubscribeToClass.pending.toString()]: (state) => {
      state.errors = null;
    },
    [unsubscribeToClass.fulfilled.toString()]: (state, { payload }) => {
      // state.userClasses = [...state.userClasses];
      state.errors = null;
    },
    [unsubscribeToClass.rejected.toString()]: (state, { payload }) => {
      //  state.userClasses = [];
      state.errors = payload;
    },
    [cancelInstructorClass.pending.toString()]: (state) => {
      state.errors = null;
    },
    [cancelInstructorClass.fulfilled.toString()]: (state, { payload }) => {
      //  state.userClasses = [...state.userClasses];
      state.errors = null;
    },
    [cancelInstructorClass.rejected.toString()]: (state, { payload }) => {
      //  state.userClasses = [];
      state.errors = payload;
    },
    [createStreamSession.pending.toString()]: (state) => {
      state.errors = null;
    },
    [createStreamSession.fulfilled.toString()]: (state, { payload }) => {
      state.liveClass = { ...state.liveClass, ...payload };
      state.errors = null;
    },
    [createStreamSession.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
    },
    [saveStream.pending.toString()]: (state) => {
      state.errors = null;
    },
    [saveStream.fulfilled.toString()]: (state, { payload }) => {
      state.liveClass = { ...state.liveClass, ...payload };
      state.errors = null;
    },
    [saveStream.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
    },
    [joinClass.pending.toString()]: (state) => {
      state.errors = null;
    },
    [joinClass.fulfilled.toString()]: (state, { payload }) => {
      state.errors = null;
    },
    [joinClass.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
    },
    [leaveClass.pending.toString()]: (state) => {
      state.errors = null;
    },
    [leaveClass.fulfilled.toString()]: (state, { payload }) => {
      state.errors = null;
    },
    [leaveClass.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
    },
    [getClassUsers.pending.toString()]: (state) => {
      state.errors = null;
    },
    [getClassUsers.fulfilled.toString()]: (state, { payload }) => {
      state.liveClassUsers = payload;
      state.errors = null;
    },
    [getClassUsers.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
    },
    [getClassUsersInfo.pending.toString()]: (state) => {
      state.errors = null;
    },
    [getClassUsersInfo.fulfilled.toString()]: (state, { payload }) => {
      state.liveClass.class_users_info = payload.class_users_info;
      state.errors = null;
    },
    [getClassUsersInfo.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
    },
    [updateClass.pending.toString()]: (state) => {
      state.errors = null;
    },
    [updateClass.fulfilled.toString()]: (state, { payload }) => {
      state.liveClass = { ...state.liveClass, ...payload };
      state.errors = null;
    },
    [updateClass.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
    },
    [editClass.pending.toString()]: (state) => {},
    [editClass.fulfilled.toString()]: (state, { payload }) => {
      state.liveClass = { ...state.liveClass, ...payload };
      state.errors = null;
    },
    [editClass.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
    },
    [createLiveClass.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [createLiveClass.fulfilled.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.newClass = {} as NewClassType;
      state.errors = null;
    },
    [createLiveClass.rejected.toString()]: (state, { payload }) => {
      // state.newClass = {} as NewClassType;
      state.errors = payload;
      state.isLoading = false;
    },
    [getClassCommission.pending.toString()]: (state) => {},
    [getClassCommission.fulfilled.toString()]: (state, { payload }) => {
      state.commission = payload;
    },
    [getClassCommission.rejected.toString()]: (state, { payload }) => {
      state.commission = 10;
    },
    [getClassChatMessages.pending.toString()]: (state) => {
      state.errors = null;
    },
    [getClassChatMessages.fulfilled.toString()]: (state, { payload }) => {
      state.chat = payload;
      state.errors = null;
    },
    [getClassChatMessages.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
    },
    [sendClassChatMessage.pending.toString()]: (state) => {
      state.errors = null;
    },
    [sendClassChatMessage.fulfilled.toString()]: (state, { payload }) => {
      state.errors = null;
      // const messagesArr = [...state.chat, payload];
      // return { ...state, chat: messagesArr };
    },
    [sendClassChatMessage.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
    },
  },
});

export const {
  resetClasses,
  setNewClass,
  setClass,
  setStreamUsers,
  updateLiveClasses,
  updateUserClasses,
  updateInstrClasses,
  resetNewClass,
  setFullscreen,
  clearInstrClasses,
  updateChatMessages,
  resetChat,
} = liveClassesSlice.actions;

const classesReducer = liveClassesSlice.reducer;

export default classesReducer;
