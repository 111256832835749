import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form, submit } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';
import styles from './WithdrawnForm.module.scss';
import Button from '../../../../common/Button/Button';
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Input from '../../../../common/Input/Input';
import {
  replenishBalance,
  setupPaymentMethod,
  getProfile,
  withdrawnStripe,
  getBalance,
} from '../../../../../redux/actions/userActions';
import { StateType } from '../../../../../redux/store';

export type WithdrawnFormType = {
  balance: number;
};

const WithdrawnForm: React.FC<InjectedFormProps<WithdrawnFormType>> = ({
  handleSubmit,
}) => {
  const { user } = useSelector((state: StateType) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = (e: any) => {
    e && e.preventDefault();

    handleSubmit((value) => {
      value.balance > 19 &&
        //@ts-ignore
        dispatch(withdrawnStripe(value.balance)).then(() => {
          dispatch(getBalance());
        });
    })();
  };

  return (
    <Form onSubmit={onSubmit} className={styles.form}>
      <Field
        component={Input}
        name='balance'
        type='number'
        iconStart={<AttachMoneyIcon />}
        parentStyle={styles.moneyInput}
        classes={{ root: styles.rootMoneyInput }}
        balanceValue
      />

      {/*  <Button
        color='primary'
        variant='contained'
        customClass={styles.balanceButton}
        type='submit'
      >
        Request a payout
      </Button> */}
    </Form>
  );
};

export default reduxForm<WithdrawnFormType>({
  form: 'withdrawnForm',
})(WithdrawnForm);
