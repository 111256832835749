import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { useHistory, Link, useLocation } from 'react-router-dom';
import Input from '../../../common/Input/Input';
import Button from '../../../common/Button/Button';
import { StateType } from '../../../../redux/store';
import {
  required,
  password,
  passwordsCheck,
  email,
} from '../../../../helpers/validate';
import SyncIcon from '@material-ui/icons/Sync';
import { Link as MaterialLink } from '@material-ui/core';
import styles from './ForgotPasswordPage.module.scss';
import {
  resetPassword,
  setPassword,
  signIn,
} from '../../../../redux/actions/authActions';
import { setError, setResetPass } from '../../../../redux/reducers/auth';
import MainLoader from '../../../common/MainLoader/MainLoader';

type PasswordsType = {
  password: string;
  new_password: string;
};

type EmailType = {
  email: string;
};

const ForgotPasswordPage: React.FC<InjectedFormProps<
  PasswordsType | EmailType
>> = ({ handleSubmit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState<boolean>(false);
  const [preloader, setPreloader] = useState<boolean>(false);
  const [showPassInput, setShowPassInput] = useState<boolean>(false);
  const [userEmail, setEmail] = useState({ email: '' });
  const [linkDisable, setLinkDisable] = useState(false);
  const { errors, resetPass } = useSelector((state: StateType) => state.auth);

  let query = new URLSearchParams(useLocation().search);
  let code = query.get('confirm_code');
  let status = query.get('status');
  let emailQuery = query.get('email');
  let token = localStorage.getItem('token');

  useEffect(() => {
    return () => {
      dispatch(setError(''));
      dispatch(setResetPass({}));
    };
  }, []);

  useEffect(() => {
    if (code) {
      setShowPassInput(true);
    }
  }, [code]);

  useEffect(() => {
    if (emailQuery) {
      setEmail({ email: `${emailQuery}` });
    }
  }, [emailQuery]);

  useEffect(() => {
    if (token) {
      history.push(`/user/home`);
    }
  }, [token]);

  useEffect(() => {
    if (resetPass && resetPass.success) {
      setPreloader(false);
      setSuccess(true);
    }
  }, [resetPass]);

  useEffect(() => {
    if (errors === 'User not found' || errors === 'User is not activated') {
      dispatch(
        setError(
          errors === 'User not found'
            ? `${errors}. Create an account`
            : `${errors}. Activate it, please`
        )
      );
      history.push(
        errors === 'User not found' ? `/auth/sign-up` : `/auth/sign-in`
      );
      setSuccess(false);
      localStorage.clear();
    }

    return () => {
      dispatch(setError(''));
    };
  }, [errors]);

  useEffect(() => {
    if (status && status !== 'success') {
      dispatch(setError('Try to login with a new password'));
      history.push(`/auth/sign-in`);
    }
  }, [status]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    handleSubmit((value: any) => {
      setPreloader(true);
      //let email = localStorage.getItem('email');
      let password = value.password;
      if (code && status === 'success') {
        dispatch(setPassword({ password: password, confirm_code: code }));
        userEmail.email &&
          dispatch(signIn({ email: userEmail.email, password: password }));
      } else {
        // localStorage.setItem('email', value.email);
        setEmail(value);
        dispatch(resetPassword(value));
      }
    })();
  };

  const handlerOK = () => {
    history.push(`/auth/sign-in`);
  };

  const resendHandler = () => {
    if (!linkDisable) {
      userEmail && dispatch(resetPassword(userEmail));
      setLinkDisable(true);
      setTimeout(() => setLinkDisable(false), 30000);
    }
  };

  return (
    <>
      <MainLoader loading={preloader} />
      <div className={styles.wrapper}>
        {success ? (
          <div className={styles.form}>
            <div className={styles.internalWrapper}>
              <h1>Email sent!</h1>
              <p>We’ve sent you a link to reset your password</p>

              <div className={styles.formButtons}>
                <Button
                  onClick={() => handlerOK()}
                  color='primary'
                  variant='contained'
                >
                  Ok
                </Button>

                <div>
                  <div>
                    <MaterialLink
                      component='button'
                      onClick={() => resendHandler()}
                      disabled={linkDisable}
                      className={`${
                        linkDisable ? styles.disabledLink : styles.enabledLink
                      }`}
                    >
                      <SyncIcon /> Resend link
                    </MaterialLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Form onSubmit={onSubmit} className={styles.form}>
            <div className={styles.internalWrapper}>
              <h1>Reset Password</h1>

              {!showPassInput ? (
                <>
                  <p>Enter your email to reset your password</p>
                  <h4>Email</h4>

                  <Field
                    component={Input}
                    name='email'
                    label='Type email here...'
                    type='email'
                    validate={[email, required]}
                  />
                </>
              ) : (
                <>
                  <h4>New password</h4>
                  <Field
                    component={Input}
                    name='password'
                    label='New password'
                    type='password'
                    validate={[required, password]}
                  />
                  <h4>Retype password</h4>
                  <Field
                    component={Input}
                    name='new_password'
                    label='Retype password'
                    type='password'
                    validate={[required, passwordsCheck]}
                  />
                </>
              )}

              <div className={styles.formButtons}>
                <Button type='submit' color='primary' variant='contained'>
                  {!setShowPassInput ? 'Send email' : 'Send'}
                </Button>

                {!setShowPassInput && (
                  <div>
                    <div>
                      <div>
                        <Link to='/auth/sign-up'>Create an account</Link>
                      </div>
                    </div>
                    <div>
                      Already have an account?
                      <Link to='/auth/sign-in'>Log in</Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
        <div className={styles.termsOfUse}>
          <span>
            By login in or creating an account, you agree with our{' '}
            <Link to='/terms-of-use'>Terms of Use </Link>
            and <Link to='/privacy-policy'>Privacy Policy</Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default reduxForm<PasswordsType | EmailType>({
  form: 'forgotPassword',
})(ForgotPasswordPage);
