import React, { useState, useEffect } from 'react';
import styles from './SelectableCheckbox.module.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  InputBase,
  Checkbox,
  NativeSelect,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  CheckboxProps,
  Chip,
  SvgIcon,
  FormHelperText,
} from '@material-ui/core';

import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from '@material-ui/core/styles';
import { WrappedFieldInputProps } from 'redux-form';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete.svg';

type SelectableCheckboxType = {
  name: string;
  onChange?: any;
  data: Array<any>;
  defaultValue?: Array<{ id: number; name: string }>;
  handleChange?: Function;
  input?: WrappedFieldInputProps;
  checkbox?: boolean;
  isMultiple?: boolean;
  meta?: any;
  label?: string;
  isLevels?: boolean;
  longLabel?: boolean;
};

type SelectedType = string | Array<{ id: number; name: string }>;

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '2px 0 22px',
    },

    input: {
      borderRadius: 6,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid rgba(71, 70, 71, 0.26)',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: ['-apple-system'].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#5900a8',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
      '&:hover': {
        borderColor: '#5900a8',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
)(InputBase);

const SelectableCheckbox: React.FC<SelectableCheckboxType> = ({
  name,
  onChange,
  data,
  defaultValue,
  handleChange,
  input,
  checkbox,
  isMultiple = false,
  meta,
  label,
  isLevels,
  longLabel,
}) => {
  const [value, setValue] = useState<Array<{ id: number; name: string }>>(
    [] as Array<{ id: number; name: string }>
  );
  const [open, setOpen] = useState(false);
  const [menuArr, setMenuArr] = useState<Array<any>>(data);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    return () => {
      setValue([] as Array<{ id: number; name: string }>);
    };
  }, []);

  useEffect(() => {
    if (data) {
      const dataIsSelected = data.map((el: any) => {
        return { ...el, isSelected: false };
      });

      setMenuArr(dataIsSelected);
    }
  }, [data]);

  useEffect(() => {
    let isMetaError = meta && meta.touched && !!meta.error;
    setError(!!isMetaError);
    if (meta && meta.error) {
      meta.touched && setErrorMessage(`${meta.error}`);
    } else {
      setErrorMessage('');
    }
  }, [meta]);

  useEffect(() => {
    if (menuArr && value) {
      const setSelected = menuArr.map((el) => {
        const itemFound = value.find(
          (e: { id: number; name: string }) => e.id === el.id
        );

        return itemFound
          ? { ...el, isSelected: true }
          : { ...el, isSelected: false };
      });

      setMenuArr(setSelected);
    }
    if (value) {
      input && value.length > 0
        ? input.onChange(value)
        : input && input.onChange(null);
    }
  }, [value]);

  useEffect(() => {
    if (defaultValue && data) {
      const mapedArr = data.filter((el) => {
        const matchedDefValue = defaultValue.find((e) => e.id === el.id);

        return matchedDefValue;
      });
      setValue(mapedArr);

      input && input.onChange(defaultValue);
    }
  }, [defaultValue]);

  const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    isMultiple
      ? setValue(event.target.value as Array<{ id: number; name: string }>)
      : setValue(event.target.value as Array<{ id: number; name: string }>);

    // input && input.onChange(event.target.value);
    let elSorted = data?.find((el) => el.id === event.target.value);

    elSorted && onChange && onChange(elSorted);
  };
  const handleClose = (e: any) => {
    e.preventDefault();
    setOpen(false);
  };

  const handleOpen = (e: any) => {
    e.preventDefault();
    e.target.id && setOpen(true);
  };

  const handleDelete = (id: number) => {
    const filteredArr =
      value &&
      typeof value !== 'number' &&
      typeof value !== 'string' &&
      value.filter((el: { id: number; name: string }) => {
        return el.id !== id;
      });

    filteredArr && setValue(filteredArr);
  };

  const deleteFromValue = (id: number) => {
    const filteredValue = value.filter((el: any) => el.id !== id);
    setValue(filteredValue);
  };

  return (
    <FormControl className={styles.wrapper}>
      <InputLabel
        classes={{
          shrink: styles.labelShrink,
          root: longLabel ? styles.longLabelRoot : styles.labelRoot,
        }}
        id='selectableInputLabel'
      >
        {isLevels ? (
          <>
            {' '}
            Select the level of the students the live class is for.
            <br /> Multiple levels can be selected
          </>
        ) : (
          label
        )}
      </InputLabel>
      <Select
        id='selectableInput'
        labelId={'selectableInputLabel'}
        value={value}
        onChange={handleOnChange}
        input={<BootstrapInput />}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        label={label ? label : ''}
        classes={{
          root: `${styles.selectRoot} ${error && styles.selectRootError}`,
        }}
        MenuProps={{
          classes: { paper: styles.dropdownList },
        }}
        IconComponent={ExpandMoreIcon}
        multiple={isMultiple}
        name={'selectInput'}
        renderValue={(selected: any) => {
          const uniqueIdArr = selected.filter(
            (v: any, i: number, a: any) =>
              a.findIndex((t: any) => t.id === v.id) === i
          );

          return (
            <div className={styles.selectedMultiple}>
              {(uniqueIdArr as Array<{ id: number; name: string }>).map(
                (el: { id: number; name: string }) => (
                  <Chip
                    key={el.id}
                    label={el.name}
                    className={styles.chip}
                    onDelete={() => handleDelete(el.id)}
                    id={`${el.id}`}
                    deleteIcon={<DeleteIcon className={styles.deleteIcon} />}
                  />
                )
              )}
            </div>
          );
        }}
      >
        {menuArr &&
          menuArr.map((el) => {
            return (
              <MenuItem
                key={`${el.id}`}
                classes={{}}
                value={isMultiple ? el : el.id}
                onClick={() => deleteFromValue(el.id)}
              >
                {el.name}
                {checkbox && (
                  <Checkbox
                    checked={el.isSelected && el.isSelected}
                    classes={{
                      root: `${styles.menuCheckbox} ${
                        el.isSelected && styles.activeCheckbox
                      }`,
                    }}
                  />
                )}
              </MenuItem>
            );
          })}
      </Select>
      {error && (
        <FormHelperText
          classes={{ root: `${error && styles.error}` }}
          id='selectableInputHelper'
        >
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectableCheckbox;
