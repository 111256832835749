import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { StateType } from '../../redux/store';

type GuestRouteProps = {
  exact: boolean;
  path: string;
  component: any;
};

const GuestRoute: React.FC<GuestRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { user } = useSelector((state: StateType) => state.user);
  const token = localStorage.getItem('token');
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token || location.pathname === '/auth/stripe') {
          return <Component {...props} />;
        }

        return <Redirect to='/' />;
      }}
    />
  );
};

export default GuestRoute;
