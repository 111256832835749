import React, { useState, useEffect } from 'react';

const ImageWithFallbacks = (props: any) => {
  const [state, setState] = useState({
    src: props.src,
    fallbackIndex: 0,
  });

  const [fallbacks, setFallbacks] = useState([] as any);

  useEffect(() => {
    setFallbacks(props.fallbacks.split(','));
  }, []);

  useEffect(() => {
    setState({...state, src: props.src})
  }, [props.src]);

  const onError = () => {
    if (state.fallbackIndex > fallbacks.length) {
      return;
    }
    setState({
      src: fallbacks[state.fallbackIndex],
      fallbackIndex: state.fallbackIndex + 1,
    });
  };

  return <img {...props} src={state.src} onError={() => onError()} />;
};

export default ImageWithFallbacks;
