import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form, submit } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';

import Input from '../../../../common/Input/Input';
import Button from '../../../../common/Button/Button';

import { signIn } from '../../../../../redux/actions/authActions';
import { StateType } from '../../../../../redux/store';
import { required, email, password } from '../../../../../helpers/validate';

import styles from './InformationStep.module.scss';
import { ReactComponent as ArrowRight } from '../../../../../assets/svg/arrowRight.svg';
import { ReactComponent as Camera } from '../../../../../assets/svg/camera.svg';
import SelectedInput from '../../../../common/SelectedInput/SelectedInput';
import { Link as MaterialLink } from '@material-ui/core';
import { setNewClass } from '../../../../../redux/reducers/liveClasses';
import AutocompleteInput from '../../../../common/AutocompleteInput/AutocompleteInput';
import FileInput from '../../../../common/FileInput/FileInput';
import SelectableCheckbox from '../../../../common/SelectableCheckbox/SelectableCheckbox';

export type InformationStepType = {
  title: string;
  levels: string | Array<any>;
  description: string;
  photo?: string;
};

const InformationStep: React.FC<InjectedFormProps<InformationStepType>> = ({ handleSubmit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { levels, newClass } = useSelector((state: StateType) => state.liveClasses);
  const [file, setFile] = useState({} as any);
  const [fileURL, setFileURL] = useState('');
  const [photoString, setPhoto] = useState('' as any);
  const [formObj, setFormObj] = useState<InformationStepType>({} as InformationStepType);

  const localPhoto = localStorage.getItem('photo');
  const localNewClass = localStorage.getItem('newClass');

  useEffect(() => {
    const parsedNewClass = JSON.parse(localNewClass as string);
    if (parsedNewClass && parsedNewClass.information) {
      parsedNewClass && setFormObj(parsedNewClass.information);
    }
  }, [localNewClass]);

  /* useEffect(() => {
    if (photoString) {
      localStorage.setItem('photo', photoString);
    }
  }, [photoString]); */

  useEffect(() => {
    // if file uploaded than code below show the preview
    if (file && file[0]) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setFileURL(reader.result as string);
        // localStorage.setItem('photo', `${reader.result}`);
      };
      reader.readAsDataURL(file[0]);
    }
  }, [file]);

  useEffect(() => {
    if (newClass.information) {
      newClass.information.photo && setFileURL(newClass.information.photo);
    }
  }, [newClass]);

  useEffect(() => {
    if (newClass.information) {
      setFormObj({
        title: newClass.information.title,
        levels: newClass.information.levels,
        description: newClass.information.description,
        photo: newClass.information.photo,
      });
    }
  }, [newClass]);

  const onSubmit = (e: any) => {
    handleSubmit((value) => {
      const information = { ...value };

      dispatch(setNewClass({ information: information }));
    })();
  };

  return (
    <Form onSubmit={onSubmit} className={styles.form}>
      <div className={styles.inputs}>
        <div>
          <h4>Live class title *</h4>
          <Field
            component={Input}
            maxLength={30}
            name='title'
            label='Type the title of the live class (e.g. Rocket science)'
            type='text'
            validate={[required]}
            defaultValue={formObj && formObj.title}
          />
          <h4>Live class level *</h4>
          <Field
            component={SelectableCheckbox}
            name='levels'
            label='Select the level of the students the live class is for. Multiple levels can be selected'
            type='number'
            validate={[required]}
            data={levels}
            defaultValue={formObj && formObj.levels}
            isMultiple
            isLevels
            longLabel
          />
          {/* 
          <AutocompleteInput
            title={'Live class level *'}
            data={levels}
            iSrequired
            isLevels
            label={
              'Add keywords to your live class to help students to find your live class easily'
            }
          /> */}

          <h4>Live class description *</h4>
          <Field
            component={Input}
            name='description'
            label='Type the description of the live class so your students will understand what class is about(e.g. We will build the rocket) '
            type='text'
            validate={[required]}
            maxLength={500}
            parentStyle={styles.textArea}
            isMultiline={true}
            rows={5}
            defaultValue={formObj && formObj.description}
          />

          {/*    {errors && <div className='error'>{errors}</div>} */}
        </div>
        <div className={styles.photo}>
          {/* {fileURL ? (
            <img src={fileURL} alt='preview' />
          ) : (
            <Field
              name='photo'
              component={FileInput}
              type='file'
              value={null as any}
            />
          )} */}
          <Field
            name='photo'
            component={FileInput}
            type='file'
            onChangeField={(e: any) => setFile(e)}
            setPhoto={(photo: string) => setPhoto(photo)}
            defaultValue={formObj && formObj.photo}
          />
        </div>
      </div>
    </Form>
  );
};

export default reduxForm<InformationStepType>({
  form: 'scheduleInfo',
})(InformationStep);
