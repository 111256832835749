import { liveClasses } from './../api/liveClasses';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './reducers/auth';
import userReducer from './reducers/user';
import classesReducer from './reducers/liveClasses';
import alertReducer from './reducers/alert';
import promoReducer from "./reducers/promo";

const middleware = [...getDefaultMiddleware({ serializableCheck: false })];

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    liveClasses: classesReducer,
    alert: alertReducer,
    form: formReducer,
    promo: promoReducer,
  },
  middleware,
});

export type DispatchType = typeof store.dispatch;
export type StateType = ReturnType<typeof store.getState>;

export default store;
