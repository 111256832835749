import React from 'react';
import { Link } from 'react-router-dom';

import { footerMenuList } from '../../../helpers/values';
import { ReactComponent as DarkLogo } from '../../../assets/svg/logoBlack.svg';
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';
import styles from './Footer.module.scss';

const Footer: React.FC = () => {
  return (
    <footer className={styles.wrapper}>
      {/*       <div className={styles.copy}>&copy; 2020 GoTeach.Live</div> */}
      <div className={styles.logo}>
        <Link to='/'>
          <DarkLogo />
        </Link>
      </div>

      <div className={styles.menu}>
        {footerMenuList.map((link, index) => (
          <Link key={`${link.name + index}`} to={link.url}>
            {link.name}
          </Link>
        ))}
        <a href='mailto:info@goteach.live'>For support contact: info@goteach.live</a>
      </div>
      <div className={styles.termsOfUse}>
        <span>
          By logging in or creating an account, you agree with our <Link to='/terms-of-use'>Terms of Use </Link>
          and <Link to='/privacy-policy'>Privacy Policy</Link> <br /> For support contact:{' '}
          <a href='mailto:info@goteach.live'>info@goteach.live</a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
