import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './EditLiveClass.module.scss';
import Button from '../../../common/Button/Button';
import { StateType } from '../../../../redux/store';
import { getCategories, getLevels, getClass, editClass } from '../../../../redux/actions/liveClassesActions';
import ClassesList from '../ClassesList/ClassesList';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import Input from '../../../common/Input/Input';
import FileInput from '../../../common/FileInput/FileInput';
import SelectableCheckbox from '../../../common/SelectableCheckbox/SelectableCheckbox';
import { required } from '../../../../helpers/validate';
import MainLoader from '../../../common/MainLoader/MainLoader';
import { Hidden } from '@material-ui/core';

type EditClassType = {
  title: string;
  levels: Array<any>;
  categories: Array<any>;
  description: string;
  photo?: string;
};

const EditLiveClass: React.FC<InjectedFormProps<EditClassType>> = ({ handleSubmit }) => {
  const { categories, levels, liveClass } = useSelector((state: StateType) => state.liveClasses);
  const { message } = useSelector((state: StateType) => state.alert);
  const { user } = useSelector((state: StateType) => state.user);

  const [formObj, setFormObj] = useState<EditClassType>({} as EditClassType);
  const [file, setFile] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [photoString, setPhoto] = useState('' as any);
  const params: { id: string; stream: string } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getLevels(null));
    dispatch(getCategories(null));

    return () => {
      localStorage.removeItem('photo');
    };
  }, []);

  useEffect(() => {
    message && history.push('/user/my-live-classes');
  }, [message]);

  useEffect(() => {
    if (liveClass && liveClass.id) {
      setFormObj({
        ...formObj,
        title: liveClass.title,
        levels: liveClass.levels,
        categories: liveClass.categories,
        description: liveClass.description,
      });
    }
    if (+user.id !== +liveClass.owner && liveClass?.owner) {
      history.push('/user/home');
    }
  }, [liveClass]);

  useEffect(() => {
    const id = +params.id;
    if (params && params.id) {
      window.scrollTo(0, 0);
      setLoading(true);
      getClassById(id);
    }
  }, [params.id]);

  const getClassById = async (id: number) => {
    await dispatch(getClass(id));
    await setTimeout(() => setLoading(false), 300);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    handleSubmit((value) => {
      const photo = localStorage.getItem('photo');
      const categoriesMaped: any =
        value.categories &&
        value.categories.map((el) => {
          return el.id;
        });
      const levelsMaped: any =
        value.levels &&
        value.levels.map((el) => {
          return el.id;
        });
      const photoObj = photoString ? { photo: photoString } : {};
      dispatch(
        editClass({
          id: liveClass.id,
          formData: {
            ...value,
            categories: categoriesMaped,
            levels: levelsMaped,
            ...photoObj,
          },
        })
      );
    })();
  };

  const Buttons = () => {
    return (
      <div className={styles.buttons}>
        <Button color='primary' variant='contained' onClick={() => history.push('/user/my-live-classes')}>
          Cancel
        </Button>
        <Button type='submit' color='primary' variant='contained'>
          Save
        </Button>
      </div>
    );
  };

  return (
    <Form onSubmit={onSubmit} className={styles.wrapper}>
      <MainLoader loading={loading} />
      <h1>Edit live class</h1>
      <div className={styles.photoWrapper}>
        <Field
          name='photo'
          component={FileInput}
          type='file'
          onChangeField={(e: any) => {
            setFile(e);
          }}
          setPhoto={(photo: string) => setPhoto(photo)}
          photoPreview={liveClass.photo}
        />
        <Hidden smDown>
          <Buttons />
        </Hidden>
      </div>
      <div className={styles.descWrapper}>
        <div>
          <h4>Title</h4>
          <Field
            component={Input}
            name='title'
            label='Title'
            maxLength={30}
            type='text'
            validate={[required]}
            defaultValue={formObj && formObj.title}
          />
        </div>
        <div>
          <h4>Levels</h4>

          <Field
            component={SelectableCheckbox}
            name='levels'
            label='Levels'
            type='number'
            validate={[required]}
            data={levels}
            defaultValue={formObj && formObj.levels}
            checkbox
            isMultiple
          />
        </div>
        <div>
          <h4>Categories</h4>
          <Field
            component={SelectableCheckbox}
            name='categories'
            label='Categories'
            type='number'
            validate={[required]}
            data={categories}
            defaultValue={formObj && formObj.categories}
            checkbox
            isMultiple
          />
        </div>
        <div>
          <h4>Description</h4>
          <Field
            component={Input}
            name='description'
            label='Description'
            type='text'
            validate={[required]}
            maxLength={500}
            parentStyle={styles.textArea}
            isMultiline={true}
            rows={5}
            defaultValue={formObj && formObj.description}
          />
        </div>
      </div>
      <Hidden mdUp>
        <Buttons />
      </Hidden>
    </Form>
  );
};

export default reduxForm<EditClassType>({
  form: 'editClassForm',
})(EditLiveClass);
