import React from 'react';

type ShareItemType = {
  id: number;
  name: string;
  icon: string;
};

export const shareArr: Array<ShareItemType> = [
  { id: 0, name: 'Share on Twitter', icon: 'twitter' },
  { id: 1, name: 'Share on Facebook', icon: 'facebook' },
  { id: 2, name: 'Copy link', icon: 'copy' },
];
