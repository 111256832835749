import React, { useEffect, useState } from 'react';
import { useHistory, Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Popup from '../Popup/Popup';
import { Button, Popover } from '@material-ui/core';
import { ReactComponent as Email } from '../../../assets/svg/email.svg';
import { ReactComponent as Notification } from '../../../assets/svg/notification.svg';
import { ReactComponent as Target } from '../../../assets/svg/target.svg';
import { ReactComponent as Switch } from '../../../assets/svg/switch.svg';
import { ReactComponent as Wallet } from '../../../assets/svg/wallet.svg';
import { ReactComponent as Camera } from '../../../assets/svg/liveClasses/camera.svg';
import { ReactComponent as User } from '../../../assets/svg/liveClasses/user.svg';
import { ReactComponent as Dollar } from '../../../assets/svg/liveClasses/dollar.svg';
import { ReactComponent as Settings } from '../../../assets/svg/settings.svg';
import { ReactComponent as LogOut } from '../../../assets/svg/turnOff.svg';
import { ReactComponent as Coins } from '../../../assets/svg/Coins.svg';
import { Link as MaterialLink, Hidden } from '@material-ui/core';
import { StateType } from '../../../redux/store';
import { signOut } from '../../../redux/actions/userActions';
import { NavMenuList } from '../../../helpers/values';

import styles from './NavBar.module.scss';
import { setUser } from '../../../redux/reducers/user';
import { switchType } from '../../../redux/actions/authActions';
import { photoUserErrHandler } from '../../../helpers/functions';
import ImageWithFallbacks from '../ImageWithFallbacks/ImageWithFallbacks';
import { UserButton } from '../UserButton/UserButton';

const icons: any = {
  email: <Email />,
  wallet: <Dollar />,
  notification: <Notification />,
  profile: <User />,
  settings: <Settings />,
  logout: <LogOut />,
};

const NavMenu: React.FC = () => {
  const [state, setState] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile, user, balance } = useSelector((state: StateType) => state.user);
  const [anchorEl, setAnchorEl] = useState<any | null>(null);

  const logout = async () => {
    await dispatch(signOut());
    history.push('/auth/sign-in');
  };

  const handleToggle = () => {
    setState(!state);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const User = (
    <div className={styles.userWrapper}>
      <div className={styles.userImg}>
        {user.sm_photo ? (
          <ImageWithFallbacks
            src={`${process.env.REACT_APP_SERVER_HOST}/storage/photo/${user.sm_photo}`}
            alt='user_img'
            fallbacks={`${photoUserErrHandler(user.photo)}`}
          />
        ) : (
          <PersonIcon />
        )}
      </div>
      <div className={styles.userInfo}>
        {user && user.type && (
          <>
            <h4>{`${user.first_name} ${user.last_name}`}</h4>
            <p>{user.type}</p>
          </>
        )}
      </div>
    </div>
  );
  const open = Boolean(anchorEl);

  const UserMenu = () => {
    return (
      <div className={styles.userMenu}>
        <List className={styles.popupWrapper}>
          {NavMenuList.map((item, index) => (
            <ListItem button key={index} className={styles.myWallet}>
              <NavLink
                activeClassName={styles.activeLink}
                to={`/user/${item.url === 'profile' ? 'profile/' + user.id : item.url}`}
                key={index}
              >
                {icons[item.url] && icons[item.url]} {item.name}
              </NavLink>
            </ListItem>
          ))}

          {user && user.type && (
            <ListItem button className={styles.myWallet}>
              <NavLink
                activeClassName={styles.activeLink}
                to={`/user/${user.type === 'Student' ? 'my-live-classes' : 'live-classes'}`}
              >
                <Camera /> {user.type === 'Student' ? 'My sign ups' : 'All live classes'}
              </NavLink>
            </ListItem>
          )}
          {user && user.type && (
            <ListItem button className={styles.myWallet}>
              <NavLink activeClassName={styles.activeLink} to={`/user/wallet`}>
                <Wallet />
                My wallet
              </NavLink>
            </ListItem>
          )}

          {user && user.types && user.types.length > 1 && (
            <ListItem
              button
              onClick={() =>
                dispatch(
                  switchType({
                    type: user.type && user.type === 'Instructor' ? 'Student' : 'Instructor',
                  })
                )
              }
              className={styles.switchType}
            >
              <Switch /> Switch the account
            </ListItem>
          )}
          <hr className={styles.horDivider} />
          <ListItem button onClick={() => logout()} className={styles.logOut}>
            <LogOut /> Logout
          </ListItem>
        </List>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <List className={styles.userButtons}>
        <ListItem aria-describedby={'wallet'} onClick={handleClick}>
          <span>
            {' '}
            {icons['wallet']} {Number(balance)}
          </span>
        </ListItem>
      </List>
      <Popover
        id={'wallet'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: styles.walletPopover }}
      >
        <div className={styles.arrow} />
        <div className={styles.popoverWrapper}>
          {balance ? (
            <h2>
              <span>$</span>
              {balance}
            </h2>
          ) : (
            <Coins />
          )}
          <h4>{balance ? 'Current balance' : 'No money on balance'}</h4>
          <p>Add money to my wallet</p>
        </div>
        <Link className={styles.addButton} to={'/user/wallet'} onClick={() => setAnchorEl(null)}>
          Add
        </Link>
      </Popover>

      <Hidden smDown>
        <Popup button={<UserButton user={user} />}>
          <UserMenu />
        </Popup>
      </Hidden>
      <Hidden mdUp>
        <hr className={styles.horDivider} />
        <UserMenu />
      </Hidden>
    </div>
  );
};

export default NavMenu;
