import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './LiveClasses.module.scss';
import Button from '../../../common/Button/Button';
import AsideFilter from './AsideFilter/AsideFilter';
import SearchBar from '../SearchBar/SearchBar';
import { StateType } from '../../../../redux/store';
import { getClasses, getCategories, getLevels, filterClasses } from '../../../../redux/actions/liveClassesActions';
import ClassesList from '../ClassesList/ClassesList';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { getFilters } from '../../../../helpers/liveClassesfilters';
import { Echo } from '../../../../index';
import { updateLiveClasses } from '../../../../redux/reducers/liveClasses';
import LoadMore from '../Instructors/LoadMore/LoadMore';
import { Hidden, Drawer } from '@material-ui/core';
import { SideDrawer } from '../../../components/SideDrawer/SideDrawer';

const LiveClasses: React.FC = (props: any) => {
  const { liveClasses, categories, levels, disableLoadmore } = useSelector((state: StateType) => state.liveClasses);

  const query = new URLSearchParams(useLocation().search);
  const status = query.get('status[]');

  const type = props && props.location && props.location.state && props.location.state.type;

  const arrFromState = props && props.location && props.location.state && props.location.state.arr;

  const [filteredClasses, setFilteredClasses] = useState(liveClasses);
  const [offset, setOffset] = useState(5);

  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCategories(null));
    dispatch(getLevels(null));
    Echo.channel('LiveClasses').listen('LiveClassUpdated', (e: any) => {
      dispatch(updateLiveClasses(e.liveClass));
    });
    return () => {
      Echo.channel('LiveClasses').stopListening('LiveClassUpdated');
    };
  }, []);

  useEffect(() => {
    if (liveClasses) {
      setFilteredClasses(liveClasses);
    } /* else if (arrFromState) {
      setFilteredClasses(arrFromState);
    } */
  }, [liveClasses, arrFromState]);

  const statusTitle = () => {
    if (type) {
      return type !== ('instructor' || 'recurring') && `All ${type} classes`;
    } else {
      return status ? `All ${status} classes` : 'All running/upcoming live classes';
    }
  };

  /*  const loadMore = () => {
    dispatch(
      filterClasses({ filter: getFilters(), limit: offset, offset: offset })
    );
    setOffset(offset + 5);
  }; */

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchWrapper}>
        <div className={styles.title}>
          <h1>{statusTitle()}</h1>
        </div>
        <SearchBar />
        <Hidden mdUp>
          <SideDrawer className={styles.sideFilters}>
            <AsideFilter />
          </SideDrawer>
        </Hidden>
      </div>

      <Hidden smDown>
        <AsideFilter />
      </Hidden>
      <div className={styles.contentWrapper}>
        {filteredClasses && <ClassesList arr={filteredClasses} type={'recommended'} isVertical={true} />}
        {/*    <Button
          className={styles.loadMoreButton}
          onClick={() => console.log('load more')}
        >
          Load more
        </Button> */}
        <LoadMore
          isLiveClasses
          disableLoadmore={disableLoadmore ? disableLoadmore : !filteredClasses.length ? true : false}
        />
      </div>
    </div>
  );
};

export default LiveClasses;
