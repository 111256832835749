import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../UserPhoto.module.scss';
import { submit } from 'redux-form';
import Button from '../../../../../common/Button/Button';
import { StateType } from '../../../../../../redux/store';

type SubmitButtonType = {
  currTab: number;
};

const SubmitButton: React.FC<SubmitButtonType> = ({ currTab }) => {
  const dispatch = useDispatch();
  const { userPhoto } = useSelector((state: StateType) => state.form);

  const formSubmit = () => {
    switch (currTab) {
      case 0:
        dispatch(submit('profileTab'));
        userPhoto.values && dispatch(submit('userPhoto'));
        break;
      case 1:
        dispatch(submit('passwordTab'));

        break;
      case 2:
        dispatch(submit('notificationsTab'));
        break;

      default:
        break;
    }
  };

  return (
    <div className={styles.buttons}>
      <Button
        type='submit'
        color='primary'
        variant='contained'
        onClick={(e) => {
          e.preventDefault();
          formSubmit();
        }}
      >
        Save
      </Button>
    </div>
  );
};

export default SubmitButton;
