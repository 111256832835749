import {
  updatePassword,
  getNotifications,
  updateProfile,
  connectWithStripe,
  refreshStripeAccount,
  setupPaymentMethod,
  withdrawnStripe,
  deleteStudentCard,
} from './../actions/userActions';
import { editClass, createLiveClass } from './../actions/liveClassesActions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setNotifications } from '../actions/userActions';
import { signUp } from '../actions/authActions';
import { applyCode } from '../actions/promoActions';

export type AlertType = {
  open: boolean;
  type: string;
  message: string;
  length?: number;
};

const defaultState: AlertType = {
  open: false,
  type: 'success',
  message: '',
  length: 5000,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState: defaultState,
  reducers: {
    setAlert: (state, { payload }) => {
      state.open = true;
      state.type = payload.type;
      state.message = payload.message;
      state.length = +payload.time >= 0 ? payload.time : 5000;
    },
    closeAlert: (state) => {
      state.open = false;
      state.message = '';
    },
  },
  extraReducers: {
    [editClass.pending.toString()]: (state) => {},
    [editClass.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'success';
      state.message = 'Update successful!';
    },
    [editClass.rejected.toString()]: (state, { payload }) => {
      state.type = 'error';
      state.message = 'Something went wrong';
    },
    [createLiveClass.pending.toString()]: (state) => {},
    [createLiveClass.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'success';
      state.message = 'Class successfully created!';
    },
    [createLiveClass.rejected.toString()]: (state, { payload }) => {
      state.type = 'error'; /* ToDo: Doesn't work */
      state.message = payload?.data?.message??'Something went wrong'; /* ToDo: Temporary added ability to see actual errors that are going from the server */
      state.length = Number(0); /* ToDo: Doesn't work */
    },
    [setNotifications.pending.toString()]: (state) => {},
    [setNotifications.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'success';
      state.message = 'Notifications settings updated!';
    },
    [setNotifications.rejected.toString()]: (state, { payload }) => {
      state.type = 'error';
      state.message = 'Something went wrong';
    },
    [getNotifications.pending.toString()]: (state) => {},
    [getNotifications.fulfilled.toString()]: (state, { payload }) => {
      state.message = '';
      state.open = false;
    },
    [getNotifications.rejected.toString()]: (state, { payload }) => {
      state.message = '';
      state.open = false;
    },
    [updatePassword.pending.toString()]: (state) => {},
    [updatePassword.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = payload && payload.success ? 'success' : 'error';
      state.message = payload && payload.success ? 'Password successfully updated!' : 'Something went wrong';
    },
    [updatePassword.rejected.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'error';
      state.message = 'Something went wrong';
    },
    [updateProfile.pending.toString()]: (state) => {},
    [updateProfile.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = payload && payload.success ? 'success' : 'error';
      state.message = payload && payload.success ? 'Profile successfully updated!' : 'Something went wrong';
    },
    [updateProfile.rejected.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'error';
      state.message = 'Something went wrong';
    },
    [signUp.pending.toString()]: (state) => {},
    [signUp.fulfilled.toString()]: (state, { payload }) => {
      if (payload?.status === 'Inactive') {
        state.open = true;
        state.type = payload ? 'success' : 'error';
        state.message = payload ? 'Check your email, please' : 'Something went wrong';
      }
    },
    [signUp.rejected.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'error';
      state.message = 'Something went wrong';
    },
    [connectWithStripe.pending.toString()]: (state) => {},
    [connectWithStripe.fulfilled.toString()]: (state, { payload }) => {
      /*   state.open = true;
      state.type = payload ? 'success' : 'error';
      state.message = payload
        ? 'Check your email, please'
        : 'Something went wrong'; */
    },
    [connectWithStripe.rejected.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'error';
      state.message = payload.message;
    },
    [refreshStripeAccount.pending.toString()]: (state) => {},
    [refreshStripeAccount.fulfilled.toString()]: (state, { payload }) => {
      /*  state.open = true;
      state.type = payload ? 'success' : 'error';
      state.message = payload
        ? 'Check your email, please'
        : 'Something went wrong'; */
    },
    [refreshStripeAccount.rejected.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'error';
      state.message = payload.message;
    },
    [setupPaymentMethod.pending.toString()]: (state) => {},
    [setupPaymentMethod.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      console.log(payload);
      state.type = payload ? 'success' : 'error';
      state.message = payload.message;
    },
    [setupPaymentMethod.rejected.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'error';
      state.message = payload.message;
    },
    [deleteStudentCard.pending.toString()]: (state) => {},
    [deleteStudentCard.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = payload ? 'success' : 'error';
      state.message = payload.message;
    },
    [deleteStudentCard.rejected.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'error';
      state.message = payload.message;
    },
    [withdrawnStripe.pending.toString()]: (state) => {},
    [withdrawnStripe.fulfilled.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = payload ? 'success' : 'error';
      state.message = payload.message;
    },
    [withdrawnStripe.rejected.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'error';
      state.message = payload.message;
      state.length = 10000;
    },
    [applyCode.fulfilled.toString()]: (state, { payload }) => {
      console.log('applyCode');
      console.log(payload);
      state.open = true;
      state.type = 'success';
      state.message = payload.message;
    },
    [applyCode.rejected.toString()]: (state, { payload }) => {
      state.open = true;
      state.type = 'error';
      state.message = payload.data.message;
    },
  },
});

const alertReducer = alertSlice.reducer;

export const { setAlert, closeAlert } = alertSlice.actions;
export default alertReducer;

export const alertShowThunk = (type: string, message: string, time?: number): any => async (dispatch: any) => {
  const alertData = {
    type: type,
    message: message,
    time,
  };
  dispatch(setAlert(alertData));
};

export const alertCloseThunk = () => async (dispatch: any) => {
  dispatch(closeAlert());
};
