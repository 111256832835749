import React, { useState, useEffect, ReactElement, useRef, useCallback } from 'react';
import styles from './Chat.module.scss';
import ChatInput from './ChatInput/ChatInput';

import { usePrevious } from '../../../../../../helpers/hooks';
import FlatList from './FlatList/FlatList';
import Loader from '../../../../../common/Loader/Loader';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../../redux/store';
import { resetChat, updateChatMessages } from '../../../../../../redux/reducers/liveClasses';
import { getClassChatMessages } from '../../../../../../redux/actions/liveClassesActions';
import { Echo } from '../../../../../../index';

type ChatType = {
  userId: number;
};

const Chat: React.FC<ChatType> = ({ userId }) => {
  const dispatch = useDispatch();
  const { chat, liveClass } = useSelector((state: StateType) => state.liveClasses);
  const messages = chat;
  const prevMessages: any = usePrevious({ messages });

  const [mess, setMessages] = useState<any>([]);
  const [chunkSize, setChunkSize] = useState(20);
  const [refreshing, setRefreshing] = useState(false);
  const [disableFetch, setDisableFetch] = useState(false);
  const [showToBottom, setShowToBottom] = useState(false);

  const flatListRef: any = useRef();

  let classChat: any = null;
  useEffect(() => {
    if (liveClass.id) {
      dispatch(getClassChatMessages(liveClass.id));
      classChat = Echo.join(`liveClassMessages.${liveClass.id}`);
      // @ts-ignore
      classChat.listen('LiveClassMessageSent', (e: any) => {
        dispatch(updateChatMessages(e.message));
      });
    }
    return () => {
      dispatch(resetChat());
      classChat && classChat.stopListening('LiveClassMessageSent');
    };
  }, [liveClass.id]);

  useEffect(() => {
    if (messages.length) {
      const newArr = [...messages].slice(Math.max(messages.length - 10, 0));
      setMessages(newArr);
    }

    flatListRef.current.addEventListener('scroll', handleScrollOnTop);

    setTimeout(() => scrollToEnd(), 200);

    return () => {
      flatListRef.current.removeEventListener('scroll', handleScrollOnTop);
    };
  }, [messages]);

  const scrollToEnd = () => {
    const listHeight = flatListRef.current.scrollHeight;
    const elHeight = flatListRef.current.clientHeight;
    flatListRef.current.scrollTop = listHeight - elHeight;
  };

  const handleScrollOnTop = (e: any) => {
    const el = e.target;

    if (!el.scrollTop) {
      setRefreshing(true);
      setDisableFetch(true);
    } else if (el.clientHeight + el.scrollTop + 100 <= el.scrollHeight) {
      setShowToBottom(true);
    } else {
      setShowToBottom(false);
    }
  };

  useEffect(() => {
    refreshing && !disableFetch && fetchData();
  }, [refreshing, mess]);

  useEffect(() => {
    if (prevMessages?.messages?.length && prevMessages.messages?.length !== messages?.length) {
      const lastMessageIdx = messages?.length - 1;
      const newMessages = [...mess, messages[lastMessageIdx]];

      messages[lastMessageIdx] && setMessages(newMessages);
      setTimeout(() => scrollToEnd(), 200);
    } else if (prevMessages?.messages?.length === messages?.length && mess.length === 0) {
      messages[0] && setMessages(messages);
    }
  }, [prevMessages, messages]);

  const fetchData = async () => {
    const chunkArr = [...messages].slice(-chunkSize, -(chunkSize - 10));
    const newRecords = chunkArr.concat(mess);

    if (newRecords.length < messages.length) {
      setMessages(newRecords);
      setChunkSize(chunkSize + 10);
    } else if (newRecords.length === messages.length) {
      setMessages(newRecords);
      setChunkSize(0);
    }
    setTimeout(() => {
      if (flatListRef.current && mess.length !== messages.length) {
        const elHeight = flatListRef.current.clientHeight;
        flatListRef.current.scrollTop = elHeight - 50;
      }
      setDisableFetch(false);
      setRefreshing(false);
    }, 1000);
  };

  return (
    <div className={styles.wrapper}>
      {refreshing && mess?.length !== messages?.length && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <div
        className={styles.scrollableDiv}
        ref={flatListRef}
        style={{ marginTop: mess.length !== messages.length ? '50px' : '0' }}
      >
        <FlatList data={mess} ownerId={userId} />
        {showToBottom && (
          <IconButton
            className={styles.toBottomButton}
            onClick={() => {
              setShowToBottom(false);
              scrollToEnd();
            }}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        )}
      </div>
      {liveClass.status === 'Upcoming' || liveClass.status === 'Running' ?
        <ChatInput />
        :
        ''}
    </div>
  );
};

export default Chat;
