import React, { useState, useEffect, ChangeEvent, SyntheticEvent } from 'react';
import styles from './SelectedInput.module.scss';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputBase from '@material-ui/core/InputBase';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { WrappedFieldInputProps } from 'redux-form';
import { differenceBy } from 'lodash';

type SelectedInputType = {
  name: string;
  onChange?: any;
  data?: Array<any>;
  defaultValue?: any;
  handleChange?: Function;
  input?: WrappedFieldInputProps;
  customStyles?: any;
  disableValues?: boolean;
  listStyle?: any;
  wrapperStyle?: any;
};

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {},

    input: {
      borderRadius: 6,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid rgba(71, 70, 71, 0.26)',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: ['-apple-system'].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#5900a8',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
      '&:hover': {
        borderColor: '#5900a8',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
)(InputBase);

const SelectedInput: React.FC<SelectedInputType> = ({
  name,
  onChange,
  data,
  defaultValue,
  handleChange,
  input,
  customStyles,
  disableValues,
  listStyle,
  wrapperStyle,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [open, setOpen] = useState(false);
  const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    input && input.onChange(event.target.value);
    let elSorted = data?.find((el) => el.id === event.target.value);
    setValue(event.target.value as string);
    elSorted && onChange && onChange(elSorted);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const menuArr = data
    ? data
    : [
        { id: 0, name: 'some name 1' },
        { id: 1, name: 'some name 2' },
        { id: 2, name: 'some name 3' },
        { id: 3, name: 'some name 4' },
      ];

  return (
    <FormControl className={`${styles.wrapper} ${wrapperStyle ? wrapperStyle : ''}`}>
      {(!value || disableValues) && (
        <InputLabel shrink={false} htmlFor='selectableInputLabel'>
          {name}
        </InputLabel>
      )}
      <Select
        id={'selectableInput'}
        labelId={'selectableInputLabel'}
        value={value ? value : ''}
        onChange={handleOnChange}
        input={<BootstrapInput />}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        className={customStyles}
        MenuProps={{
          classes: { list: listStyle, paper: styles.dropdownList },
        }}
        IconComponent={ExpandMoreIcon}
      >
        {menuArr.map((el) => {
          return (
            <MenuItem key={`${el.id}`} classes={{ root: styles.selectRoot }} value={el.id}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectedInput;
