import { optionsSelecter } from './fakeValues';

export const value = 'value';

export const subjectList = [
  'Math',
  'English',
  'Science',
  'Foreign language',
  'Computers',
  'Art',
  'Sport & fitness',
  'Business',
  'Musical & drama',
  'Spaecial education',
];

export const studentMenu = [
  { name: 'Live classes', link: 'live-classes' },
  { name: 'Find your instructor', link: 'instructors-list' },
];

export const instructorMenu = [
  { name: 'My live classes', link: 'my-live-classes' },
  { name: 'Schedule live class', link: 'schedule-classes' },
  { name: 'Find your instructor', link: 'instructors-list' },
];

export const navMenuSubHeaderParent = [
  { name: 'Teachers', url: 'teachers' },
  { name: 'Study PODs', url: 'study-pods' },
  { name: 'Curriculums', url: 'curriculums' },
  {
    name: 'Schools/Online Tutoring',
    url: 'schools',
  },
];

export const navMenuSubHeaderTeacher = [
  {
    name: 'Teacher jobs',
    url: 'teachers-jobs',
  },
  {
    name: 'Curriculums',
    url: 'curriculums',
  },
];

export const filtersList = [
  { label: 'Location', options: optionsSelecter },
  { label: 'Availability', options: optionsSelecter },
  { label: 'Subject', options: optionsSelecter },
  { label: 'Hourly rate', options: optionsSelecter },
  { label: 'Level', options: optionsSelecter },
  { label: 'Number of kids', options: optionsSelecter },
  { label: 'Duration', options: optionsSelecter },
];

export const NavMenuList = [
  { name: 'Profile', url: 'profile' },
  { name: 'Settings', url: 'settings' },
];
/* export const NavMenuList = [
  { name: 'Profile', url: 'profile-step1' },
  { name: 'My Reviews', url: 'my-reviews' },
  { name: 'Subscription', url: 'subscription' },
  { name: 'Support', url: 'support' },
  { name: 'Swith to teacher view', url: '#' },
];
 */
export const subscriptionsList = [
  {
    name: '12 month',
    description: '(annual)',
    cost: '$12/month',
    value: 12,
  },
  {
    name: '3 month',
    description: '(quarterly)',
    cost: '$25/month',
    value: 25,
  },
  {
    name: '2 month',
    description: '(monthly)',
    cost: '$38/month',
    value: 38,
  },
];

export const footerMenuList = [
  { name: 'Terms of use', url: '/terms-of-use' },
  { name: 'Privacy policy', url: '/privacy-policy' },
];
