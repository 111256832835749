import React, { useEffect, useState, ChangeEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';
import Input from '../../../../common/Input/Input';
import { StateType } from '../../../../../redux/store';
import { required, email, password, dataCheck } from '../../../../../helpers/validate';
import styles from './DataStep.module.scss';
import { setNewClass } from '../../../../../redux/reducers/liveClasses';
import Checkbox from '@material-ui/core/Checkbox';
import SelectableCheckbox from '../../../../common/SelectableCheckbox/SelectableCheckbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import AutocompleteInput from '../../../../common/AutocompleteInput/AutocompleteInput';
import { ReactComponent as Note } from '../../../../../assets/svg/liveClasses/note.svg';
import DatePicker from './DatePicker/DatePicker';
import TimePicker from './TimePicker/TimePicker';
import { start } from 'repl';
import moment from 'moment';
import { dateWithTimezone, timeWithOffset } from '../../../../../helpers/functions';

export type DataStepType = {
  oneTime: boolean;
  weekdays?: Array<any>;
  startDate: string;
  endDate?: string;
  time: string;
  price: number | string;
  duration: number | string;
};

const DataStep: React.FC<InjectedFormProps<DataStepType>> = ({ handleSubmit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { newClass, commission } = useSelector((state: StateType) => state.liveClasses);

  const { user } = useSelector((state: StateType) => state.user);

  const [formObj, setFormObj] = useState<DataStepType>({} as DataStepType);
  const [recurring, setRecurring] = useState(false);
  const [startD, setStartDate] = useState('');
  const [endD, setEndDate] = useState('');
  const [priceValue, setPriceValue] = useState(0);

  useEffect(() => {
    if (newClass.data) {
      let weekdays = newClass.data.weekdays && {
        weekdays: newClass.data.weekdays,
      };
      const sortedWeekdays = weekdaysArr.map((el) => {});
      let endDate = newClass.data.endDate && {
        endDate: newClass.data.endDate,
      };
      setFormObj({
        oneTime: newClass.data.oneTime,

        startDate: newClass.data.startDate,
        time: newClass.data.time,
        price: newClass.data.price,
        duration: newClass.data.duration,
        ...weekdays,
        ...endDate,
      });
      setRecurring(!newClass.data.oneTime);
    }
  }, []);

  useEffect(() => {
    if (newClass.data) {
      let weekdays = newClass.data.weekdays && {
        weekdays: newClass.data.weekdays,
      };
      const sortedWeekdays = weekdaysArr.map((el) => {});
      let endDate = newClass.data.endDate && {
        endDate: newClass.data.endDate,
      };
      setFormObj({
        oneTime: newClass.data.oneTime,

        startDate: newClass.data.startDate,
        time: newClass.data.time,
        price: newClass.data.price,
        duration: newClass.data.duration,
        ...weekdays,
        ...endDate,
      });
      setRecurring(!newClass.data.oneTime);
    }
  }, [newClass]);

  const onSubmit = () => {
    handleSubmit((value) => {
      const duration = durationArr.find((el) => el.id === value.duration);
      const weekdaysObj = value.weekdays ? { weekdays: value.weekdays } : {};

      dispatch(
        setNewClass({
          ...newClass,
          data: {
            ...value,
            duration: duration ? duration.duration : '',
            ...weekdaysObj,
          },
        })
      );
    })();
  };

  const FrequencyCheck = ({ input }: any) => {
    const [oneTime, setOneTime] = useState(!recurring);

    useEffect(() => {
      input && input.onChange(oneTime);
    }, [oneTime]);

    return (
      <div className={styles.frequencyWrapper}>
        <div>
          <Checkbox
            checked={!recurring}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            onChange={(e: any) => {
              setOneTime(true);
              setRecurring(false);
            }}
            classes={{ checked: styles.checkedBox }}
          />
          <p>One-time live class</p>
        </div>
        <div>
          <Checkbox
            checked={recurring}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            onChange={(e: any) => {
              setOneTime(false);
              setRecurring(true);
            }}
            classes={{ checked: styles.checkedBox }}
          />
          <p> Recurring live class</p>
        </div>
      </div>
    );
  };

  const durationArr = [
    { id: 1, name: '30min', duration: '00:30:00' },
    { id: 2, name: '1h', duration: '01:00:00' },
    { id: 3, name: '1h30min', duration: '01:30:00' },
    { id: 4, name: '2h', duration: '02:00:00' },
  ];
  const weekdaysArr = [
    { id: 0, name: 'Monday' },
    { id: 1, name: 'Tuesday' },
    { id: 2, name: 'Wednesday' },
    { id: 3, name: 'Thursday' },
    { id: 4, name: 'Friday' },
    { id: 5, name: 'Saturday' },
    { id: 6, name: 'Sunday' },
  ];

  let currDate = timeWithOffset(user.timezone ? user.timezone : '').format('YYYY-MM-DD');

  const commissionHandler = (price: number, percent: number) => {
    if (price > 0) {
      return price - price * (percent / 100);
    } else {
      return price;
    }
  };

  return (
    <Form onSubmit={onSubmit} className={styles.form}>
      <div className={styles.inputs}>
        <h4>Session frequency *</h4>
        <Field
          component={FrequencyCheck}
          name='oneTime'
          label='Type the title of the live class (e.g. Rocket science)'
          type='checkbox'
          validate={[]}
        />

        <div className={`${styles.inputsWrapper} ${recurring ? styles.inputsWrapperRecurring : ''}`}>
          <div>
            <div>
              <h4>Live class date {recurring && 'From'} *</h4>

              <Field
                component={DatePicker}
                name='startDate'
                label='Start date'
                validate={[required]}
                defaultValue={formObj && formObj.startDate ? formObj.startDate : currDate}
                classes={{ root: styles.datePicker }}
                handleStartDate={(e: string) => setStartDate(e)}
                currDateTime={timeWithOffset(user.timezone ? user.timezone : '')}
                timezone={user?.timezone}
              />
            </div>
            {recurring && (
              <div>
                <h4>Live class date To *</h4>

                <Field
                  component={DatePicker}
                  name='endDate'
                  label='End date'
                  validate={[required, dataCheck]}
                  defaultValue={formObj && formObj.endDate ? formObj.endDate : currDate}
                  classes={{ root: styles.datePicker }}
                  handleEndDate={(e: string) => setEndDate(e)}
                  currDateTime={timeWithOffset(user.timezone ? user.timezone : '')}
                />
              </div>
            )}
            <div>
              <h4>Live class time *</h4>

              <Field
                component={TimePicker}
                name='time'
                label='Time'
                validate={[required]}
                defaultValue={formObj && formObj.time ? formObj.time : ''}
                startDate={(formObj && formObj.startDate) || (startD && startD)}
                endDate={(formObj && formObj.endDate) || (endD && endD)}
                recurring={recurring}
                currDateTime={timeWithOffset(user.timezone ? user.timezone : '')}
                timeZone={user.timezone}
              />
            </div>
          </div>
          {recurring && (
            <div>
              <h4>Weekday *</h4>
              <Field
                component={SelectableCheckbox}
                name='weekdays'
                label='Select day'
                type='number'
                validate={[required]}
                data={weekdaysArr}
                defaultValue={formObj && formObj.weekdays}
                checkbox
                isMultiple
              />
            </div>
          )}
          <div>
            <div>
              {/*  <h4>Duration *</h4>
              <Field
                component={AutocompleteInput}
                name='duration'
                label='Select the level of the students the live class is for.'
                type='number'
                validate
                data={durationArr}
              /> */}

              <AutocompleteInput
                title={'Duration *'}
                data={durationArr}
                duration
                label={'Select the duration'}
                defaultValue={formObj && formObj.duration ? formObj.duration : '01:00:00'}
              />
            </div>
            <div className={styles.priceWrapper}>
              <h4>
                Price per student *<span className={styles.priceHelper}>set 0 to make live class free</span>
              </h4>
              <Field
                component={Input}
                label='Price'
                type='number'
                name='price'
                balanceValue
                zero
                validate={[required]}
                defaultValue={formObj && formObj.price ? formObj.price : '0'}
                onChange={(e: any) => setPriceValue(+e)}
              />
              {priceValue > 0 && (
                <div className={styles.commission}>
                  You will get {commissionHandler(priceValue, commission)}$ for every sign up
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.note}>
          <Note />{' '}
          <p>
            Note: You will not be able to change a live class date and time in future as well as duration and price. If
            you are unable to conduct a live class at the scheduled time, please cancel it so students can get a refund
            and create a new session at another time.
          </p>
        </div>
        {/*    {errors && <div className='error'>{errors}</div>} */}
      </div>
    </Form>
  );
};

export default reduxForm<DataStepType>({
  form: 'scheduleData',
})(DataStep);
