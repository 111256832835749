import React, { useEffect, useState, ChangeEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form, submit } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';
import Input from '../../../../common/Input/Input';
import { StateType } from '../../../../../redux/store';

import styles from './UserPhoto.module.scss';
import PersonIcon from '@material-ui/icons/Person';
import { Hidden } from '@material-ui/core';
import Button from '../../../../common/Button/Button';
import FileInput from '../../../../common/FileInput/FileInput';
import { updateProfile } from '../../../../../redux/actions/userActions';
import UserPhotoForm from './UserPhotoForm/UserPhotoForm';
import SubmitButton from './SubmitButton/SubmitButton';

const UserPhoto: React.FC<any> = ({ user, isFileUpload, currTab }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userPhoto } = useSelector((state: StateType) => state.form);
  const [photoExist, setPhotoExist] = useState(true);
  const formSubmit = () => {
    switch (currTab) {
      case 0:
        dispatch(submit('profileTab'));
        userPhoto.values && dispatch(submit('userPhoto'));
        break;
      case 1:
        dispatch(submit('passwordTab'));

        break;
      case 2:
        dispatch(submit('notificationsTab'));
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className={styles.photoWrapper}>
        <div>
          <div className={styles.photo}>
            {isFileUpload ? (
              <UserPhotoForm />
            ) : (
              <>
                {user.md_photo && photoExist ? (
                  <img
                    src={`${process.env.REACT_APP_SERVER_HOST}/storage/photo/${user.md_photo}`}
                    alt='user_img'
                    onError={() => {
                      setPhotoExist(false);
                    }}
                  />
                ) : (
                  <div className={styles.noPhoto}>
                    <PersonIcon />
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <Hidden smDown>
          <SubmitButton currTab={currTab} />
        </Hidden>
      </div>
      <Hidden mdUp>
        <SubmitButton currTab={currTab} />
      </Hidden>
    </>
  );
};

export default UserPhoto;
