import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import styles from "./MainPage.module.scss";
import MainLayout from "../../layout/MainLayout/MainLayout";
import { useHistory } from "react-router-dom";
import LiveClasses from "./LiveClasses/LiveClasses";
import MyLiveClasses from "./MyLiveClasses/MyLiveClasses";
import EditLiveClass from "./EditLiveClass/EditLiveClass";
import Home from "./Home/Home";
import ScheduleClasses from "./ScheduleClasses/ScheduleClasses";
import Settings from "./Settings/Settings";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../redux/store";
import LiveClass from "./LiveClass/LiveClass";
import Wallet from "./Wallet/Wallet";
import Stream from "./Stream/Stream";
import Profile from "./Profile/Profile";
import Instructors from "./Instructors/Instructors";
import ErrorPage from "../ErrorPages/ErrorPage";

const MainPage: React.FC = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { errors } = useSelector((state: StateType) => state.liveClasses);

  const userRoutes = [
    "live-classes",
    "my-live-classes",
    "edit-live-class",
    "home",
    "schedule-classes",
    "wallet",
    "profile",
    "settings",
    "instructors-list",
  ];

  useEffect(() => {
    if (props.match.params && props.match.params.category) {
      const matched = userRoutes.find(
        (el) => el === props.match.params.category
      );

      !matched && history.push("/page-not-found");

      if (
        (matched === "edit-live-class" || matched === "profile") &&
        !props.match.params.id
      ) {
        history.push("/page-not-found");
      }
      /* matched === 'my-live-classes' &&
        user.type === 'Student' &&
        history.push('/user/home'); */
    }
  }, [props.match]);

  useEffect(() => {
    if (errors) {
      if (errors.status === 404) {
        history.push("/page-not-found", { code: 404 });
      } else if (errors.status === 403) {
        history.push("/page-not-found", { code: 403 });
      } else if (errors.status === 502) {
        history.push("/page-not-found", { code: 502 });
      }
    }
  }, [errors]);

  const typeFromRoute = () => {
    const matched = userRoutes.find(
      (el: any) => el === props.match.params.category
    );

    return matched;
  };

  return (
    <MainLayout type={typeFromRoute() ? typeFromRoute() : ""}>
      <Route
        path="/user/live-classes"
        exact
        render={(props: any) => <LiveClasses {...props} />}
      />
      <Route
        path="/user/instructors-list"
        exact
        render={(props: any) => <Instructors {...props} />}
      />
      <Route
        path="/user/my-live-classes"
        exact
        render={(props: any) => <MyLiveClasses {...props} />}
      />
      <Route
        path="/user/edit-live-class/:id"
        exact
        render={(props: any) => <EditLiveClass {...props} />}
      />
      <Route
        path="/user/live-classes/:id"
        exact
        render={(props: any) => <LiveClass {...props} />}
      />
      <Route
        path="/user/live-classes/:id/:stream"
        exact
        render={(props: any) => <Stream {...props} />}
      />

      <Route
        path="/user/home"
        exact
        render={() => {
          return <Home />;
        }}
      />
      <Route
        path="/user/schedule-classes"
        exact
        render={() => {
          return <ScheduleClasses />;
        }}
      />
      <Route
        path="/user/wallet"
        exact
        render={() => {
          return <Wallet />;
        }}
      />
      <Route
        path="/user/settings"
        exact
        render={() => {
          return <Settings />;
        }}
      />
      <Route
        path="/user/profile/:id"
        exact
        render={() => {
          return <Profile />;
        }}
      />
    </MainLayout>
  );
};

export default MainPage;
