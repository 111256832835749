import { createAsyncThunk } from '@reduxjs/toolkit';
import { user } from '../../api/user';

export const getProfile = createAsyncThunk('user/getProfile', async () => {
  try {
    const data = await user.getProfile();

    return data;
  } catch (err) {
    localStorage.clear();
    return err.response.data;
  }
});

export const signOut = createAsyncThunk('user/home-signOut', async () => {
  try {
    const data = await user.signOut();
    localStorage.clear();
    return data;
  } catch (err) {
    localStorage.clear();
    return err.response.data;
  }
});

export const getBalance = createAsyncThunk('getBalance', async () => {
  try {
    const data = await user.getBalance();
    return data;
  } catch (err) {
    return err.response.data;
  }
});

export const replenishBalance = createAsyncThunk(
  'replenishBalance',
  async (value: number) => {
    try {
      const data = await user.replenishBalance(value);
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const connectWithStripe = createAsyncThunk(
  'connectWithStripe',
  async (value: null, thunkAPI: any) => {
    try {
      const data = await user.connectWithStripe();
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const setNotifications = createAsyncThunk(
  'setNotifications',
  async (value: any) => {
    try {
      const { data } = await user.setNotifications(value);
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getNotifications = createAsyncThunk(
  'getNotifications',
  async () => {
    try {
      const { data } = await user.getNotifications();
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const updateProfile = createAsyncThunk(
  'updateProfile',
  async (value: any) => {
    try {
      const data = await user.updateProfile(value);
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getSpecificUser = createAsyncThunk(
  'getSpecificUser',
  async (value: any) => {
    try {
      const data = await user.getSpecificUser(value);
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const getListOfUsers = createAsyncThunk(
  'getListOfUsers',
  async (value: {
    filter: {
      type: 'Instructor' | 'Student';
      status: Array<string>;
    };
    limit?: number;
    offset?: number;
  }) => {
    try {
      const data = await user.getListOfUsers(value);
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const updatePassword = createAsyncThunk(
  'updatePassword',
  async (value: { old_password: string; new_password: string }) => {
    try {
      const data = await user.updatePassword(value);

      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);

export const setupPaymentMethod = createAsyncThunk(
  'users/wallet/paymentMethod',
  async (values: any | {}, thunkAPI) => {
    try {
      const { data } = await user.paymentMethod(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteStudentCard = createAsyncThunk(
  'users/wallet/deleteStudentCard',
  async (values: any | {}, thunkAPI) => {
    try {
      const { data } = await user.deleteStudentCard();

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const refreshStripeAccount = createAsyncThunk(
  'refreshStripeAccount',
  async (values: any | {}, thunkAPI) => {
    try {
      const { data } = await user.refreshStripeAccount(values);

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const withdrawnStripe = createAsyncThunk(
  'withdrawnStripe',
  async (values: any | {}, thunkAPI) => {
    try {
      const { data } = await user.withdrawnStripe(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const setUserPhoto = createAsyncThunk(
  'setUserPhoto',
  async (values: string, thunkAPI) => {
    try {
      const { data } = await user.setUserPhoto(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
