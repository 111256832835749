import React, { useState, useEffect } from 'react';
import styles from './SingleClass.module.scss';
import { ReactComponent as Fire } from '../../../../../assets/svg/liveClasses/fire.svg';
import { ReactComponent as Clock } from '../../../../../assets/svg/liveClasses/clock.svg';
import { ReactComponent as Signal } from '../../../../../assets/svg/liveClasses/signal.svg';
import { ReactComponent as Dollar } from '../../../../../assets/svg/liveClasses/dollar.svg';
import { ReactComponent as Star } from '../../../../../assets/svg/liveClasses/star.svg';
import { ReactComponent as FilledStar } from '../../../../../assets/svg/liveClasses/starFilled.svg';
import { ReactComponent as Calendar } from '../../../../../assets/svg/liveClasses/calendar.svg';
import { ReactComponent as User } from '../../../../../assets/svg/liveClasses/user.svg';
import { ReactComponent as Camera } from '../../../../../assets/svg/liveClasses/camera.svg';
import { GridList, Modal } from '@material-ui/core';
import GridListTile from '@material-ui/core/GridListTile';
import { LiveClassType } from '../../../../../redux/reducers/liveClasses';
import { Link, useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StatusFlag from '../../../../common/StatusFlag/StatusFlag';
import {
  timeHandler,
  compareTime,
  timeLocalHandler,
  dateLocalHandler,
  dateHandler,
} from '../../../../../helpers/functions';
import { UserType } from '../../../../../redux/reducers/user';
import { useDispatch } from 'react-redux';
import { alertShowThunk } from '../../../../../redux/reducers/alert';
import { subscribeToClass, getUserClasses, getClass } from '../../../../../redux/actions/liveClassesActions';
import Button from '../../../../common/Button/Button';
import { Hidden } from '@material-ui/core';
import GridListTileBar from '@material-ui/core/GridListTileBar';

type SingleClassType = {
  el: LiveClassType;
  isVertical: boolean;
  index: number;
  dateDivider?: string;
  previewImg: number;
  user: UserType;
  joinButtonTitle: string;
  setSignUp: Function;
  signUp: boolean;
  setSelectedClass: Function;
  handleOpenModal: Function;
  userClasses: Array<LiveClassType>;
  handleOpenCancelModal: Function;
  handleOpenEndModal: Function;
  subscribe: Function;
};

const SingleClass: React.FC<SingleClassType> = ({
  el,
  isVertical,
  index,
  dateDivider,
  previewImg,
  user,
  joinButtonTitle,
  setSignUp,
  signUp,
  setSelectedClass,
  handleOpenModal,
  userClasses,
  handleOpenCancelModal,
  handleOpenEndModal,
  subscribe,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [categories, setCategories] = useState<Array<any>>([]);

  useEffect(() => {
    if (el.categories.length > 3) {
      const newArr = el.categories.slice(0, !isVertical ? 3 : 4);

      setCategories([...newArr, { id: 9999, name: '+', laravel_through_key: 9999 }]);
    } else {
      setCategories(el.categories);
    }
  }, [el]);

  const photoErrHandler = (err: any, index: number) => {
    return (err.target.src = require(`../../../../../assets/images/${previewImg}.jpg`));
  };

  const durationHandler = (duration: string) => {
    const hours = +duration.slice(0, 2);
    const minutes = +duration.slice(3, 5);
    if (hours || minutes) {
      return `${hours ? hours + ' h' : ''}  ${minutes > 0 ? minutes + ' m' : ''}`;
    } else {
      return '';
    }
  };

  const levelLabel = (levels: Array<any>) => {
    if (levels.length) {
      return levels.length === 1 ? `${levels[0].name}` : levels.length === 2 ? 'level+' : 'Any level';
    } else {
      return 'Any level';
    }
  };

  const InstrHasName = el && el.user && el.user.first_name && el.user.last_name;

  const showButton = (el: LiveClassType, title: string) => {
    if (isVertical && (el.status === 'Upcoming' || el.status === 'Running')) {
      return user.type === 'Student' && title === 'Edit' ? false : true;
    }
  };

  const disableButton = (el: LiveClassType) => {
    if (
      ((compareTime(el.gtm_start_datetime, user.timezone) || !el.is_joinable) &&
        (joinButtonTitle === 'Join for the live class' || joinButtonTitle === 'Book and join')) ||
      (!compareTime(el.gtm_start_datetime, user.timezone) && joinButtonTitle === 'Edit') ||
      (el.status === 'Running' && joinButtonTitle === 'Edit')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const buttonHandler = (liveClass: LiveClassType, title: string) => {
    if (title === 'Edit') {
      history.push(`/user/edit-live-class/${liveClass.id}`);
    } else if (title === 'Start live class' || title === 'Enter to class' || title === 'Join for the live class') {
      history.push(`/user/live-classes/${liveClass.id}/stream`);
    } else if (user.type !== 'Instructor') {
      subscribe();
    } else if (user.type === 'Instructor' && Number(liveClass.owner) !== Number(user.id)) {
      dispatch(alertShowThunk('error', 'You should switch to the student account'));
    }
  };

  const cancelEditButtonsChecker = (liveClassId: number) => {
    const classFound = userClasses.find((el) => el.id === liveClassId);

    return classFound ? true : false;
  };

  const cancelClassButton = async (liveClass: LiveClassType) => {
    //check if there is no more than five mins to the start
    if (compareTime(liveClass.gtm_start_datetime, user.timezone)) {
      handleOpenCancelModal();
    }
  };

  const endClassButton = (id: number) => {
    //@ts-ignore
    dispatch(getClass(id)).then(() => handleOpenEndModal());
  };

  const stringHandler = (str: string, num: number, isVertical: boolean) => {
    if (str && str.length > num && !isVertical) {
      return `${str.substr(0, num - 1)}...`;
    } else if (str && str.length > num && isVertical) {
      return `${str.substr(0, num - 1)}...`;
    } else {
      return str;
    }
  };

  const TitleDescription = () => {
    return (
      <div className={styles.titleDesc}>
        <h4>{stringHandler(el.title, isVertical ? (matchesMaxXS ? 27 : 50) : 29, (isVertical = isVertical))}</h4>
        <div className={styles.author}>
          <span>
            {el.user.sm_photo ? (
              <img
                src={`${process.env.REACT_APP_SERVER_HOST}/storage/photo/${el.user.sm_photo}`}
                alt='userImg'
                onError={(err: any) => {
                  return (err.target.src = require('../../../../../assets/svg/liveClasses/user.svg'));
                }}
              />
            ) : (
              <User />
            )}
          </span>
          <Link to={`/user/profile/${el.user.id}`}>
            {InstrHasName ? `${el.user.first_name} ${el.user.last_name}` : 'Some Author'}
            {/*    <FilledStar />
    {el.rating ? el.rating : 0} */}
          </Link>
        </div>
      </div>
    );
  };

  const ButtonsWrapper = () => {
    return (
      <div className={styles.buttonsWrapper}>
        {showButton(el, `${joinButtonTitle}`) &&
          user.id &&
          ((Number(user.id) !== Number(el.owner) && user.type === 'Student') || user.type === 'Instructor') && (
            <Button
              children={`${joinButtonTitle}`}
              customClass={styles.joinButton}
              disabled={disableButton(el)}
              onClick={(e) => {
                e.preventDefault();
                buttonHandler(el, `${joinButtonTitle}`);
              }}
            />
          )}
        {cancelEditButtonsChecker(el.id) &&
          compareTime(el.gtm_start_datetime, user.timezone) &&
          isVertical &&
          el.status === 'Upcoming' && (
            <Button
              className={styles.cancelClassButton}
              variant='contained'
              onClick={(e: any) => {
                e.preventDefault();
                setSelectedClass(el);
                cancelClassButton(el);
              }}
              cancel
              classes={{ disabled: styles.disabledButton }}
              disabled={!compareTime(el.gtm_start_datetime, user.timezone)}
            >
              Cancel live class
            </Button>
          )}
        {isVertical &&
          cancelEditButtonsChecker(el.id) &&
          el.status === 'Running' &&
          Number(el.owner) === Number(user.id) && (
            <Button
              className={styles.cancelClassButton}
              variant='contained'
              cancel
              onClick={(e: any) => {
                e.preventDefault();
                setSelectedClass(el);
                endClassButton(el.id);
              }}
              classes={{ disabled: styles.disabledButton }}
              disabled={false}
            >
              End live class
            </Button>
          )}
      </div>
    );
  };

  const matchesMin = useMediaQuery('(min-width:767px)');
  const matchesMax = useMediaQuery('(max-width:960px)');
  const matchesMaxXS = useMediaQuery('(max-width: 380px)');

  return (
    <GridListTile
      key={el.id}
      classes={{
        root: isVertical && dateDivider ? styles.dateTitle : '',
        tile: `${styles.tileWrapper} ${isVertical ? styles.vertical : ''}`,
      }}
      title={`${dateDivider ? dateDivider : ' '}`}
    >
      <Link to={`/user/live-classes/${el.id}`} title={el.title}>
        <div className={styles.wrapperImg} title=''>
          <div className={styles.imageBlock}>
            <img
              src={
                el.photo
                  ? `${process.env.REACT_APP_SERVER_HOST}/storage/live-class/${el.sm_photo}`
                  : require(`../../../../../assets/images/${previewImg}.jpg`)
              }
              onError={(err) => photoErrHandler(err, index)}
              alt={el.title}
              className={styles.image}
            />

            <StatusFlag customStyles={styles.statusFlag} status={el.status} />
            {durationHandler(el.duration).length > 1 && (
              <div className={`${styles.durationLabel}`}>{el.duration && durationHandler(el.duration)}</div>
            )}
          </div>
          <div className={styles.descHeader}>
            {isVertical && (
              <Hidden mdUp>
                <TitleDescription />
              </Hidden>
            )}
            <div className={styles.classDetails}>
              <div>
                <Calendar />
                <span title={el.title}>
                  {el.start_date &&
                    `${dateHandler(el.gtm_start_datetime, user.timezone, true)} ${timeHandler(
                      el.gtm_start_datetime,
                      user.timezone
                    )}`}
                </span>
              </div>
              <div>
                <Star />
                <span>{el && el.levels && levelLabel(el.levels)}</span>
              </div>
              <div>
                <Dollar />
                <span>{el && el.price && +el.price ? `$ ${+el.price}` : '$ Free'}</span>
              </div>
            </div>

            <Hidden mdUp>
              <ButtonsWrapper />
            </Hidden>
          </div>
        </div>

        <div className={styles.descWrapper} title=''>
          <div className={styles.descTop}>
            {isVertical ? (
              <Hidden smDown>
                <TitleDescription />
              </Hidden>
            ) : (
              <TitleDescription />
            )}

            <Hidden smDown>
              <ButtonsWrapper />
            </Hidden>
          </div>
          <div className={styles.description}>
            <p>{stringHandler(el.description, 400, (isVertical = isVertical))}</p>
          </div>
          <div className={styles.categories}>
            {categories.map((el: any) => {
              return (
                <div key={`${el.id}`} className={styles.singleCategory}>
                  {el.name === '+' ? '' : '#'}
                  {el.name}
                </div>
              );
            })}
          </div>
        </div>
      </Link>
    </GridListTile>
  );
};

export default SingleClass;
