import React, { useEffect, useRef, useState } from 'react';
import { OTPublisher, OTSession, OTStreams } from 'opentok-react';
import CheckBox from './CheckBox';
import Subscriber from './Subscriber';
import { useDispatch, useSelector } from 'react-redux';
import { createStreamSession, saveStream, updateClass } from '../../../../../redux/actions/liveClassesActions';
import { StateType } from '../../../../../redux/store';
import { Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { inspect } from 'util';
import styles from './VideoLesson.module.scss';
import LinearProgress from '@material-ui/core/LinearProgress';
import ModalStream from './ModalStream';
import { ReactComponent as Settings } from '../../../../../assets/svg/settings.svg';
import { ReactComponent as FullScreen } from '../../../../../assets/svg/fullScreen.svg';
import { Link as MaterialLink } from '@material-ui/core';
import StatusFlag from '../../../../common/StatusFlag/StatusFlag';
import SelectedInput, { BootstrapInput } from '../../../../common/SelectedInput/SelectedInput';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';
import { setFullscreen } from '../../../../../redux/reducers/liveClasses';
import { useHistory } from 'react-router-dom';
import { alertShowThunk } from '../../../../../redux/reducers/alert';

const Publisher: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { liveClass, isFullscreen } = useSelector((state: StateType) => state.liveClasses);

  const [accessAllowed, setAccessAllowed] = useState(true);
  const [video, setVideo] = useState(true);
  const [audio, setAudio] = useState(true);
  const [videoSource, setVideoSource] = useState('camera');
  const [openModal, setOpenModal] = useState(false);

  const [audioDevices, setAudioDevices] = React.useState<any>([]);
  const [videoDevices, setVideoDevices] = React.useState<any>([]);
  const [audioDevice, setAudioDevice] = React.useState('');
  const [videoDevice, setVideoDevice] = React.useState('');
  const [micLevel, setMicLevel] = React.useState(0);

  const [publisher, setPublisher] = React.useState<any>(null);
  const [publisherReady, setPublisherReady] = React.useState(false);

  const [canShareScreen, setCanShareScreen] = React.useState(false);

  const OTpublisher = useRef(null);
  const [settingsAnchor, setSettingsAnchor] = React.useState<HTMLButtonElement | null>(null);

  const playerRef: any = useRef();

  useEffect(() => {
    window.onunhandledrejection = (event: any) => {
      event.preventDefault();
    };

    OT.checkScreenSharingCapability(function (response) {
      setCanShareScreen(response.supported);
    });
  }, []);

  useEffect(() => {
    if (OTpublisher.current) {
      // @ts-ignore
      setPublisher(OTpublisher.current.getPublisher());
    }
  });

  useEffect(() => {
    setAudio(!liveClass.is_pending);
  }, [liveClass.is_pending]);

  useEffect(() => {
    if (publisher && publisherReady && liveClass.id && liveClass.audio_device_id) {
      publisher.setAudioSource(liveClass.audio_device_id);
    }
  }, [liveClass.id, publisher, publisherReady]);

  useEffect(() => {
    if (accessAllowed) {
      let audioInputDevices;
      let videoInputDevices;
      const constraints = navigator.mediaDevices.getSupportedConstraints();

      navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((res) => {
        OT.getDevices(function (error, devices) {
          audioInputDevices = devices.filter(function (element) {
            return element.kind == 'audioInput';
          });
          setAudioDevices(audioInputDevices);
          videoInputDevices = devices.filter(function (element) {
            return element.kind == 'videoInput';
          });
          setVideoDevices(videoInputDevices);
        });
      });
    }
  }, [accessAllowed]);

  useEffect(() => {
    //@ts-ignore
    document.fullscreenElement ||
      //@ts-ignore
      (document.webkitFullscreenElement && dispatch(setFullscreen(true)));
  }, [isFullscreen]);

  const handleAudioDeviceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAudioDevice(event.target.value as string);
    publisher.setAudioSource(event.target.value as string);

    dispatch(
      updateClass({
        id: liveClass.id,
        fields: { audio_device_id: event.target.value as string },
      })
    );
  };
  const handleVideoDeviceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVideoDevice(event.target.value as string);
    dispatch(
      updateClass({
        id: liveClass.id,
        fields: { video_device_id: event.target.value as string },
      })
    );
  };

  const changeVideoSource = (videoSourceValue: string) => {
    videoSourceValue ? setVideoSource('screen') : setVideoSource('camera');
  };

  let movingAvg: any = null;
  let resetLevel: any;
  const publisherEventHandlers = {
    accessAllowed: function (event: any) {
      setAccessAllowed(true);
    },
    accessDenied: function accessDeniedHandler(event: any) {
      setVideoSource('camera');
      setAccessAllowed(false);
      // dispatch(alertShowThunk('error', ''));
    },
    streamCreated: (event: any) => {
      console.log('Publisher stream created!');
      dispatch(
        saveStream({
          id: liveClass.id,
          fields: { streamId: event.stream.streamId },
        })
      );
    },
    streamDestroyed: (event: any) => {
      console.log('Publisher stream destroyed!');
    },
    audioLevelUpdated: (event: any) => {
      if (movingAvg === null || movingAvg <= event.audioLevel) {
        movingAvg = event.audioLevel;
      } else {
        movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
      }

      // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
      let logLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
      logLevel = Math.round(Math.min(Math.max(logLevel, 0), 1) * 100);

      if (logLevel > 0) {
        clearTimeout(resetLevel);
        setMicLevel(logLevel);
        resetLevel = setTimeout(() => setMicLevel(0), 500);
      }
    },
  };

  const publisherScreenEventHandlers = {
    mediaStopped: () => {
      setVideoSource('camera');
    },
  };

  const onInit = () => {
    console.log(`VideoLesson: publisher initialized`);
    return false;
  };

  const onPublish = () => {
    console.log(`VideoLesson: publisher published`);
    setPublisherReady(true);
  };

  const onError = (err: any) => {
    console.log(`VideoLesson: Failed to publish: ${err.message}`);
  };

  const handleOpenModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingsAnchor(event.currentTarget);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handlerFullScreen = () => {
    //@ts-ignore
    if (isFullscreen) {
      //@ts-ignore
      document.webkitExitFullscreen && document.webkitExitFullscreen();
      dispatch(setFullscreen(false));
    } else if (!isFullscreen && playerRef !== null && playerRef.current) {
      dispatch(setFullscreen(true));
      !document.fullscreenElement && playerRef.current.requestFullscreen
        ? playerRef.current.requestFullscreen()
        : //@ts-ignore
          playerRef.current.webkitRequestFullscreen();
    } else {
      //@ts-ignore
      if (document.exitFullscreen || document.webkitExitFullscreen) {
        dispatch(setFullscreen(false));
        document.exitFullscreen && document.exitFullscreen();
      }
    }
  };

  return (
    <div className={`${styles.publisherWrapper} ${isFullscreen && styles.fullScreen}`} ref={playerRef}>
      {/*     Publisher */}
      {liveClass.id && (
        <OTPublisher
          // @ts-ignore
          ref={OTpublisher}
          properties={{
            publishAudio: audio,
            publishVideo: videoSource !== 'screen' ? video : false,
            videoSource: liveClass.video_device_id ? liveClass.video_device_id : videoDevice ? videoDevice : undefined,
            audioSource: liveClass.audio_device_id ? liveClass.audio_device_id : audioDevice ? audioDevice : undefined,
            audioFallbackEnabled: false,
            usePreviousDeviceSelection: true,
            showControls: false,
            mirror: false,
            width: '100%',
            height: '100%',
          }}
          eventHandlers={publisherEventHandlers}
          onInit={onInit}
          onPublish={onPublish}
          onError={onError}
        />
      )}
      {liveClass.id && videoSource === 'screen' ? (
        <div className={styles.shareScreenView}>
          <OTPublisher
            properties={{
              videoSource: 'screen',
              showControls: false,
              mirror: false,
              width: '100%',
              height: '100%',
            }}
            // onPublish={}
            // onError={this.onPublishScreenError}
            eventHandlers={publisherScreenEventHandlers}
          />
        </div>
      ) : (
        ''
      )}
      <div className={styles.settingsWrapper}>
        {/*   <StatusFlag status={liveClass.status} /> */}

        <MaterialLink
          className={styles.videoToolButton}
          component='button'
          onClick={(e: any) => handleOpenModal(e)}
          disabled={false}
        >
          <Settings />
        </MaterialLink>
        <MaterialLink
          className={styles.videoToolButton}
          component='button'
          onClick={() => handlerFullScreen()}
          disabled={false}
        >
          <FullScreen />
        </MaterialLink>
      </div>
      <Popover
        open={openModal}
        onClose={handleCloseModal}
        anchorEl={settingsAnchor}
        container={isFullscreen && playerRef ? playerRef.current : document.body}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: styles.settingsPopover }}
      >
        <div className={styles.popoverTitle}>Set up devices</div>
        <div className={styles.turnOnOff}>
          <CheckBox className={styles.onOffMic} type='audio' initialChecked={audio} onChange={setAudio} />
          <CheckBox className={styles.onOffCamera} type='video' initialChecked={video} onChange={setVideo} />

          <CheckBox
            disabled={!canShareScreen}
            className={styles.shareScreenIcon}
            type='share'
            initialChecked={videoSource === 'screen'}
            onChange={changeVideoSource}
          />
        </div>
        <div className={styles.devices}>
          <div className={styles.deviceTitle}>Camera</div>
          {videoDevices.length && (
            <FormControl className={styles.chooseDevice}>
              <Select
                className={styles.deviceSelect}
                labelId='camera-label'
                id='camera-select'
                input={<BootstrapInput />}
                value={liveClass.video_device_id ? liveClass.video_device_id : videoDevices[0].deviceId}
                onChange={handleVideoDeviceChange}
                IconComponent={ExpandMoreIcon}
              >
                {videoDevices.map((el: any) => {
                  return (
                    <MenuItem key={el.deviceId} value={el.deviceId}>
                      {el.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {audioDevices.length && (
            <div>
              <div className={styles.deviceTitle}>Microphone</div>
              <FormControl className={styles.chooseDevice}>
                <Select
                  className={styles.deviceSelect}
                  labelId='microphone-label'
                  id='microphone-select'
                  input={<BootstrapInput />}
                  value={liveClass.audio_device_id ? liveClass.audio_device_id : audioDevices[0].deviceId}
                  onChange={handleAudioDeviceChange}
                  IconComponent={ExpandMoreIcon}
                >
                  {audioDevices.map((el: any) => {
                    return (
                      <MenuItem key={el.deviceId} value={el.deviceId}>
                        {el.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <LinearProgress className={styles.micLevel} classes={{}} variant='determinate' value={micLevel} />
            </div>
          )}
        </div>
      </Popover>
      {/*<ModalStream open={openModal} onCloseModal={handleCloseModal}>
        <div className={styles.turnOnOff}>
          <CheckBox type='audio' initialChecked={audio} onChange={setAudio} />
          <CheckBox type='video' initialChecked={video} onChange={setVideo} />
        </div>
        <div className={styles.devices}>
          <h3>Camera</h3>
          {videoDevices.length && (
            <FormControl className={styles.chooseAudio}>
              <Select
                labelId='camera-label'
                id='camera-select'
                input={<BootstrapInput />}
                value={
                  liveClass.video_device_id
                    ? liveClass.video_device_id
                    : videoDevices[0].deviceId
                }
                onChange={handleVideoDeviceChange}
                IconComponent={ExpandMoreIcon}
              >
                {videoDevices.map((el: any) => {
                  return (
                    <MenuItem key={el.deviceId} value={el.deviceId}>
                      {el.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {audioDevices.length && (
            <div>
              <h3>Microphone</h3>
              <FormControl className={styles.chooseAudio}>
                <Select
                  labelId='microphone-label'
                  id='microphone-select'
                  input={<BootstrapInput />}
                  value={
                    liveClass.audio_device_id
                      ? liveClass.audio_device_id
                      : audioDevices[0].deviceId
                  }
                  onChange={handleAudioDeviceChange}
                  IconComponent={ExpandMoreIcon}
                >
                  {audioDevices.map((el: any) => {
                    return (
                      <MenuItem key={el.deviceId} value={el.deviceId}>
                        {el.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <LinearProgress
                className={styles.micLevel}
                variant='determinate'
                value={micLevel}
              />
            </div>
          )}
        </div>
      </ModalStream>*/}
    </div>
  );
};

export default Publisher;
