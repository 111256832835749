import React, { useEffect, useRef, useState } from 'react';
import { OTSubscriber } from 'opentok-react';
import CheckBox from './CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../redux/store';
import { joinClass, leaveClass } from '../../../../../redux/actions/liveClassesActions';
import { ReactComponent as Settings } from '../../../../../assets/svg/settings.svg';
import { ReactComponent as FullScreen } from '../../../../../assets/svg/fullScreen.svg';
import { Button, Link as MaterialLink } from '@material-ui/core';
import StatusFlag from '../../../../common/StatusFlag/StatusFlag';
import styles from './VideoLesson.module.scss';
import { setFullscreen } from '../../../../../redux/reducers/liveClasses';

const Subscriber: React.FC = (props) => {
  const dispatch = useDispatch();
  const { liveClass, isFullscreen } = useSelector((state: StateType) => state.liveClasses);
  const [video, setVideo] = useState(true);
  const [audio, setAudio] = useState(true);
  const [audioSuspended, setAudioSuspended] = useState(false);

  const otSubscriber = useRef(null);

  const playerRef: any = useRef();

  const audioResume = () => {
    OT.unblockAudio();
  };

  useEffect(() => {
    //@ts-ignore
    document.fullscreenElement ||
      //@ts-ignore
      (document.webkitFullscreenElement && dispatch(setFullscreen(true)));
  }, [isFullscreen]);

  const onError = (err: any) => {
    console.log(`VideoLesson: Failed to subscribe: ${err.message}`);
  };

  const screensHandler = (event: any) => {
    const elements = document.getElementsByClassName('OTSubscriberContainer');
    const audioOnly = document.getElementsByClassName('OT_audio-only');
    const el = event.target.element;
    const targetParentAudio = audioOnly[0]?.parentElement?.parentElement?.parentElement;

    const isThereHiddenClassName = document.getElementsByClassName(`${styles.hideAudioStream}`);

    if (elements.length > 1 && audioOnly[0] && isThereHiddenClassName) {
      targetParentAudio?.classList.add(`${styles.hideAudioStream}`);
    } else if (elements.length === 1) {
      isThereHiddenClassName[0]?.classList?.remove(`${styles.hideAudioStream}`);
    }
  };

  const subscriberEventHandlers = {
    connected: (event: any) => {
      console.log('Subscriber connected!', event.target.stream.videoType);
      screensHandler(event);

      if (event.target.stream.videoType !== 'screen') {
        dispatch(
          joinClass({
            id: liveClass.id,
            fields: { clientId: event.target.session.connection.connectionId },
          })
        );
      }
    },
    destroyed: (event: any) => {
      console.log('Subscriber destroyed!', event.target.stream);
      screensHandler(event);
    },
    disconnected: (event: any) => {
      console.log('Subscriber disconnected!');
    },
    audioBlocked: function (event: any) {
      // setAudioSuspended(true);
    },
    audioUnblocked: function (event: any) {
      // setAudioSuspended(false);
    },
  };

  const handlerFullScreen = () => {
    //@ts-ignore
    if (isFullscreen) {
      //@ts-ignore
      document.webkitExitFullscreen && document.webkitExitFullscreen();
      dispatch(setFullscreen(false));
    } else if (!isFullscreen && playerRef !== null && playerRef.current) {
      dispatch(setFullscreen(true));
      !document.fullscreenElement && playerRef.current.requestFullscreen
        ? playerRef.current.requestFullscreen()
        : //@ts-ignore
          playerRef.current.webkitRequestFullscreen();
    } else {
      //@ts-ignore
      if (document.exitFullscreen || document.webkitExitFullscreen) {
        dispatch(setFullscreen(false));
        document.exitFullscreen && document.exitFullscreen();
      }
    }
  };

  return (
    <>
      {audioSuspended && (
        <div className={styles.audioSuspended}>
          Sound was turned off according to policy reasons. Click{' '}
          <div className={styles.button} onClick={audioResume}>
            HERE
          </div>{' '}
          to resume.
        </div>
      )}
      <div
        className={`
          ${styles.subscriberWrapper} 
          ${isFullscreen ? styles.fullScreen : ''} 
          ${
            // @ts-ignore
            otSubscriber?.current?.state.stream.videoType === 'screen'
              ? `${styles.shareStreamView}`
              : `${styles.videoStreamView}`
          }
        `}
        ref={playerRef}
      >
        {/*     Subscriber */}
        <OTSubscriber
          // @ts-ignore
          ref={otSubscriber}
          properties={{
            subscribeToAudio: audio,
            subscribeToVideo: video,
            showControls: true,
            width: '100%',
            height: '100%',
            style: { buttonDisplayMode: 'off' },
          }}
          eventHandlers={subscriberEventHandlers}
          onError={onError}
        />
        {/*<CheckBox
          // @ts-ignore
          label="Subscribe to Audio"
          initialChecked={audio}
          onChange={setAudio}
        />
        <CheckBox
          // @ts-ignore
          label="Subscribe to Video"
          initialChecked={video}
          onChange={setVideo}
        />*/}
        <div className={styles.settingsWrapper}>
          {/*<MaterialLink
              component='button'
              onClick={() => console.log('settings')}
              disabled={false}
            >
              <Settings />
            </MaterialLink>*/}
          <MaterialLink component='button' onClick={() => handlerFullScreen()} disabled={false}>
            <FullScreen />
          </MaterialLink>
        </div>
      </div>
    </>
  );
};

export default Subscriber;
