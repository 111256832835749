import React, { useState, useEffect, useRef } from 'react';
import styles from './ClassesList.module.scss';
import { GridList } from '@material-ui/core';
import { ReactComponent as Fire } from '../../../../assets/svg/liveClasses/fire.svg';
import { ReactComponent as Clock } from '../../../../assets/svg/liveClasses/clock.svg';
import { ReactComponent as Signal } from '../../../../assets/svg/liveClasses/signal.svg';
import { Link, useHistory } from 'react-router-dom';
import { compareTime, gtmDateCastHandler, classDateDivider } from '../../../../helpers/functions';
import { LiveClassType } from '../../../../redux/reducers/liveClasses';
import { useDispatch, useSelector } from 'react-redux';
import { UserType } from '../../../../redux/reducers/user';
import {
  cancelInstructorClass,
  getUserClasses,
  unsubscribeToClass,
  subscribeToClass,
} from '../../../../redux/actions/liveClassesActions';
import store, { StateType } from '../../../../redux/store';
import ModalWindow from '../LiveClasses/ModalWindow/ModalWindow';
import MainLoader from '../../../common/MainLoader/MainLoader';
import ModalCancelClass from '../LiveClass/ModalCancelClass/ModalCancelClass';
import ModalEndStream from '../Stream/VideoLesson/ModalEndStream/ModalEndStream';
import SingleClass from './SingleClass.tsx/SingleClass';
import { getBalance } from '../../../../redux/actions/userActions';

type ClassesListType = {
  arr: Array<any>;
  totalCount?: number;
  type?: string;
  icon?: string;
  title?: string;
  sectionId?: number;
  isVertical?: boolean;
  authorName?: string;
  isInstructor?: boolean;
  isProfile?: boolean;
};

const ClassesList: React.FC<ClassesListType> = ({
  arr,
  totalCount,
  type,
  icon,
  title,
  sectionId,
  isVertical,
  authorName,
  isInstructor,
  isProfile,
}) => {
  const { user } = useSelector((state: StateType) => state.user);
  const { userClasses } = useSelector((state: StateType) => state.liveClasses);
  const [signUp, setSignUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openEndModal, setOpenEndModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState({} as LiveClassType);
  const [filteredClasses, setFilteredClasses] = useState<Array<LiveClassType>>([] as Array<LiveClassType>);
  const history = useHistory();
  const dispatch = useDispatch();

  const ref: any = useRef();

  useEffect(() => {
    user.id &&
      !isProfile &&
      !history.location.pathname.includes('user/my-live-classes') &&
      dispatch(getUserClasses(user.id));

    return () => {};
  }, []);

  useEffect(() => {
    if (arr) {
      /*const sortedArr = [...arr].sort((a: LiveClassType, b: LiveClassType) => {
        let aDate = gtmDateCastHandler(a.gtm_start_datetime);
        let bDate = gtmDateCastHandler(b.gtm_start_datetime);
        return aDate.getTime() - bDate.getTime();
      });*/
      setFilteredClasses(arr);
    }
  }, [arr]);

  useEffect(() => {
    if (signUp && selectedClass.id && (selectedClass.status === 'Upcoming' || selectedClass.status === 'Running')) {
      selectedClass.status === 'Upcoming'
        ? history.push(`/user/live-classes/${selectedClass.id}`)
        : history.push(`/user/live-classes/${selectedClass.id}/stream`);
    }
  }, [signUp]);

  let currentImg = 1;

  const TitleByType = () => {
    switch (type) {
      case 'recommended':
        return (
          <div className={styles.title}>
            <div>
              <Fire /> <span>Recommended</span> live classes
            </div>
            {arr.length > 4 && (
              <Link
                onClick={() => console.log('See all')}
                to={{
                  pathname: `/user/live-classes`,
                  search: `limit=5&offset=0&?status[]=Upcoming`,
                }}
              >
                See all
              </Link>
            )}
          </div>
        );

      case 'running':
        return (
          <div className={styles.title}>
            <div className={styles.signal}>
              <Signal /> <span>Running</span> live classes
            </div>
            {arr.length > 4 && (
              <Link
                onClick={() => console.log('See all')}
                to={{
                  pathname: `/user/live-classes`,
                  search: `limit=5&offset=0&?status[]=Running`,
                }}
              >
                See all
              </Link>
            )}
          </div>
        );

      case 'upcoming':
        return (
          <div className={styles.title}>
            <div>
              <Clock /> <span>Upcoming</span> live classes
            </div>
            {arr.length > 4 && (
              <Link
                onClick={() => console.log('See all')}
                to={{
                  pathname: `/user/live-classes`,
                  search: `?limit=5&offset=0&status[]=Upcoming`,
                  state: {
                    arr: arr,
                  },
                }}
              >
                See all
              </Link>
            )}
          </div>
        );

      case 'instructor':
        return (
          <div className={styles.title}>
            <div className={styles.signal}>
              <Signal /> <span>Other live classes</span> from {authorName}
            </div>
            {arr.length > 4 && (
              <Link
                onClick={() => console.log('See all')}
                to={{
                  pathname: `/user/live-classes`,
                  search: `?limit=5&offset=0&type[]=Instructor`,
                  state: {
                    type: 'instructor',
                    authorName: authorName,
                    arr: arr,
                  },
                }}
              >
                See all
              </Link>
            )}
          </div>
        );
      case 'recurring':
        return (
          <div className={styles.title}>
            <div className={styles.signal}>
              <Signal /> <span>This is recurring live class. </span> Sign up for next classes:
            </div>
            {arr.length > 4 && (
              <Link
                onClick={() => console.log('See all')}
                to={{
                  pathname: `/user/live-classes`,
                  search: `?limit=5&offset=0&type[]=recurring`,
                }}
              >
                See all
              </Link>
            )}
          </div>
        );
      case 'category':
        return (
          <div className={styles.title}>
            <div>
              <img
                src={`${process.env.REACT_APP_SERVER_HOST}/storage/category/${icon}`}
                alt={title}
                className={styles.categoryIcon}
                onError={(err: any) => {
                  return (err.target.src = require('../../../../assets/images/whiteSquare.png'));
                }}
              />{' '}
              <span>{title}</span> live classes
            </div>
            {totalCount && totalCount > 4 && (
              <Link
                onClick={() => console.log('See all')}
                to={{
                  pathname: `/user/live-classes`,
                  search: `?limit=5&offset=0&status[]=Upcoming&categories[]=${sectionId}`,
                  state: {
                    arr: arr,
                  },
                }}
              >
                See all
              </Link>
            )}
          </div>
        );

      default:
        return (
          <div className={styles.title}>
            <div>
              <Fire /> <span>Re</span> live classes
            </div>
            {arr.length > 4 && (
              <Link
                onClick={() => console.log('See all')}
                to={{
                  pathname: `/user/live-classes`,
                  search: `?limit=5&offset=0&status[]=Recommended`,
                }}
              >
                See all
              </Link>
            )}
          </div>
        );
        break;
    }
  };

  const imgNumber = (index: number) => {
    if ((index + 1) % currentImg === 0 && currentImg < 3) {
      currentImg++;
      return currentImg;
    } else {
      currentImg = 1;
      return 1;
    }
  };

  const joinButtonTitle = (liveClass: LiveClassType, user: UserType) => {
    const classFound = userClasses.find((el) => el.id === liveClass.id);

    if (user && user.type === 'Instructor' && Number(liveClass.owner) === Number(user.id)) {
      return compareTime(liveClass.gtm_start_datetime, user.timezone)
        ? 'Edit'
        : liveClass.status === 'Running'
        ? 'Enter to class'
        : 'Start live class';
    } else if ((liveClass.status === 'Upcoming' || liveClass.status === 'Running') && !classFound) {
      return liveClass.status === 'Running' ? 'Book and join' : 'Sign up for the class';
    } else if (
      (liveClass.status === 'Upcoming' || liveClass.status === 'Running') &&
      classFound &&
      Number(liveClass.owner) !== Number(user.id)
    ) {
      return 'Join for the live class';
    }
  };

  const subscribe = async (liveClass: LiveClassType) => {
    if (liveClass && liveClass.price === 0) {
      await dispatch(subscribeToClass(liveClass.id));
      await dispatch(getUserClasses(null));
      await setSignUp(true);
      (await liveClass.status) === 'Upcoming'
        ? history.push(`/user/live-classes/${liveClass.id}`)
        : history.push(`/user/live-classes/${liveClass.id}/stream`);
    } else if (liveClass && liveClass.price > 0) {
      /*       await dispatch(subscribeToClass(liveClass.id));
      await dispatch(getUserClasses(null)); */
      setSelectedClass(liveClass);
      !signUp && handleOpenModal();
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenCancelModal = () => {
    setOpenCancelModal(true);
  };

  const handleOpenEndModal = () => {
    setOpenEndModal(true);
  };

  const cancelInstClass = async (liveClassId: number, recurrent?: boolean) => {
    await dispatch(
      cancelInstructorClass({
        id: liveClassId,
        update_all: recurrent,
        status: 'Canceled',
      })
    );

    await setOpenCancelModal(false);
  };

  const cancelStudentClass = async (liveClassId: number) => {
    //@ts-ignore
    await dispatch(unsubscribeToClass(liveClassId)).then(() => dispatch(getUserClasses(null)));
    await dispatch(getBalance());
    await setOpenCancelModal(false);
  };

  return (
    <section className={`${styles.wrapper} ${isVertical && styles.wrapperVertical}`}>
      <MainLoader loading={loading} />
      <ModalWindow
        signUpFunc={(e: boolean) => setSignUp(e)}
        selectedClass={selectedClass}
        open={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
      />
      {selectedClass && (
        <ModalCancelClass
          open={openCancelModal}
          recurrent={selectedClass.schedule_id && user.type === 'Instructor' ? true : false}
          onCloseModal={() => setOpenCancelModal(false)}
          cancelCurrClass={() =>
            user.type === 'Instructor' ? cancelInstClass(selectedClass.id) : cancelStudentClass(selectedClass.id)
          }
          cancelAllClasses={() =>
            selectedClass.schedule_id && user.type === 'Instructor' && cancelInstClass(selectedClass.id, true)
          }
        />
      )}
      {selectedClass && <ModalEndStream open={openEndModal} onCloseModal={() => setOpenEndModal(false)} />}
      {!isVertical && <TitleByType />}
      {arr && arr.length > 0 ? (
        <GridList className={styles.gridList} cols={isVertical ? 1 : 5} ref={ref}>
          {filteredClasses?.map((el: LiveClassType, index: number) => {
            return (
              <SingleClass
                key={el.id}
                el={el}
                isVertical={isVertical ? true : false}
                index={index}
                dateDivider={classDateDivider(el.gtm_start_datetime, index, filteredClasses, user?.timezone)}
                previewImg={imgNumber(index)}
                user={user}
                joinButtonTitle={joinButtonTitle(el, user) ? `${joinButtonTitle(el, user)}` : ''}
                setSignUp={() => setSignUp(true)}
                signUp={signUp}
                setSelectedClass={() => setSelectedClass(el)}
                handleOpenModal={handleOpenModal}
                userClasses={userClasses}
                handleOpenCancelModal={handleOpenCancelModal}
                handleOpenEndModal={handleOpenEndModal}
                subscribe={() => subscribe(el)}
              />
            );
          })}
        </GridList>
      ) : (
        <div className={styles.emptyClasses}>
          <img src={require('../../../../assets/images/camera.png')} alt={`${type}`} />
          <h2>Oh no! No classes available</h2>
        </div>
      )}
    </section>
  );
};

export default ClassesList;
