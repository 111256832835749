import React, { useRef, useState, useEffect } from 'react';
import styles from '../LiveClass.module.scss';
import { ReactComponent as Share } from '../../../../../assets/svg/share.svg';
import { ReactComponent as Twitter } from '../../../../../assets/svg/share/twitter.svg';
import { ReactComponent as Facebook } from '../../../../../assets/svg/share/facebook.svg';
import { ReactComponent as Copy } from '../../../../../assets/svg/share/copy.svg';
import { ReactComponent as Clock } from '../../../../../assets/svg/liveClasses/clock.svg';
import { ReactComponent as Signal } from '../../../../../assets/svg/liveClasses/signal.svg';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { MenuItem, Menu, Box, Hidden } from '@material-ui/core';
import Button from '@material-ui/core/Button';

let icons: any = {
  twitter: (
    <TwitterShareButton url={`${window.location.href}`}>
      <Twitter /> Share on Twitter
    </TwitterShareButton>
  ),
  facebook: (
    <FacebookShareButton url={`${window.location.href}`}>
      <Facebook /> Share on Facebook
    </FacebookShareButton>
  ),
  copy: (
    <div onClick={() => navigator.clipboard.writeText(`${window.location.href}`)}>
      <Copy /> Copy link
    </div>
  ),
};

export const LiveClassHeader = ({ title, shareArr }: any) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <h1>{title}</h1>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={(e: any) => {
          anchorRef && handleToggle(e);
        }}
        className={styles.shareButton}
        variant='contained'
      >
        Share <Share />
      </Button>
      <Menu
        id='fade-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        classes={{ paper: styles.shareMenu }}
      >
        {shareArr &&
          shareArr.map((el: any) => {
            return (
              <MenuItem
                key={`${el.id}`}
                classes={{
                  root: `${styles.shareMenuItem} `,
                }}
                onClick={handleClose}
              >
                <div className={styles.linkTitle}>{icons[`${el.icon}`]}</div>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};
