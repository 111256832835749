import React from 'react';
import { Button as MainButton, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';

import styles from './Button.module.scss';

type PropsType = {
  active?: boolean;
  customSize?: string;
  type?: 'button' | 'submit' | 'reset';
  customClass?: any;
  iconLeft?: any;
  iconRight?: any;
  cancel?: boolean;
  customWrapper?: any;
};

const Button: React.FC<ButtonProps & PropsType> = ({
  variant,
  color,
  children,
  disabled,
  onClick,
  active,
  customSize,
  type = 'button',
  customClass,
  iconLeft,
  iconRight,
  cancel,
  customWrapper,
}) => (
  <div className={`${styles.wrapper} ${customWrapper ? customWrapper : ''}`}>
    <MainButton
      variant={variant}
      color={color}
      className={clsx(
        {
          [styles.active]: active,
          [styles.small]: customSize === 'small',
          [styles.big]: customSize === 'big',
        },
        color && styles[color]
      )}
      classes={{
        root: `${customClass ? customClass : styles.rootButton} ${cancel && styles.cancelButton}`,
        disabled: styles.disabled,
      }}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {iconLeft} {children} {iconRight}
    </MainButton>
  </div>
);

export default Button;
