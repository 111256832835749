import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form, submit } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';

import Input from '../../../common/Input/Input';
import Button from '../../../common/Button/Button';
import { TextField } from '@material-ui/core';

import { signIn } from '../../../../redux/actions/authActions';
import { StateType } from '../../../../redux/store';
import { required, email, password } from '../../../../helpers/validate';

import styles from './Wallet.module.scss';

import { ReactComponent as Download } from '../../../../assets/svg/download.svg';
import SelectedInput from '../../../common/SelectedInput/SelectedInput';
import { Link as MaterialLink } from '@material-ui/core';
import { setNewClass } from '../../../../redux/reducers/liveClasses';
import EmailForm from './EmailForm/EmailForm';
import { alertShowThunk } from '../../../../redux/reducers/alert';
import {
  getBalance,
  deleteStudentCard,
} from '../../../../redux/actions/userActions';
import { InputAdornment } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReplenishForm from './ReplenishForm/ReplenishForm';
import NoStripe from './NoStripe/NoStripe';
import { clearStripeObj } from '../../../../redux/reducers/user';
import Modal from '../../../components/Modal/Modal';
import WithdrawnForm from './WithdrawnForm/WithdrawnForm';
import { applyCode } from '../../../../redux/actions/promoActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import {ReactComponent as Coins} from "../../../../assets/svg/Coins.svg";

const Wallet: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const { user, balance, stripe } = useSelector(
    (state: StateType) => state.user
  );

  const { withdrawnForm }: any = useSelector((state: StateType) => state.form);
  const { promo }: any = useSelector((state: StateType) => state);
  const [disable, setDisable] = useState(true);
  const [disableDelete, setDisableDelete] = useState(false);
  const [showDeleteButton, setDeleteButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [withdrawnValue, setWithdrawnValue] = useState(0);
  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [promoCodeSubmitting, setPromoCodeSubmitting] = useState(false);

  useEffect(() => {
    setDisable(true);
    if (withdrawnForm && withdrawnForm.values && withdrawnForm.values.balance) {
      setDisable(true);
      !withdrawnForm.submitSucceeded &&
      withdrawnForm.values.balance >= 20 &&
      balance >= 20 &&
      balance >= withdrawnForm.values.balance
        ? setDisable(false)
        : setDisable(true);
      withdrawnForm.values.balance >= 20
        ? setWithdrawnValue(withdrawnForm.values.balance)
        : setWithdrawnValue(0);
    }
    if (withdrawnForm && withdrawnForm.triggerSubmit) {
    } else if (withdrawnForm && withdrawnForm.submitSucceeded) {
      setTimeout(() => setOpenModal(false), 3000);
    }
  }, [withdrawnForm]);

  useEffect(() => {
    dispatch(getBalance());
    setOpenModal(false);
  }, [balance]);

  // useEffect below checks the incoming data after click on 'Connect stripe'
  useEffect(() => {
    if (stripe && stripe.url) {
      linkHandler();
    }
  }, [stripe]);

  const linkHandler = async () => {
    await window.open(stripe.url);
    await dispatch(clearStripeObj());
  };

  const showAlert = (type: 'success' | 'error', amount?: number) => {
    if (type === 'success') {
      dispatch(
        alertShowThunk(type, `Good work! Top up your account +$${amount}`)
      );
    } else {
      dispatch(
        alertShowThunk(type, 'You should switch to the student account')
      );
    }
  };

  const handlePromoChange = (event: any) => {
    setPromoCodeValue(event.target.value);
  };

  const applyPromoCode = async () => {
    setPromoCodeSubmitting(true);
    await dispatch(applyCode({ code: promoCodeValue }));
    setPromoCodeSubmitting(false);
    await dispatch(getBalance());
  };

  return (
    <div className={styles.wrapper}>
      <Modal
        open={openModal}
        onCloseModal={() => setOpenModal(false)}
        customClass={styles.modal}
      >
        <div className={styles.modalWrapper}>
          <div className={styles.info}>
            <div className={styles.imgWrapper}>
              <Coins className={styles.coins} />
            </div>
            <h2>Withdraw money to your stripe account</h2>

            <div className={styles.classPrice}>
              <p>Your total balance is:</p>
              <span />
              <p>${balance}</p>
            </div>

            <div className={styles.balanceAmount}>
              <p>Your balance is:</p>
              <span /> <p>${balance - withdrawnValue}</p>
            </div>

            <div className={styles.totalAmount}>
              <p>Total:</p>
              <span /> <p>${withdrawnValue}</p>
            </div>
          </div>

          <div className={styles.helper}>
            {withdrawnValue < 20 && <span>Minimum amount is 20 dollars</span>}
            {withdrawnValue >= 20 &&
              (balance < 20 || balance < withdrawnValue) && (
                <span>You have insufficient balance</span>
              )}
          </div>

          <WithdrawnForm />
          <Button
            onClick={() => balance >= 20 && dispatch(submit('withdrawnForm'))}
            color='primary'
            variant='contained'
            customClass={styles.balanceButton}
            disabled={disable}
          >
            Request a payout
          </Button>
        </div>
      </Modal>
      <h1>My Wallet</h1>
      <div className={styles.internalWrapper}>
        <div
          className={`${styles.wallet} ${
            user.type === 'Instructor' ? styles.walletInstructor : ''
          }`}
        >
          <div className={styles.balance}>
            {!showDeleteButton && (
              <div className={styles.info}>
                <h2>Available balance</h2>
                {user.type === 'Instructor' && (
                  <p>You should get all money 3 days after a live class</p>
                )}
                <p>{true ? `$${balance}` : '$0'}</p>
              </div>
            )}
            {user.stripe_customer && user.type === 'Student' && (
              <>
                {showDeleteButton ? (
                  <div className={styles.delete}>
                    <h2>Are you sure you want to delete the card?</h2>
                    <div className={styles.deleteButtons}>
                      <Button
                        onClick={() => {
                          setDisableDelete(true);
                          //@ts-ignore
                          dispatch(deleteStudentCard(null)).then(() => {
                            setDisableDelete(false);
                            setDeleteButton(false);
                          });
                        }}
                        cancel
                        disabled={disableDelete}
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() => {
                          setDeleteButton(false);
                        }}
                        color='primary'
                        variant='contained'
                        customClass={styles.balanceButton}
                        disabled={disableDelete}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className={styles.card}>
                    <div className={styles.cardInfo}>
                      Your card is: **** **** **** {user.stripe_customer.last4}
                    </div>
                    <IconButton
                      className={styles.showDeleteButton}
                      onClick={() => {
                        setDeleteButton(true);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
              </>
            )}
            {!showDeleteButton && (
              <div className={styles.buttons}>
                {user.type === 'Instructor' ? (
                  <Button
                    onClick={() => {
                      /* showAlert('success', 30) */
                      setOpenModal(true);
                    }}
                    color='primary'
                    variant='contained'
                    customClass={styles.balanceButton}
                    disabled={!user.stripe_account?.login_link}
                  >
                    Request a payout
                  </Button>
                ) : (
                  <ReplenishForm />
                )}
              </div>
            )}
          </div>
          {user.type === 'Student' && (
            <div className={styles.promo}>
              <TextField
                name='promoCode'
                placeholder='Enter Coupon'
                label=''
                type='text'
                value={promoCodeValue}
                onChange={handlePromoChange}
                className={styles.code}
              />
              <div className={styles.applyCode}>
                <Button
                  children={'Apply'}
                  color='primary'
                  variant='contained'
                  onClick={() => applyPromoCode()}
                  disabled={!promoCodeValue || promoCodeSubmitting}
                  // customClass={styles.applyCode}
                />
              </div>
            </div>
          )}
        </div>
        {user.type === 'Instructor' && (
          <div className={styles.history}>
            {user.stripe_account_id && user.stripe_account ? (
              <EmailForm />
            ) : (
              <NoStripe refresh={Boolean(user.stripe_account_id)} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Wallet;
