import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { StateType } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { alertCloseThunk } from '../../../redux/reducers/alert';
import styles from './Alert.module.scss';

type AlertType = {
  onClose: () => void;
};

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const CustomizedSnackbars: React.FC<AlertType> = (props: any) => {
  const { open, message, type, length } = useSelector((state: StateType) => state.alert);
  const dispatch = useDispatch();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(alertCloseThunk());
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={length === 0 ? null : length}
        onClose={handleClose}
        classes={{ root: styles.wrapper }}
      >
        <Alert
          classes={{
            root: styles.root,
            icon: styles.icon,
            filledError: styles.error,
            filledWarning: styles.warning,
          }}
          onClose={(e) => {
            handleClose(e);
            props?.onClose();
          }}
          severity={type as AlertProps['severity']}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomizedSnackbars;
