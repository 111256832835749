import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Content from '../Content';
import Footer from '../../components/Footer/Footer';
import { StateType } from '../../../redux/store';
import styles from './AuthLayout.module.scss';

type Props = {
  children?: React.ReactNode;
  type?: string;
};

const AuthLayout: React.FC<Props> = ({ children, type }) => {
  return (
    <div className={`${styles.authLayout} ${type && styles[type]}`}>
      <Header isAuthLayout />
      <Content>{children}</Content>
      <Footer />
    </div>
  );
};

export default AuthLayout;
