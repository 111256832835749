import React, { useEffect, useState } from 'react';
import styles from './Profile.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { StateType } from '../../../../redux/store';
import { useParams, useHistory, Link } from 'react-router-dom';
import { getSpecificUser } from '../../../../redux/actions/userActions';
import { UserType, clearSpecificUser } from '../../../../redux/reducers/user';
import { ReactComponent as User } from '../../../../assets/svg/liveClasses/user.svg';
import { ReactComponent as Clock } from '../../../../assets/svg/clock.svg';
import { ReactComponent as Pencil } from '../../../../assets/svg/pencil.svg';
import { ReactComponent as Copy } from '../../../../assets/svg/link-broken.svg';
import Button from '../../../common/Button/Button';
import { IconButton, Modal, Tab, Tabs, Slider } from '@material-ui/core';
import { TabPanel } from '../Stream/VideoLesson/VideoLesson';
import {
  getCategories,
  getInstrClasses,
  getLevels,
  getUserClasses,
} from '../../../../redux/actions/liveClassesActions';
import ClassesList from '../ClassesList/ClassesList';
import { LiveClassType, clearInstrClasses, updateInstrClasses } from '../../../../redux/reducers/liveClasses';
import { Link as MaterialLink } from '@material-ui/core';
import { getCodes } from '../../../../redux/actions/promoActions';
import { alertShowThunk } from '../../../../redux/reducers/alert';
import moment from 'moment';
import Loader from '../../../common/Loader/Loader';
import MainLoader from '../../../common/MainLoader/MainLoader';
import { photoUserErrHandler } from '../../../../helpers/functions';
import { Echo } from '../../../../index';
import ImageWithFallbacks from '../../../components/ImageWithFallbacks/ImageWithFallbacks';

const Profile: React.FC = () => {
  const { user, specificUser } = useSelector((state: StateType) => state.user);
  const { instrClasses } = useSelector((state: StateType) => state.liveClasses);
  const { codes } = useSelector((state: StateType) => state.promo);
  const [userInfo, setUserInfo] = useState<UserType>({} as UserType);

  const [classes, setClasses] = useState<Array<LiveClassType>>([] as Array<LiveClassType>);
  const [tabsValue, setTabsValue] = useState(0);
  const [showPromocode, setShowPromocode] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [loadingClasses, setLoadingClasses] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const params: { id: string } = useParams();
  const id = +params.id;

  useEffect(() => {
    dispatch(clearInstrClasses());
    user.id && dispatch(getUserClasses(user.id));
    Echo.channel('LiveClasses').listen('LiveClassUpdated', (e: any) => {
      dispatch(updateInstrClasses(e.liveClass));
    });
    return () => {
      Echo.channel('LiveClasses').stopListening('LiveClassUpdated');
      dispatch(clearSpecificUser());
      dispatch(clearInstrClasses());
    };
  }, []);

  useEffect(() => {
    id && getUserProfile(id);
  }, [params.id]);

  useEffect(() => {
    if (id !== user.id && !instrClasses.length) {
      //@ts-ignore
      dispatch(getInstrClasses({ id, status: ['Upcoming'] })).then((res) => {});
    }
    if (specificUser) {
      setUserInfo(specificUser);
      setShowPromocode(true);
    }
  }, [user, specificUser]);

  useEffect(() => {
    specificUser && specificUser.status === 'Blocked' && history.push('/page-not-found');
  }, [specificUser]);

  useEffect(() => {
    if (user.type === 'Instructor') {
      dispatch(getCodes(id));
    }
  }, [user.type]);

  const getUserProfile = (id: number) => {
    id &&
      //@ts-ignore
      dispatch(getSpecificUser(id)).then(({ payload }) => {
        !payload?.success && history.push('/page-not-found');
      });
  };

  const getInstrClassesStatus = async (status: string, lastWeek?: boolean) => {
    const endDate = lastWeek ? moment().format('YYYY-MM-DD') : moment().add(7, 'days').format('YYYY-MM-DD');
    const startDate = lastWeek ? moment().subtract(7, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    dispatch(getInstrClasses({ id, status: [status], endDate, startDate }));
  };

  const copyPromoCode = (code: string) => {
    navigator.clipboard.writeText(code).then(
      function () {
        dispatch(alertShowThunk('success', 'Promo code copied to clipboard'));
      },
      function (err) {
        dispatch(alertShowThunk('error', 'Could not copy text'));
      }
    );
  };

  const ClassesTab = () => {
    return (
      <div>
        {loadingClasses ? (
          <MainLoader loading local />
        ) : (
          <ClassesList isProfile arr={instrClasses} type={'upcoming'} isVertical={true} />
        )}
      </div>
    );
  };

  const TabStep = () => {
    switch (tabsValue) {
      case 0:
        return <ClassesTab />;
      case 1:
        return <ClassesTab />;

      default:
        return <div></div>;
    }
  };

  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabsValue(newValue);
  };

  const showUserType = (type: string, owner: boolean) => {
    return type && owner ? type : 'Instructor';
  };

  return (
    <>
      {userInfo?.id && (
        <div className={styles.wrapper}>
          <div className={styles.photo}>
            <div className={styles.imgWrapper}>
              {userInfo.md_photo ? (
                <ImageWithFallbacks
                  src={`${process.env.REACT_APP_SERVER_HOST}/storage/photo/${userInfo.md_photo}`}
                  alt='userImg'
                  fallbacks={`${photoUserErrHandler(userInfo.photo)}`}
                />
              ) : (
                <User />
              )}
            </div>
            {id === user.id && (
              <Button
                children={`Edit`}
                customClass={styles.editButton}
                onClick={() => history.push(`/user/settings`)}
                iconLeft={<Pencil />}
                customWrapper={styles.buttonWrapper}
              />
            )}
            {user.type === 'Instructor' && codes.length > 0 && (
              <div className={styles.promoCodes}>
                {codes.map((el) => {
                  return (
                    <div key={el.id} className={styles.promoCode}>
                      <div className={styles.title}>Coupon:</div>
                      <div className={styles.code}>{el.code}</div>
                      <div className={styles.icon} title={'Copy'} onClick={() => copyPromoCode(el.code)}>
                        <Copy />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className={styles.desc}>
            <h1>{`${userInfo.first_name} ${userInfo.last_name}`}</h1>
            <p>{showUserType(userInfo.type, user.id === userInfo.id)}</p>
            <div>
              <p className={styles.description}>{userInfo.about_me}</p>
            </div>
            {+id !== +user?.id && (
              <div className={styles.tabsWrapper}>
                <Tabs
                  value={tabsValue}
                  onChange={handleChangeTabs}
                  variant='fullWidth'
                  indicatorColor='primary'
                  textColor='primary'
                  aria-label='icon tabs example'
                  classes={{ root: styles.tabsRoot }}
                  TabIndicatorProps={{ className: styles.indicatorRoot }}
                >
                  <Tab
                    classes={{
                      root: styles.tabRoot,
                      selected: styles.selectedTab,
                    }}
                    icon={<Clock />}
                    aria-label='upcoming'
                    id={`scrollable-auto-tab-${0}`}
                    aria-controls={`scrollable-auto-tabpanel-${0}`}
                    disableRipple
                    label='Upcoming sessions'
                    onClick={() => {
                      setLoadingClasses(true);
                      getInstrClassesStatus('Upcoming').then((res) => setTimeout(() => setLoadingClasses(false), 1000));
                    }}
                  />
                  <Tab
                    classes={{
                      root: `${styles.tabRoot}`,
                      selected: styles.selectedTab,
                    }}
                    // icon={<Password />}
                    aria-label='past'
                    id={`scrollable-auto-tab-${1}`}
                    aria-controls={`scrollable-auto-tabpanel-${2}`}
                    disableRipple
                    label='Past sessions'
                    onClick={() => {
                      setLoadingClasses(true);
                      getInstrClassesStatus('Passed', true).then((res) =>
                        setTimeout(() => setLoadingClasses(false), 1000)
                      );
                    }}
                  />
                </Tabs>
                <div className={styles.tabWrapper}>
                  <TabPanel value={tabsValue} index={tabsValue}>
                    <TabStep />
                  </TabPanel>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
