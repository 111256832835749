import React, { useEffect, useState } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import styles from './UserButton.module.scss';
import { photoUserErrHandler } from '../../../helpers/functions';
import ImageWithFallbacks from '../ImageWithFallbacks/ImageWithFallbacks';
import { UserType } from '../../../redux/reducers/user';

type UserButtonType = {
  user: UserType;
};

export const UserButton: React.FC<UserButtonType> = ({ user }) => {
  return (
    <div className={styles.userWrapper}>
      <div className={styles.userImg}>
        {user.sm_photo ? (
          <ImageWithFallbacks
            src={`${process.env.REACT_APP_SERVER_HOST}/storage/photo/${user.sm_photo}`}
            alt='user_img'
            fallbacks={`${photoUserErrHandler(user.photo)}`}
          />
        ) : (
          <PersonIcon />
        )}
      </div>
      <div className={styles.userInfo}>
        {user && user.type && (
          <>
            <h4>{`${user.first_name} ${user.last_name}`}</h4>
            <p>{user.type}</p>
          </>
        )}
      </div>
    </div>
  );
};
