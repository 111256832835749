import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../../../common/Button/Button';
import styles from './ModalEndStream.module.scss';
import Modal from '@material-ui/core/Modal';
import { StateType } from '../../../../../../redux/store';
import { updateClass, cancelInstructorClass } from '../../../../../../redux/actions/liveClassesActions';
import { useHistory } from 'react-router-dom';

const ModalEndStream: React.FC<any> = ({ open, onCloseModal }) => {
  const { balance, user } = useSelector((state: StateType) => state.user);
  const { replenishForm } = useSelector((state: StateType) => state.form);
  const { liveClass } = useSelector((state: StateType) => state.liveClasses);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (balance) {
    }
    return () => {};
  }, [open]);

  const checkPublisher = () => {
    return (
      user &&
      user.type === 'Instructor' &&
      liveClass.owner === user.id &&
      (liveClass.status === 'Upcoming' || liveClass.status === 'Running')
    );
  };

  const checkSubscriber = () => {
    let currentUser = null;
    if (user && user.type === 'Student' && liveClass.class_users_info) {
      currentUser = liveClass.class_users_info.find((el: any) => {
        return el.user_id === user.id && !Boolean(el.is_blocked);
      });
    }
    return currentUser;
  };

  const endLiveClass = (action: string) => {
    switch (user.type) {
      case 'Instructor':
        switch (action) {
          case 'leave':
            if (liveClass.status === 'Running') {
              dispatch(updateClass({ id: liveClass.id, fields: { is_pending: true } }));
              onCloseModal();
            } else {
              history.push(`/user/live-classes/${liveClass.id}`);
              onCloseModal();
            }
            break;
          case 'end':
            dispatch(updateClass({ id: liveClass.id, fields: { status: 'Passed' } }));
            onCloseModal();
            break;
          case 'cancel':
            dispatch(cancelInstructorClass({ id: liveClass.id, status: 'Canceled' }));
            onCloseModal();
            break;
        }
        break;
      case 'Student':
        history.push(`/user/live-classes/${liveClass.id}`);
        break;
    }
  };

  const handleCloseModal = () => {
    onCloseModal();
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className={styles.modalRoot}>
      <div className={styles.modalStream}>
        <div className={styles.modalLeave}>
          <img src={require('../../../../../../assets/svg/stop.svg')} alt='stop' />
          <h3>{user.type === 'Instructor' ? 'End' : 'Leave'} live class</h3>
          {checkPublisher() ? (
            <p>
              Are you sure you want to end live class? <br /> All students will be kicked out of the room and you will
              not be able to re-start it.
            </p>
          ) : (
            <p>Are you sure you want to leave live class?</p>
          )}

          <div className={`${styles.buttonsLeave} ${checkPublisher() ? '' : styles.streamEnded}`}>
            {((user.type === 'Instructor' && !liveClass.is_pending) || user.type === 'Student') && (
              <Button
                color='default'
                variant='outlined'
                cancel
                onClick={() => {
                  endLiveClass('leave');
                }}
              >
                Leave live class
              </Button>
            )}
            {checkPublisher() && (
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  endLiveClass('end');
                }}
              >
                End live class
              </Button>
            )}
            {checkPublisher() && (
              <Button
                color='secondary'
                variant='contained'
                onClick={() => {
                  endLiveClass('cancel');
                }}
              >
                Cancel live class
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEndStream;
