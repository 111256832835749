import {
  connectWithStripe,
  getNotifications,
  setNotifications,
  getListOfUsers,
  getSpecificUser,
  updateProfile,
  refreshStripeAccount,
  deleteStudentCard,
} from './../actions/userActions';
import { LiveClassType } from './liveClasses';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { signOut, getProfile, replenishBalance, getBalance } from '../actions/userActions';
import { switchType } from '../actions/authActions';

export type ProfileType = {
  id: number;
  email: string;
  role: string;
  is_multiroled: boolean;
};

type LastType = 'Instructor' | 'Student';
type StatusType = 'Active' | 'Blocked' | 'Inactive';
type UserTypesType = {
  id: number;
  type: LastType;
  user_id: number;
};

type StripeAccountType = {
  errors: Array<unknown>;
  login_link: string;
};
type StripeCustomerType = {
  address_city: null | unknown;
  address_country: null | unknown;
  address_line1: null | unknown;
  address_line1_check: null | unknown;
  address_line2: null | unknown;
  address_state: null | unknown;
  address_zip: null | string;
  address_zip_check: null | string;
  brand: null | string;
  country: null | string;
  customer: null | string;
  cvc_check: null | string;
  dynamic_last4: null | string;
  exp_month: null | number;
  exp_year: null | number;
  fingerprint: null | string;
  funding: null | string;
  id: null | string;
  last4: null | string;
  metadata: Array<any> | unknown;
  name: null | string;
  object: null | string;
  tokenization_method: null | unknown;
};

export type UserType = {
  about_me: null | string;
  card_brand: null | unknown;
  card_last_four: null | unknown;
  created_at: string;
  direct_message: string;
  email: string;
  first_name: string;
  google_id: null | unknown;
  id: number;
  is_agree_terms: number;
  last_name: string;
  last_type: LastType;
  live_classes: Array<LiveClassType>;
  phone_number: null | unknown;
  photo: string;
  md_photo: string;
  sm_photo: string;
  rating: number;
  status: StatusType;
  stripe_id: null | string;
  stripe_account_id: null | unknown;
  stripe_account_link: null | unknown;
  stripe_account: null | StripeAccountType;
  stripe_customer: null | StripeCustomerType;
  timezone: null | string;
  trial_ends_at: null | unknown;
  twitter_id: null | unknown;
  type: LastType;
  types: Array<UserTypesType>;
};

type StripeDataType = {
  created: number;
  expires_at: number;
  object: string;
  url: string;
};

type UserStateType = {
  profile: null | ProfileType;
  isLoading: boolean;
  errors: any;
  user: UserType;
  balance: number;
  balanceReplenished: boolean;
  stripe: StripeDataType;
  notifications: NotificationsType;
  settingsStep: number;
  users: Array<UserType>;
  specificUser: UserType;
};

type NotificationsType = {
  canceled_by_instructor: number | string;
  class_changed: number | string;
  class_reminders: number | string;
  day_before: number | string;
  hour_before: number | string;
  id: number | string;
  new_class: number | string;
  user_id: number | string;
  withdrawal: number | string;
};

const initialState: UserStateType = {
  profile: null,
  isLoading: false,
  errors: null,
  user: {} as UserType,
  balance: 0,
  balanceReplenished: false,
  stripe: {} as StripeDataType,
  notifications: {} as NotificationsType,
  settingsStep: 0,
  users: [] as Array<UserType>,
  specificUser: {} as UserType,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    setUserProfile(state, { payload }: PayloadAction<ProfileType>) {
      state.profile = { ...state.profile, ...payload };
    },
    setUser(state, { payload }: PayloadAction<any>) {
      state.user = { ...state.user, ...payload };
    },
    setSettingsStep(state, { payload }: PayloadAction<number>) {
      state.settingsStep = payload;
    },
    clearStripeObj(state) {
      state.stripe = {} as StripeDataType;
    },
    clearSpecificUser(state) {
      state.specificUser = {} as UserType;
    },
    clearListOfUsers(state) {
      state.users = [] as Array<UserType>;
    },
  },
  extraReducers: {
    [getProfile.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getProfile.fulfilled.toString()]: (state, { payload }) => {
      state.user = payload;
      state.isLoading = false;
    },
    [getProfile.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [signOut.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [signOut.fulfilled.toString()]: (state, { payload }) => {
      localStorage.clear();

      state.user = {} as UserType;
      state.stripe = {} as StripeDataType;
      state.profile = null;
      state.isLoading = false;
    },
    [signOut.rejected.toString()]: (state, { payload }) => {
      state.user = {} as UserType;
      state.stripe = {} as StripeDataType;
      localStorage.clear();
      state.isLoading = false;
      state.errors = payload;
    },

    [switchType.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [switchType.fulfilled.toString()]: (state, { payload }) => {
      const token = payload.data.access_token;
      localStorage.setItem('token', token);

      state.user = {} as UserType;
      state.profile = null;
      state.isLoading = false;
    },
    [switchType.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [getBalance.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getBalance.fulfilled.toString()]: (state, { payload }) => {
      state.balance = payload && payload.data ? +payload.data.balance : 0;
    },
    [getBalance.rejected.toString()]: (state, { payload }) => {
      state.balance = state.balance;
      state.errors = payload;
    },
    [getListOfUsers.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getListOfUsers.fulfilled.toString()]: (state, { payload }) => {
      state.users = [...state.users, ...payload.data] as Array<UserType>;
    },
    [getListOfUsers.rejected.toString()]: (state, { payload }) => {
      state.users = [] as Array<UserType>;
      state.errors = payload;
    },
    [getSpecificUser.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getSpecificUser.fulfilled.toString()]: (state, { payload }) => {
      state.specificUser = { ...payload.data } as UserType;
    },
    [getSpecificUser.rejected.toString()]: (state, { payload }) => {
      state.specificUser = {} as UserType;
      state.errors = payload;
    },
    [replenishBalance.pending.toString()]: (state) => {
      state.isLoading = true;
      state.balanceReplenished = false;
    },
    [replenishBalance.fulfilled.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.balanceReplenished = true;
    },
    [replenishBalance.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
      state.balanceReplenished = false;
    },
    [connectWithStripe.pending.toString()]: (state) => {},
    [connectWithStripe.fulfilled.toString()]: (state, { payload }) => {
      state.stripe = { ...state.stripe, ...payload.data };
      state.isLoading = false;
    },
    [connectWithStripe.rejected.toString()]: (state, { payload }) => {
      state.stripe = {} as StripeDataType;
      state.isLoading = false;
      state.errors = payload;
    },
    [refreshStripeAccount.pending.toString()]: (state) => {},
    [refreshStripeAccount.fulfilled.toString()]: (state, { payload }) => {
      console.log(payload.data);
      state.stripe = { ...state.stripe, ...payload.data };
      state.isLoading = false;
    },
    [refreshStripeAccount.rejected.toString()]: (state, { payload }) => {
      state.stripe = {} as StripeDataType;
      state.isLoading = false;
      state.errors = payload;
    },
    [getNotifications.pending.toString()]: (state) => {},
    [getNotifications.fulfilled.toString()]: (state, { payload }) => {
      state.notifications = { ...state.notifications, ...payload };
      state.isLoading = false;
    },
    [getNotifications.rejected.toString()]: (state, { payload }) => {
      state.notifications = { ...state.notifications };
      state.isLoading = false;
      state.errors = payload;
    },
    [setNotifications.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [setNotifications.fulfilled.toString()]: (state, { payload }) => {
      state.notifications = { ...state.notifications, ...payload };
      state.isLoading = false;
    },
    [setNotifications.rejected.toString()]: (state, { payload }) => {
      state.notifications = { ...state.notifications };
      state.isLoading = false;
      state.errors = payload;
    },
    [updateProfile.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [updateProfile.fulfilled.toString()]: (state, { payload }) => {
      state.user = {
        ...state.user,
        first_name: payload.data.first_name,
        last_name: payload.data.last_name,
        timezone: payload.data.timezone,
        about_me: payload.data.about_me,
        photo: payload.data.photo,
      };
      state.isLoading = false;
    },
    [updateProfile.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [deleteStudentCard.pending.toString()]: (state) => {},
    [deleteStudentCard.fulfilled.toString()]: (state, { payload }) => {
      state.user = { ...state.user, stripe_customer: null };
    },
    [deleteStudentCard.rejected.toString()]: (state, { payload }) => {
      state.user = { ...state.user };
    },
  },
});

export const {
  setStateValue,
  setUserProfile,
  setUser,
  setSettingsStep,
  clearStripeObj,
  clearSpecificUser,
  clearListOfUsers,
} = userSlice.actions;

const userReducer = userSlice.reducer;

export default userReducer;
