import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Instructors.module.scss';
import Button from '../../../common/Button/Button';
import SideBar from './SideBar/SideBar';
import SearchBar from '../SearchBar/SearchBar';
import { StateType } from '../../../../redux/store';
import { getClasses, getCategories, getLevels, filterClasses } from '../../../../redux/actions/liveClassesActions';
import ClassesList from '../ClassesList/ClassesList';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { getFilters } from '../../../../helpers/liveClassesfilters';
import { Echo } from '../../../../index';
import { updateLiveClasses } from '../../../../redux/reducers/liveClasses';
import InstrList from './InstrList/InstrList';
import { getListOfUsers } from '../../../../redux/actions/userActions';
import LoadMore from './LoadMore/LoadMore';
import { clearListOfUsers } from '../../../../redux/reducers/user';

const InstructorsList: React.FC = (props: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { users } = useSelector((state: StateType) => state.user);

  useEffect(() => {
    return () => {
      dispatch(clearListOfUsers());
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchWrapper}>
        <div className={styles.title}>
          <h1>
            <span>Instructors</span>
          </h1>
        </div>
        {/*    <SearchBar /> */}
      </div>
      <div className={styles.contentWrapper}>
        {users && <InstrList arr={users} type={'recommended'} />}
        <LoadMore users={users} />
      </div>
    </div>
  );
};

export default InstructorsList;
