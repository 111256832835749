import React, { useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../common/Button/Button';
import styles from './ModalCancelClass.module.scss';

const ModalCancelClass: React.FC<{
  open: boolean;
  onCloseModal: Function;
  cancelCurrClass: Function;
  cancelAllClasses: Function;
  recurrent?: boolean;
}> = ({ open, onCloseModal, cancelCurrClass, cancelAllClasses, recurrent }) => {
  return (
    <Modal open={open} onCloseModal={() => onCloseModal()} customClass={styles.modalWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.imgWrapper}>
          <img src={require('../../../../../assets/svg/stop.svg')} alt={'cancelLiveClass'} className={styles.image} />
        </div>
        <h2>Cancel a live class</h2>
        <p>
          Are you sure you want to cancel a live class? You won't be able to undo this action and all sign ups will be
          lost
        </p>
        <div className={styles.buttons}>
          <Button cancel onClick={() => cancelCurrClass()}>
            Cancel this live class
          </Button>
          <Button onClick={() => onCloseModal()}>Back</Button>
          {recurrent && (
            <Button cancel onClick={() => cancelAllClasses()}>
              Cancel this and all following live class
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalCancelClass;
