import moment from 'moment';
import { LiveClassType } from '../redux/reducers/liveClasses';

export const setArrayForCarusel = (array: Array<any> = [], size: number = 4): Array<any> => {
  let subarray = [];

  for (let i = 0; i < Math.ceil(array.length / size); i++) {
    subarray[i] = array.slice(i * size, i * size + size);
  }
  return subarray;
};

export const getTimeFromMins = (mins: number | string) => {
  let hours: number | string = Math.trunc(+mins / 60),
    minutes: number | string = +mins % 60;

  hours = hours < 10 ? (hours = '0' + hours) : hours;
  minutes = minutes < 10 ? (minutes = '0' + minutes) : minutes;

  return `${hours}:${minutes}`;
};

export const stringLimiter = (string: string, max = 300) => {
  if (string && string.length > max) {
    return `${string.substr(0, max - 1)}...`;
  } else {
    return string;
  }
};

export const capitalizeFirstLetter = (el: string) => {
  return el.slice(0, 1).toUpperCase() + el.slice(1);
};

export const timeHandler = (gtmStartDateTime: string, userTimezone: string | null) => {
  const time = dateLocalHandler(gtmStartDateTime, userTimezone).slice(-5);

  const wholeTime = time && time.length > 5 ? time.slice(0, -3) : time;
  const hours = +wholeTime.slice(0, 2);
  const rHours = hours > 12 ? hours - 12 : hours;
  const amPm = hours < 12 ? 'AM' : 'PM';
  const mins = wholeTime.slice(3);

  return `${rHours}:${mins} ${amPm}`;
};

export const userTimezone = (timezone: string) => {
  return timezone.slice(-7, -1);
};

export const timeWithOffset = (timezone?: string) => {
  return moment(moment().utcOffset('+00:00')).utcOffset(timezone ? userTimezone(timezone) : '+00:00');
};

export const dateWithTimezone = (dateString: string, timeString: string, timeZone: string | null) => {
  const timezone = timeZone && userTimezone(timeZone);

  const time = timeString ? timeString : moment().format('HH:mm');

  const momentDate = moment(`${dateString} ${time}`, 'YYYY-MM-DD HH:mm');

  const momentLocalDate = moment(momentDate);

  if (timezone) {
    const momentWithTimezoneDate = moment(momentDate).utcOffset(timezone);

    return momentWithTimezoneDate;
  } else {
    return momentLocalDate;
  }
};
export const dateLocalHandler = (gtmStartDateTime: string, userTimezone: string | null) => {
  const timezone = userTimezone && userTimezone.slice(-7, -1);
  const momentUtcDate = moment.utc(gtmStartDateTime, 'YYYY-MM-DD hh:mm');
  const momentLocalDate = moment(momentUtcDate).local().format('YYYY-MM-DDTHH:mm');

  if (timezone) {
    const momentWithTimezoneDate = moment(momentUtcDate).utcOffset(timezone).format('YYYY-MM-DDTHH:mm');

    return momentWithTimezoneDate;
  } else {
    return momentLocalDate;
  }
};

export const dateHandler = (gtmStartDateTime: string, userTimezone: string | null, short?: boolean) => {
  const date = dateLocalHandler(gtmStartDateTime, userTimezone);

  const dateObj = moment(date);

  const longFormat = `${dateObj.date()} ${dateObj.format('MMMM')}`;
  const shortFormat = `${dateObj.date()} ${dateObj.format('MMM')}`;

  return short ? shortFormat : longFormat;
};

export const timeLocalHandler = (gtmStartDateTime: string, userTimezone?: string) => {
  const year = gtmStartDateTime.slice(0, 4);
  const month = gtmStartDateTime.slice(5, 7);
  const day = gtmStartDateTime.slice(8, 10);
  const time = gtmStartDateTime.slice(-5);
  const classDate = new Date(`${year}/${month}/${day} ${time} UTC`);

  const yearL = classDate.getFullYear();
  const monthL =
    classDate.getMonth() && `${classDate.getMonth()}`.length > 1
      ? `${+classDate.getMonth() + 1}`
      : `0${+classDate.getMonth() + 1}`;
  const dayL =
    classDate.getDate() && `${classDate.getDate()}`.length > 1 ? classDate.getDate() : `0${classDate.getDate()}`;

  const timeL = classDate.toLocaleString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${yearL}-${monthL}-${dayL} ${timeL}`;
};

export const compareTime = (gtmStartDateTime: string, userTimezone: string | null) => {
  const timezone = userTimezone && userTimezone.slice(-7, -1);

  const currDate = timezone ? moment().utcOffset(timezone).toDate() : moment().toDate();

  const classDate = moment.utc(gtmStartDateTime).toDate();

  const fiveMins = 5 * 60 * 1000;

  //check if there is no more than five mins to the start
  return +classDate - +currDate > 0 && +classDate - +currDate > fiveMins;
};

export const dateCastHandler = (startDate: string) => {
  const year = startDate.slice(0, 4);
  const month = startDate.slice(5, 7);
  const day = startDate.slice(8, 10);

  const classDate = new Date(`${year}/${month}/${day}`);

  return classDate;
};

export const gtmDateCastHandler = (gtmStartDateTime: string) => {
  const momentDate = moment.utc(gtmStartDateTime).toDate();

  return momentDate;
};

export const currentDate = (dateValue: string, timezone?: string | null) => {
  const tZone = timezone && userTimezone(timezone);

  const momentUTCdate = tZone
    ? moment(dateValue, 'YYYY-MM-DD hh:mm').utcOffset(tZone, true)
    : moment(dateValue, 'YYYY-MM-DD hh:mm');
  const dayOfWeek = momentUTCdate.format('dddd');

  const month = momentUTCdate.format('MMMM');
  const day = momentUTCdate.date();

  return `${dayOfWeek}, ${day} ${month}`;
};

export const classDateDivider = (data: string, index: number, arr: Array<LiveClassType>, timezone?: string | null) => {
  const tZone = timezone && userTimezone(timezone);

  const dateTimeToDate = (date?: string) => {
    const momentUTCdate = tZone
      ? moment.utc(date, 'YYYY-MM-DD hh:mm').utcOffset(tZone)
      : moment(date, 'YYYY-MM-DD hh:mm');

    return date ? momentUTCdate.format('YYYY-MM-DD') : '';
  };

  const dateSort = arr.find((el) => {
    const currDate = dateTimeToDate(data);
    const elDate = dateTimeToDate(el?.gtm_start_datetime);
    const prevElDate = dateTimeToDate(arr[index - 1]?.gtm_start_datetime);
    return elDate === currDate && prevElDate !== currDate;
  });
  let currDate = '';

  if (dateSort || (!dateSort && index === 0)) {
    const firstEl = dateTimeToDate(arr[0].gtm_start_datetime);
    const dateSortVal = dateTimeToDate(dateSort?.gtm_start_datetime);

    currDate = index === 0 && !dateSort ? firstEl : dateSortVal ? dateSortVal : firstEl;

    return currentDate(currDate, timezone);
  }
};

export const photoUserErrHandler = (userPhoto: string) => {
  return `${
    process.env.REACT_APP_SERVER_HOST
  }/storage/photo/${userPhoto},${require(`../assets/svg/liveClasses/user.svg`)}`;
};
