import React from 'react';
import {
  makeStyles,
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { Button, Fade, Paper, ClickAwayListener } from '@material-ui/core';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';

import styles from './Popup.module.scss';

type PropsType = {
  button?: React.ReactNode | string;
  type?: string;
};

const PositionedPopper: React.FC<PropsType> = ({ button, children, type }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();

  const handleClick = (newPlacement: PopperPlacementType) => (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  return (
    <div className={` ${styles.wrapper}`}>
      <Popper
        className={styles.rootPopper}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Fade {...TransitionProps} timeout={50}>
              <Paper>{children}</Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>

      <Button onClick={handleClick('bottom-end')}>{button}</Button>
    </div>
  );
};

export default PositionedPopper;
