import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link, NavLink } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import { studentMenu, instructorMenu } from '../../../helpers/values';
import { StateType } from '../../../redux/store';
import styles from './Header.module.scss';
import { ReactComponent as DarkLogo } from '../../../assets/svg/logoBlack.svg';
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';
import { Drawer, Button, Hidden } from '@material-ui/core';
import { UserButton } from '../UserButton/UserButton';

type HeaderType = {
  logoIsDark?: boolean;
  isAuthLayout?: boolean;
};

const Menu = ({ user, menuType, location }: any) => {
  return (
    <div className={styles.menuWrapper}>
      {user && user.id && (
        <Hidden mdUp>
          <div className={styles.menuHeader}>
            <div className={styles.logo}>
              <Logo />
            </div>
            {user && (
              <>
                <NavLink to={`/user/profile/${user.id}`}>
                  <UserButton user={user} />
                </NavLink>
                <hr className={styles.horDivider} />
              </>
            )}
          </div>
        </Hidden>
      )}
      {user && user.id ? (
        location.pathname !== '/auth/stripe' && (
          <>
            <div className={`${styles.navMenu} ${styles.links}`}>
              {!location.pathname.includes('home') && (
                <Hidden mdUp>
                  <NavLink activeClassName={styles.activeLink} to='/user/home'>
                    Home
                  </NavLink>
                </Hidden>
              )}
              {menuType &&
                menuType.length > 0 &&
                menuType.map((item: any, index: number) => (
                  <NavLink activeClassName={styles.activeLink} to={`/user/${item.link}`} key={index}>
                    {item.name}
                  </NavLink>
                ))}
            </div>
            <NavBar />
          </>
        )
      ) : (
        <div className={styles.links}>
          {location.pathname.includes('sign-in') || location.pathname.includes('sign-up') ? (
            <>
              <NavLink activeClassName={styles.activeLink} to='/user/home'>
                Home
              </NavLink>
              <NavLink activeClassName={styles.activeLink} to='/user/instructors-list'>
                Find your instructor
              </NavLink>
            </>
          ) : (
            <>
              {!location.pathname.includes('home') && (
                <Hidden mdUp>
                  <NavLink activeClassName={styles.activeLink} to='/user/home'>
                    Home
                  </NavLink>
                </Hidden>
              )}
              <NavLink activeClassName={styles.activeLink} to='/user/instructors-list'>
                Find your instructor
              </NavLink>
              <NavLink activeClassName={styles.activeLink} to='/auth/sign-in'>
                Login
              </NavLink>
              <NavLink activeClassName={styles.activeLink} to='/auth/sign-up'>
                Create an account
              </NavLink>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const MenuDrawer: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<any>(false);
  const location = useLocation();
  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setIsOpen(open);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <>
      {['top'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(!isOpen)}
            classes={{ root: styles.hamburgerButton }}
            className={`${isOpen ? styles.hamburgerOpen : styles.hamburgerClosed}`}
            disableRipple
          >
            <div>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Button>
          <Drawer anchor={'top'} open={isOpen} onClose={toggleDrawer(false)} classes={{ root: styles.menuRoot }}>
            {children}
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
};

const Header: React.FC<HeaderType> = ({ logoIsDark }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const arrayLinks = location.pathname.split('/');
  const activeLink = arrayLinks[arrayLinks.length - 1];
  const { user } = useSelector((state: StateType) => state.user);
  const { message } = useSelector((state: StateType) => state.alert);
  const token = localStorage.getItem('token');
  const [menuType, setMenuType] = useState<any>([] as any);
  const [userMenu, setUserMenu] = useState(false);

  useEffect(() => {
    if (token && user.type) {
      user.type === 'Student' ? setMenuType(studentMenu) : setMenuType(instructorMenu);
    }
  }, [token]);

  return (
    <header className={`${styles.header} ${logoIsDark && styles.mainLayoutHeader}`}>
      <Hidden mdUp>
        <div className={styles.logo}>
          <Link to='/user/home'>
            <Logo />
          </Link>
        </div>
        <MenuDrawer>
          <Menu user={user} menuType={menuType} location={location} />
        </MenuDrawer>
      </Hidden>
      <Hidden smDown>
        <div className={styles.logo}>
          <Link to='/user/home'>{logoIsDark ? <DarkLogo /> : <Logo />}</Link>
        </div>
        <Menu user={user} menuType={menuType} location={location} />
      </Hidden>
    </header>
  );
};
export default Header;
