import React from 'react';

import styles from './MainLoader.module.scss';

type MainLoaderType = {
  loading?: boolean;
  local?: boolean;
};

const MainLoader: React.FC<MainLoaderType> = ({ loading, local }) => {
  return (
    <div
      className={`${styles.wrapLoader} ${local ? styles.wrapLoaderLocal : ''}`}
      style={loading ? { display: 'flex' } : { display: 'none' }}
    >
      <div className={styles.loader}>
        {/* <img
          src={require('../../../assets/preloader/preloader.gif')}
          alt='preloader'
        /> */}
        <div className={styles.preloader}>
          <div className={styles.circle}></div>
          <div className={styles.halfCircleFirst}></div>
          <div className={styles.halfCircleSecond}></div>
        </div>
      </div>
    </div>
  );
};

export default MainLoader;
