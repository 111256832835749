import { instance } from './api';
import { setPassword } from './../redux/actions/authActions';
import { SignUpType, SignInType } from '../types/auth';

type ConfirmType = {
  confirm_code: string;
  password: string;
  first_name: string;
  last_name: string;
  timezone: string;
  type: string;
};

export const auth = {
  signUp: async (value: SignUpType | {}) => {
    const { data } = await instance.post(`/users`, value);
    return data;
  },

  signIn: async (value: SignInType | {}) => {
    const { data } = await instance.post(`/auth/login`, value);
    return data;
  },

  confirm: async (value: ConfirmType | {}) => {
    const { data } = await instance.post(`/users/confirm`, value);
    return data;
  },
  resendConfirm: async (value: ConfirmType | {}) => {
    const { data } = await instance.post(`/users/resendConfirm`, value);
    return data;
  },
  update: async (value: any | {}) => {
    const { data } = await instance.post(`/users`, value);
    return data;
  },
  resetPassword: async (value: any | {}) => {
    const data = await instance.post(`/users/reset-password`, value);
    return data;
  },
  setPassword: async (value: any | {}) => {
    const { data } = await instance.put(`/users/reset-password`, value);
    return data;
  },
  setUserType: async (value: any | {}) => {
    const { data } = await instance.post(`/users/type`, value);
    return data;
  },
  switchType: async (value: any | {}) => {
    const data = await instance.post(`/auth/switch-type`, value);
    return data;
  },
  googleAuth: async (value: { code: string; sign_up: boolean }) => {
    const data = await instance.get(`/auth/google-auth`, {
      params: value,
    });
    return data;
  },
};
