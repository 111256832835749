import { instance } from './api';

export const stream = {
  createSession: async (values: any) => {
    const { data } = await instance.post('/stream/create-session', values);
    return data;
  },
  saveStream: async (id:number, values: any) => {
    const { data } = await instance.put(`/stream/save-stream/${id}`, values);
    return data;
  },
  joinClass: async (id:number, values: any) => {
    const { data } = await instance.put(`/stream/join-class/${id}`, values);
    return data;
  },
  leaveClass: async (id:number, values: any) => {
    const { data } = await instance.put(`/stream/leave-class/${id}`, values);
    return data;
  },
  getClassUsers: async (id:number) => {
    const { data } = await instance.get(`/stream/get-class-users/${id}`);
    return data;
  },
};
