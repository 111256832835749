import React from 'react';
import styles from './ClassInfo.module.scss';
import {
  stringLimiter,
  timeHandler,
  compareTime,
  timeLocalHandler,
  dateHandler,
} from '../../../../../helpers/functions';
import { LiveClassType } from '../../../../../redux/reducers/liveClasses';
import { UserType } from '../../../../../redux/reducers/user';
import { MenuItem, Menu, Box, Hidden } from '@material-ui/core';
import { ReactComponent as Clock } from '../../../../../assets/svg/time.svg';
import { ReactComponent as Signal } from '../../../../../assets/svg/liveClasses/signal.svg';
import { ReactComponent as Dollar } from '../../../../../assets/svg/liveClasses/dollar.svg';
import { ReactComponent as Star } from '../../../../../assets/svg/liveClasses/star.svg';
import { ReactComponent as FilledStar } from '../../../../../assets/svg/liveClasses/starFilled.svg';
import { ReactComponent as Calendar } from '../../../../../assets/svg/liveClasses/calendar.svg';
import { ReactComponent as User } from '../../../../../assets/svg/liveClasses/user.svg';

type ClassInfoType = {
  liveClass: LiveClassType;
  user: UserType;
};

export const ClassInfo: React.FC<ClassInfoType> = ({ liveClass, user }) => {
  const durationHandler = (duration: string) => {
    const hours = +duration.slice(0, 2);
    const minutes = +duration.slice(3, 5);

    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes > 0 ? minutes + ' minutes' : ''}`;
  };

  return (
    <div className={styles.classInfo}>
      <div>
        <span>
          <span className={styles.subTitle}>Date:</span>
          <Calendar className={styles.icon} />
          <strong>
            {liveClass.start_date &&
              `${dateHandler(liveClass.gtm_start_datetime, user.timezone)} ${timeHandler(
                liveClass.gtm_start_datetime,
                user.timezone
              )}`}
          </strong>
        </span>
      </div>
      <div>
        <span>
          <span className={styles.subTitle}>Level:</span>
          <Star className={styles.icon} />
          <strong>
            {liveClass && liveClass.levels && liveClass.levels.length > 0
              ? liveClass.levels.map((el: any, index: any) => {
                  return `${index > 0 ? ', ' : ''}${el.name}`;
                })
              : 'Any level'}
          </strong>
        </span>
      </div>
      <div>
        <span>
          <span className={styles.subTitle}>Time:</span>
          <Clock className={styles.icon} />
          <strong>{liveClass && liveClass.duration ? durationHandler(liveClass.duration) : '0 hours'}</strong>
        </span>
      </div>
      <div>
        <span>
          <span className={styles.subTitle}>Price:</span>
          <Dollar className={styles.icon} />
          <strong>{liveClass && liveClass.price && liveClass.price ? `$ ${liveClass.price}` : '$ Free'}</strong>
        </span>
      </div>
    </div>
  );
};
