import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  typography: {
    fontFamily: "Ubuntu, Helvetica, Arial, sans-serif",
  },
  palette: {
    primary: {
      light: "#ff9a8e",
      main: "#FF8172",
      dark: "#b25a4f",
      contrastText: "#fff",
    },
    secondary: {
      light: "#60587b",
      main: "#392F5A",
      dark: "#27203e",
      contrastText: "#fff",
    },
    success: {
      light: "#73cdc0",
      main: "#51C1B1",
      dark: "#38877b",
      contrastText: "#fff",
    },
    warning: {
      light: "#f6d98b",
      main: "#F4D06F",
      dark: "#aa914d",
      contrastText: "#fff",
    },
    info: {
      light: "#fff9f3",
      main: "#FFF8F0",
      dark: "#b2ada8",
      contrastText: "#000",
    },
  },
});
