import React, { useState, useEffect } from 'react';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { ReactComponent as ClockIcon } from '../../../../../../assets/svg/time.svg';
import styles from './TimePicker.module.scss';
import { timeWithOffset, dateWithTimezone } from '../../../../../../helpers/functions';

const format = 'h:mm a';

const TimePickerRC = ({
  input,
  defaultValue,
  startDate,
  endDate,
  recurring,
  currDateTime,
  timeZone,
  ...props
}: any) => {
  let currDate = moment().add('hour', 1).format('YYYY-MM-DD HH:mm');
  let currTime = moment().add('hour', 1).format('HH:mm');
  let now = dateWithTimezone(currDate, currTime, timeZone);

  const [selectedTime, setSelectedTime] = useState(defaultValue);

  const [val, setVal] = useState(now);
  const [disabledHours, setDisabledHours] = useState<any>([]);

  let currHour = now.format('HH');
  const nowPlusOne = now.hour(+currHour);

  const startDateCheck = startDate ? new Date(startDate) : new Date(now.format('YYYY-MM-DD'));
  const endDateCheck = endDate ? new Date(endDate) : new Date(now.format('YYYY-MM-DD'));

  useEffect(() => {
    setVal(nowPlusOne);
    input && input.onChange(nowPlusOne.format('HH:mm'));
  }, []);

  useEffect(() => {
    if (selectedTime) {
      let hours = selectedTime.slice(0, 2);
      let minutes = selectedTime.slice(3);

      input && input.onChange(selectedTime);
      setVal(
        moment()
          .hour(+hours)
          .minute(+minutes)
      );
    }
  }, [selectedTime]);

  useEffect(() => {
    if (defaultValue && now) {
      let hours = defaultValue.slice(0, 2);
      let minutes = defaultValue.slice(3);

      const currDateNtime = now.hour(+hours).minute(+minutes);

      setVal(currDateNtime);
      setSelectedTime(currDateNtime.format('HH:mm'));
    }
  }, [defaultValue]);

  useEffect(() => {
    startDate && setDisabledHours(disableHours());
  }, [startDate]);

  const disableHours = () => {
    //@ts-ignore
    const arrHours = [...Array(25).keys()];
    const [, ...newArr] = arrHours;

    if (
      (startDate && +startDateCheck > +new Date(now.format('YYYY-MM-DD')) && recurring) ||
      (+endDateCheck > +startDateCheck && recurring) ||
      (+startDateCheck > +new Date(now.format('YYYY-MM-DD')) && !recurring)
    ) {
      return [] as Array<number>;
    } else {
      return arrHours.slice(0, +currHour);
    }
  };

  const disableMinutes = () => {
    return [2, 4] as Array<number>;
  };

  return (
    <TimePicker
      showSecond={false}
      value={val}
      defaultValue={val}
      className={`xxx ${styles.wrapper}`}
      onChange={(e: any) => {
        if (e && e._d) {
          setSelectedTime(moment(e._d).format('HH:mm'));
        } else {
          setSelectedTime('');
        }
      }}
      disabledHours={() => {
        return disabledHours;
      }}
      disabledMinutes={disableMinutes}
      format={format}
      use12Hours
      inputReadOnly
      minuteStep={15}
      inputIcon={<ClockIcon />}
    />
  );
};

export default TimePickerRC;
