import React, { useState, useEffect } from 'react';
import styles from './InstrList.module.scss';
import { GridList, Modal, Divider, Hidden } from '@material-ui/core';
import GridListTile from '@material-ui/core/GridListTile';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../../../common/Button/Button';
import { LiveClassType } from '../../../../../redux/reducers/liveClasses';
import { useDispatch, useSelector } from 'react-redux';
import { UserType } from '../../../../../redux/reducers/user';
import { StateType } from '../../../../../redux/store';
import { ReactComponent as User } from '../../../../../assets/svg/liveClasses/user.svg';
import MainLoader from '../../../../common/MainLoader/MainLoader';
import { photoUserErrHandler } from '../../../../../helpers/functions';
import ImageWithFallbacks from '../../../../components/ImageWithFallbacks/ImageWithFallbacks';

type InstrListType = {
  arr: Array<UserType>;
  type?: string;
  isVertical?: boolean;
  authorName?: string;
  isInstructor?: boolean;
};

type InstrType = {
  el: UserType;
};

const InstrList: React.FC<InstrListType> = ({ arr, type, isVertical, authorName, isInstructor }) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const history = useHistory();

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = () => {
    const width = document.body.clientWidth;
    +width > 960 ? setIsMobile(false) : setIsMobile(true);
  };

  let currentImg = 1;

  const imgNumber = (index: number) => {
    if ((index + 1) % currentImg === 0 && currentImg < 3) {
      currentImg++;
      return currentImg;
    } else {
      currentImg = 1;
      return 1;
    }
  };

  const InstructorItem: React.FC<InstrType> = ({ el }) => {
    const SingleItem = () => {
      return (
        <>
          <div className={styles.titleDesc}>
            <div className={styles.author}>
              <span>
                {el.md_photo ? (
                  <ImageWithFallbacks
                    src={`${process.env.REACT_APP_SERVER_HOST}/storage/photo/${el.sm_photo}`}
                    alt='userImg'
                    fallbacks={`${photoUserErrHandler(el.photo)}`}
                  />
                ) : (
                  <User />
                )}
              </span>
              <div className={styles.userName}>
                <p>
                  {el.first_name} {el.last_name}
                </p>
                <span>Instructor</span>
              </div>
            </div>
          </div>
          <div className={styles.description}>
            <p>
              {el.about_me
                ? el.about_me.length > 300 && !isVertical
                  ? `${el.about_me.substr(0, 299)}...`
                  : el.about_me
                : el.about_me}
            </p>
          </div>
          <Hidden smDown>
            <Button
              children={`View profile`}
              customClass={styles.viewProfileButton}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/user/profile/${el.id}`);
              }}
            />
          </Hidden>
        </>
      );
    };
    return (
      <>
        <Hidden smDown>
          <SingleItem />
        </Hidden>
        <Hidden mdUp>
          <Link to={`${`/user/profile/${el.id}`}`} />
          <SingleItem />
        </Hidden>
      </>
    );
  };

  return (
    <section className={`${styles.wrapper} ${isVertical && styles.wrapperVertical}`}>
      <MainLoader loading={loading} />

      {arr && arr.length > 0 ? (
        <GridList className={styles.gridList} cols={isMobile ? 1 : 4}>
          {arr.map((el, index: number) => {
            return (
              <GridListTile
                key={el.id}
                classes={{
                  root: styles.dateTitle,
                  tile: styles.tileWrapper,
                }}
              >
                <InstructorItem el={el} />
              </GridListTile>
            );
          })}
        </GridList>
      ) : (
        <div className={styles.emptyClasses}>
          <img src={require('../../../../../assets/images/camera.png')} alt={`${type}`} />
          <h2>Oh no! No instructors available</h2>
          {/*  <Button
            iconLeft={<Camera />}
            children={'Schedule live class'}
            customClass={styles.scheduleButton}
          /> */}
        </div>
      )}
    </section>
  );
};

export default InstrList;
