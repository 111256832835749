import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form, submit } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';
import Input from '../../../../common/Input/Input';
import { required, email, password } from '../../../../../helpers/validate';
import styles from './NoStripe.module.scss';
import { ReactComponent as Stripe } from '../../../../../assets/svg/stripeLogo.svg';
import { Link as MaterialLink, Hidden } from '@material-ui/core';
import Button from '../../../../common/Button/Button';
import { connectWithStripe, refreshStripeAccount } from '../../../../../redux/actions/userActions';

const NoStripe: React.FC<{ refresh: boolean }> = ({ refresh }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const StripeLink = () => {
    return (
      <a href={'https://stripe.com/en-gb-us'} target='_blank'>
        Learn more
      </a>
    );
  };

  return (
    <div className={styles.form}>
      <div className={styles.logo}>
        <Stripe />
        <Hidden mdUp>
          <StripeLink />
        </Hidden>
      </div>
      <div className={styles.inputs}>
        <div>
          <h4>Connect your account to Stripe</h4>
          <p>
            Connect your Stripe Standard account to take payments through Stripe.{' '}
            <Hidden smDown>
              <StripeLink />
            </Hidden>
          </p>
          <Button
            onClick={() => (refresh ? dispatch(refreshStripeAccount(null)) : dispatch(connectWithStripe(null)))}
            color='primary'
            variant='contained'
            customClass={styles.connectButton}
            customWrapper={styles.buttonWrapper}
          >
            Connect with Stripe
          </Button>

          {/*    {errors && <div className='error'>{errors}</div>} */}
        </div>
      </div>
    </div>
  );
};

export default NoStripe;
