import React, { useState, useEffect } from 'react';
import styles from './Stream.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../redux/store';
import {
  subscribeToClass,
  getUserClasses,
  getClass,
} from '../../../../redux/actions/liveClassesActions';
import { ReactComponent as Clock } from '../../../../assets/svg/liveClasses/clock.svg';
import { ReactComponent as Signal } from '../../../../assets/svg/liveClasses/signal.svg';
import MainLoader from '../../../common/MainLoader/MainLoader';
import Modal from '@material-ui/core/Modal';
import VideoLesson from './VideoLesson/VideoLesson';
import { Echo } from '../../../../index';
import {
  setClass,
  LiveClassType,
} from '../../../../redux/reducers/liveClasses';
import { useParams, useHistory } from 'react-router-dom';
import NotSupported from './NotSupported/NotSupported';

const Stream: React.FC = () => {
  const [open, setOpen] = useState(false);

  const { liveClasses, liveClass, instrClasses, userClasses } = useSelector(
    (state: StateType) => state.liveClasses
  );
  const [signUp, setSignUp] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const params: { id: string; stream: string } = useParams();

  useEffect(() => {
    const id = +params.id;
    if (params && params.id) {
      if ((liveClass && liveClass.id !== id) || !liveClass.id) {
        window.scrollTo(0, 0);
        setLoading(true);
        getClassNclasses(id);
      }
    }
  }, [params.id]);

  useEffect(() => {
    //if the user not signed for this class he will be redirected to the details this class
    if (userClasses && liveClass.id) {
      const liveClassMatched = userClasses.find(
        (el: LiveClassType) => el.id === liveClass.id
      );

      !liveClassMatched && history.push(`/user/live-classes/${liveClass.id}`);
    }
  }, [userClasses]);

  const getClassNclasses = async (id: number) => {
    await dispatch(getClass(id));
    await dispatch(getUserClasses(null));
    await setTimeout(() => setLoading(false), 300);
  };

  useEffect(() => {
    if (liveClass.id) {
      console.log('LiveClassUpdated listen');
      Echo.channel('LiveClasses').listen('LiveClassUpdated', (e: any) => {
        if (e.liveClass.id === liveClass.id) {
          dispatch(setClass(e.liveClass));
          //dispatch(getClasses(null));
        }
      });
    }
    return () => {
      // Echo.leave('LiveClasses');
    };
  }, [liveClass.id]);

  const StatusFlag = () => {
    return (
      <div
        className={`${styles.statusFlag} ${
          liveClass.status === 'Upcoming' ? styles.upcoming : styles.running
        }`}
      >
        {liveClass.status === 'Upcoming' ? <Clock /> : <Signal />}
        {liveClass.status && liveClass.status}
      </div>
    );
  };

  const correctBrowser = () => {
    const browser = ((agent: string) => {
      switch (true) {
        case agent.indexOf('edge') > -1:
          return false;
        case agent.indexOf('edg') > -1:
          return false;
        case agent.indexOf('opr') > -1:
          return false;
        case agent.indexOf('chrome') > -1:
          return true;
        case agent.indexOf('trident') > -1:
          return false;
        case agent.indexOf('firefox') > -1:
          return true;
        case agent.indexOf('safari') > -1:
          return true;
        default:
          return false;
      }
    })(window.navigator.userAgent.toLowerCase());
    return browser;
  };

  return (
    <div className={styles.wrapper}>
      <MainLoader loading={loading} />
      <NotSupported id={liveClass.id} correctBrowser={correctBrowser()} />
      <VideoLesson Flag={StatusFlag} correctBrowser={correctBrowser()} />

      <div className={styles.contentWrapper}></div>
    </div>
  );
};

export default Stream;
