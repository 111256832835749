import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { useHistory, Link, Route, Redirect, useLocation } from 'react-router-dom';
import Input from '../../../common/Input/Input';
import Button from '../../../common/Button/Button';

import { confirmUser, updateUser, setUserType, signIn, switchType } from '../../../../redux/actions/authActions';
import { StateType } from '../../../../redux/store';
import { required, password } from '../../../../helpers/validate';

import styles from './CreateAccount.module.scss';
import Stripe from '../Stripe/Stripe';
import { GoogleSignUpType, clearNewUser, setPassword, setError } from '../../../../redux/reducers/auth';

export type CreateAccountType = {
  first_name: string;
  last_name: string;
  timezone?: string;
  password: string;
};

const CreateAccount: React.FC<InjectedFormProps<CreateAccountType>> = ({ handleSubmit }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { profile } = useSelector((state: StateType) => state.user);
  const { createAccount } = useSelector((state: StateType) => state.form);
  const { errors, newUser, user, user_created, googleSignUp } = useSelector((state: StateType) => state.auth);
  const [timezone, setZone] = useState('');
  const [pass, setPass] = useState('');
  const [errorLocal, setErrorLocal] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [googleConfirm, setGoogleConfirm] = useState('');
  let passwordLocal = localStorage.getItem('password');
  const userLocal = localStorage.getItem('user');
  const parsedUser = userLocal && JSON.parse(userLocal);
  const newUserLocal = localStorage.getItem('newUser');
  const parsedNewUser = newUserLocal && JSON.parse(newUserLocal);
  const googleUser = localStorage.getItem('googleUser');
  const parsedGoogleUser: GoogleSignUpType = googleUser && JSON.parse(googleUser);

  useEffect(() => {
    resizeHandler();

    return () => {
      dispatch(clearNewUser());
      dispatch(setError(''));
    };
  }, []);

  useEffect(() => {
    if (createAccount && createAccount.active && errors) {
      setErrorLocal('');
    } else if (createAccount && !createAccount.active && errors) {
      setErrorLocal(errors);
    }
  }, [createAccount, errors]);

  useEffect(() => {
    if ((googleSignUp && googleSignUp.id) || (parsedGoogleUser && parsedGoogleUser.id)) {
      let firstN = googleSignUp.first_name || parsedGoogleUser.first_name;
      let lastN = googleSignUp.last_name || parsedGoogleUser.last_name;
      let googleC = googleSignUp.confirm_code || parsedGoogleUser.confirm_code;
      setFirstName(firstN);
      setLastName(lastN);
      setGoogleConfirm(googleC);
    }
  }, [googleSignUp]);

  useEffect(() => {
    if (passwordLocal && newUser.role === 'Instructor' && newUser.is_multiroled) {
      changeRoleAfterSignIn('Student', passwordLocal);
      history.push(`/user/home`);
    } else if (passwordLocal && newUser.role === 'Student' && newUser.is_multiroled) {
      history.push(`/auth/stripe`, { from: 'createAccount' });
    } else if (passwordLocal && newUser.role === 'Student' && !newUser.is_multiroled) {
      dispatch(
        signIn({
          email: parsedNewUser.email,
          password: passwordLocal,
        })
      );
    } else if (newUser.role === 'Instructor' && !newUser.is_multiroled) {
      user_created && history.push(`/auth/stripe`, { from: 'createAccount' });
    } else if (!passwordLocal && !newUser.id) {
      !passwordLocal && history.push('/');
    }
  }, [user_created]);

  const resizeHandler = () => {
    setTimeout(function () {
      const vh = window.innerHeight;
      const vw = window.innerWidth;
      const viewport = document.getElementsByTagName('meta')[3];
      viewport.setAttribute(
        'content',

        'height=' + vh + 'px, width=' + vw + 'px, initial-scale=1.0'
      );
      viewport.setAttribute('name', 'viewport');
    }, 300);
  };

  const onSubmit = (e: any, timezone?: string) => {
    e.preventDefault();

    if (newUser.is_multiroled) {
      // here will be method that allows you to adding second role for the user
      handleSubmit((value) => {
        setPass(value.password);
        localStorage.setItem('password', value.password);
        dispatch(setPassword(value.password));
        dispatch(
          setUserType({
            ...value,
            type:
              (parsedNewUser && parsedNewUser.role === 'Student') || newUser.role === 'Student'
                ? 'Instructor'
                : 'Student',
            email: (parsedNewUser && parsedNewUser.email) || newUser.email,
          })
        );
      })();
    } else {
      handleSubmit((value) => {
        const confirm = localStorage.getItem('confirm_code');
        localStorage.setItem('password', value.password);
        setPass(value.password);
        dispatch(setPassword(value.password));

        dispatch(
          confirmUser({
            ...value,
            type: newUser.role ? newUser.role : parsedNewUser.role,
            confirm_code: confirm ? confirm : googleConfirm,
          })
        );
      })();
    }
  };

  const changeRoleAfterSignIn = async (role: string, password: string) => {
    await dispatch(
      signIn({
        email: parsedNewUser ? parsedNewUser.email : newUser.email,
        password: passwordLocal,
      })
    );
    // after when user signed in, switch user`s role
    await dispatch(switchType({ type: role }));
  };

  /* const resizeScreen = () => {
    let vh = window.innerHeight * 0.01;
    alert(vh);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }; */

  return (
    <div
      className={`${styles.wrapper} ${
        newUser.role === 'Instructor' || (newUser.role === 'Student' && newUser.is_multiroled)
          ? styles.teacher
          : styles.student
      }`}
    >
      <div>
        {newUser.is_multiroled ? (
          <Form onSubmit={(e) => onSubmit(e)} className={styles.form}>
            <div className={styles.internalWrapper}>
              <h1>Create account</h1>
              <p>
                {newUser.role === 'Student'
                  ? 'You already have an account please enter the password to create another account like instructor'
                  : 'You already have an account please enter the password to create another account like student'}
              </p>

              <h4>Password</h4>
              <Field
                component={Input}
                name='password'
                label='Password'
                type='password'
                validate={[required, password]}
                parentStyle={styles.inputAcc}
              />

              {errorLocal && (
                <div className={styles.errorMessage}>
                  {errorLocal === 'User not found' ? 'Wrong password' : errorLocal}
                </div>
              )}
            </div>
            <div className={styles.formButtons}>
              <Button type='submit' color='primary' variant='contained'>
                Create an account
              </Button>
            </div>
          </Form>
        ) : (
          <Form onSubmit={(e) => onSubmit(e, timezone)} className={styles.form}>
            <div className={styles.internalWrapper}>
              <h1>{newUser.role === 'Student' ? 'Create student account' : 'Become an instructor'}</h1>

              <h4>First name*</h4>
              <Field
                component={Input}
                name='first_name'
                label='First name'
                type='text'
                maxLength={20}
                validate={[required]}
                parentStyle={styles.inputAcc}
                defaultValue={firstName}
              />
              <h4>Last name*</h4>
              <Field
                component={Input}
                name='last_name'
                label='Last name'
                type='text'
                maxLength={20}
                validate={[required]}
                parentStyle={styles.inputAcc}
                defaultValue={lastName}
              />
              <h4>Password*</h4>
              <Field
                component={Input}
                name='password'
                label='Password'
                type='password'
                validate={[required, password]}
                parentStyle={styles.inputAcc}
              />
              <h4>Timezone</h4>
              <Field
                component={Input}
                name='timezone'
                label='Time zone'
                type='text'
                parentStyle={styles.inputAcc}
                isTimezone
                setZone={(item: string) => setZone(item)}
                maxLength={20}
              />

              {/*   {errors && <div className='error'>{errors}</div>} */}

              <div className={styles.formButtons}>
                <Button type='submit' color='primary' variant='contained'>
                  Create an account
                </Button>
              </div>
            </div>
          </Form>
        )}

        <div className={styles.termsOfUse}>
          <span>
            By logging in or creating an account, you agree with our <Link to='/terms-of-use'>Terms of Use </Link>
            and <Link to='/privacy-policy'>Privacy Policy</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default reduxForm<CreateAccountType>({
  form: 'createAccount',
})(CreateAccount);
