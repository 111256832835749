import { setPassword } from '../redux/actions/authActions';
import { instance } from './api';
import { SignUpType, SignInType } from '../types/auth';

type ConfirmType = {
  confirm_code: string;
  password: string;
  first_name: string;
  last_name: string;
  timezone: string;
  type: string;
};

export const liveClasses = {
  getClasses: async (value?: any) => {
    const { data } = await instance.get(
      `/live-classes`,
      value && { params: value }
    );
    return data;
  },
  getCategories: async () => {
    const { data } = await instance.get(`/categories`);
    return data;
  },
  getLevels: async () => {
    const { data } = await instance.get(`/class-levels`);
    return data;
  },
  filter: async (value: any) => {
    const { data } = await instance.get(`/live-classes`, {
      params: value,
    });

    return data;
  },
  getClass: async (value: any) => {
    const { data } = await instance.get(`/live-classes/${value}`);
    return data;
  },
  getUserClasses: async (value?: any) => {
    const { data } = await instance.get(`/users/live-classes`, {
      params: value,
    });
    return data;
  },
  getCategoriesClasses: async (value?: any) => {
    const { data } = await instance.get(`/live-classes/by-categories`, {
      params: value,
    });
    return data;
  },
  subscribeToClass: async (value: number) => {
    const { data } = await instance.post(`/live-classes/${value}/user-classes`);
    return data;
  },
  unsubscribeToClass: async (value: number) => {
    const { data } = await instance.delete(
      `/live-classes/${value}/user-classes`
    );
    return data;
  },
  cancelInstructorClass: async (value: {
    id: number;
    status: string;
    update_all?: boolean;
  }) => {
    const { data } = await instance.put(`/live-classes/${value.id}`, {
      status: 'Canceled',
      update_all: value.update_all,
    });
    return data;
  },
  editInstructorClass: async (value: number) => {
    const { data } = await instance.put(`/live-classes/${value}`);
    return data;
  },
  scheduleClass: async (value?: unknown) => {
    const { data } = await instance.post(`/live-classes`, value);
    return data;
  },
  updateClass: async (id: number, values: any) => {
    const { data } = await instance.put(`/live-classes/${id}`, values);
    return data;
  },
  editClass: async (value: { id: number; formData: any }) => {
    const { data } = await instance.put(
      `/live-classes/${value.id}`,
      value.formData
    );
    return data;
  },
  editClassPhoto: async (value: { id: number; file: any }) => {
    const newFile = new FormData();
    newFile.append('file', value.file);

    const { data } = await instance.post(
      `/live-classes/${value.id}/photo`,
      newFile
    );
    return data;
  },

  createLiveClass: async (value: any) => {
    const { data } = await instance.post(`/live-classes`, value);
    return data;
  },
  getClassCommission: async () => {
    const { data } = await instance.get(`/live-classes/commission`);
    return data;
  },
  getClassChatMessages: async (classId: number) => {
    const { data } = await instance.get(`/class-messages/${classId}`);
    return data;
  },
  sendClassChatMessage: async (classId: number, values: any) => {
    const { data } = await instance.post(`/class-messages/${classId}`, values);
    return data;
  },

  /*
  there is below the logic for the future realization of the sign uo for liveClasses from LiveClass.tsx

  signUpClass: async (value: any) => {
    const { data } = await instance.get(`/live-classes/${value}`);
    return data;
  }, */
};
