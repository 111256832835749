import React, { useRef, useState, useEffect } from 'react';
import styles from './SideDrawer.module.scss';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { MenuItem, Menu, Checkbox } from '@material-ui/core';
import { ReactComponent as Adjustments } from '../../../assets/svg/adjustments.svg';
import { ReactComponent as Close } from '../../../assets/svg/close.svg';
import queryString from 'query-string';
import { Hidden, SwipeableDrawer } from '@material-ui/core';
import { usePrevious } from '../../../helpers/hooks';

type SideDrawerType = {};

const Filters: React.FC<any> = ({ children }) => {
  /*  const [selectedNumber, setSelectedNumber] = useState(slctNumber);
  const [selectedCats, setCategories] = useState<any>(categories); */
  /*   const prevCategories = usePrevious({ categories });
  const prevNumber = usePrevious({ slctNumber }); */
  const history = useHistory();
  /* 
  useEffect(() => {
    if (prevCategories !== categories) {
      setCategories(categories);
    }
    if (prevNumber !== slctNumber) {
      setSelectedNumber(slctNumber);
    }
  }, [prevCategories, prevNumber]); */

  return <>{children}</>;
};

export const SideDrawer: React.FC<any> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [initChild, setInitChild] = useState(true);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const EndIcon = ({ value }: any) => {
    return (
      <div className={styles.endIconWrapper}>
        <p>{value}</p>
      </div>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setInitChild(false);
    }, 50);
  }, []);

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(anchorRef.current);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    setOpen(false);
  };

  const showTempChildren = ({ children }: any) => {
    return setTimeout(() => {});
  };

  return (
    <div className={styles.categories}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        startIcon={<Adjustments className={styles.startIcon} />}
        // endIcon={+slctNumber > 0 && <EndIcon value={slctNumber} className={styles.endIcon} />}
      >
        <p className={styles.text}>Filters</p>
      </Button>
      <div className={styles.initChild}>{children}</div>
      <Hidden mdUp>
        <SwipeableDrawer
          anchor={'right'}
          open={open}
          onClose={handleClose}
          onOpen={handleClose}
          disableBackdropTransition
          classes={{ root: styles.drawerRoot, paper: styles.drawerPaper }}
        >
          <div className={styles.categoriesHeader}>
            <div>
              <span>Filters</span>
            </div>
            <Close onClick={handleClose} className={styles.closeIcon} />
          </div>
          <Filters>{children}</Filters>
        </SwipeableDrawer>
      </Hidden>
    </div>
  );
};
