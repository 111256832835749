import React, { useState, useEffect } from 'react';
import styles from './AutocompleteInput.module.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  InputBase,
  Checkbox,
  NativeSelect,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  CheckboxProps,
  Chip,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import { Field } from 'redux-form';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { WrappedFieldInputProps } from 'redux-form';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete.svg';
import { Autocomplete } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import { required } from '../../../helpers/validate';
import Input from '../Input/Input';

const SearchInput = ({
  defaultValue,
  label,
  meta,
  input,
  data,
  valueCategories,
  duration,
  onChangeCats,
  deleted,
  isCategories,
}: any) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [valueCats, setValuesCats] = useState([] as Array<any>);

  useEffect(() => {
    valueCategories && setValuesCats(valueCategories);
  }, [valueCategories]);

  useEffect(() => {
    if (deleted) {
      setValuesCats([]);
      input.onChange(0);
    }
  }, [deleted]);

  useEffect(() => {
    if (defaultValue && data) {
      const foundValue = data.find((el: any) => el.duration === defaultValue);

      if (!duration) {
        const elFound = foundValue && valueCats.find((el: any) => el.id === foundValue.id);

        !elFound && foundValue && input.onChange([...valueCats, { id: foundValue.id, name: foundValue.name }]);

        !elFound && foundValue && onChangeCats([...valueCats, { id: foundValue.id, name: foundValue.name }]);
      } else {
        onChangeCats([foundValue]);
        input.onChange(foundValue.id);
      }
    }
  }, []);
  useEffect(() => {
    if (defaultValue && data) {
      const foundValue = data.find((el: any) => el.duration === defaultValue);

      if (!duration) {
        const elFound = foundValue && valueCats.find((el: any) => el.id === foundValue.id);

        !elFound && foundValue && input.onChange([...valueCats, { id: foundValue.id, name: foundValue.name }]);

        !elFound && foundValue && onChangeCats([...valueCats, { id: foundValue.id, name: foundValue.name }]);

        if (isCategories) {
          input.onChange(isCategories);
        }
      } else {
        onChangeCats([foundValue]);
        input.onChange(foundValue.id);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    let isMetaError = meta && meta.touched && !!meta.error;
    setError(!!isMetaError);
    if (meta && meta.error) {
      meta.touched && setErrorMessage(`${meta.error || label}`);
    } else {
      setErrorMessage('');
    }
  }, [meta]);

  return (
    <Autocomplete
      id='searchInput'
      options={data}
      getOptionLabel={(option: any) => option.name}
      onChange={(event: any, newValue: any) => {
        if (!duration) {
          const elFound = newValue && valueCats.find((el: any) => el.id === newValue.id);

          !elFound && newValue && input.onChange([...valueCats, { id: newValue.id, name: newValue.name }]);

          !elFound && newValue && onChangeCats([...valueCats, { id: newValue.id, name: newValue.name }]);
        } else {
          onChangeCats([newValue]);
          input.onChange(newValue && newValue.id ? newValue.id : 0);
        }
      }}
      className={styles.autoWrapper}
      classes={{
        root: styles.inputRoot,
        paper: styles.inputPaper,
        inputRoot: styles.inputWrapper,
        input: styles.inputInput,
        listbox: styles.inputListMenu,
      }}
      value={''}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            placeholder={`${label}`}
            variant='outlined'
            InputLabelProps={{
              classes: {
                root: styles.inputLabel,
                error: styles.error,
              },
              shrink: false,
            }}
            error={error}
            helperText={meta && meta.touched && !meta.active && errorMessage}
          />
        );
      }}
    />
  );
};

const AutocompleteInput = ({
  data,
  title,
  label,
  iSrequired,
  isLevels,
  duration = false,
  defaultValue,
  durationDefValue,
  categoriesDefault,
}: any) => {
  const [valueCats, setValueCats] = useState<Array<{ id: number; name: string }>>(
    [] as Array<{ id: number; name: string }>
  );
  const [durationValue, setDurationValue] = useState('');
  const [deleted, setDeleted] = useState(false);

  const handleDelete = (id: number) => {
    const filteredArr = valueCats.filter((el) => el.id !== id);
    setValueCats(filteredArr);
    filteredArr.length ? setDeleted(false) : setDeleted(true);
  };

  useEffect(() => {
    if (!isLevels && !duration) {
      categoriesDefault && setValueCats(categoriesDefault);
    }
  }, [categoriesDefault]);

  return (
    <div className={styles.wrapper}>
      <h4>{title}</h4>
      <Field
        component={SearchInput}
        name={duration ? 'duration' : isLevels ? 'levels' : 'categories'}
        label={label}
        type='text'
        validate={[required]}
        //  validate={['Required']}
        data={data}
        defaultValue={defaultValue}
        valueCategories={valueCats}
        onChangeCats={(e: any) => {
          setValueCats([...e]);
        }}
        isCategories={categoriesDefault}
        duration={duration}
        deleted={deleted}
      />
      <Paper component='ul' className={`${styles.chipsRoot} ${duration && styles.durationChipsRoot}`}>
        {valueCats.map((el: any) => {
          return (
            el && (
              <li key={el.id}>
                <Chip
                  label={el.name}
                  onDelete={() => handleDelete(el.id)}
                  className={styles.chip}
                  deleteIcon={<DeleteIcon className={styles.deleteIcon} />}
                />
              </li>
            )
          );
        })}
      </Paper>
    </div>
  );
};

export default AutocompleteInput;
