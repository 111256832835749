export const getFilters = () => {
  return JSON.parse(String(localStorage.getItem('liveClassesFilters')));
}

export const updateFiltersStorage = (filters:any) => {
  let liveClassesFilters = {};
  const localStorageData = getFilters();
  if(localStorageData){
    liveClassesFilters = localStorageData;
  }

  liveClassesFilters = { ...liveClassesFilters, ...filters };
  localStorage.setItem('liveClassesFilters', JSON.stringify(liveClassesFilters));
}

export const resetFiltersStorage = () => {
  localStorage.setItem('liveClassesFilters', JSON.stringify({}));
}