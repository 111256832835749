import React, { useState, useEffect, ReactChild } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../common/Button/Button';
import styles from './Modal.module.scss';
import { Modal as ModalMaterial } from '@material-ui/core';
import { StateType } from '../../../redux/store';

type ModalType = {
  open: boolean;
  children: ReactChild;
  onCloseModal: Function;
  customClass?: any;
};

const Modal: React.FC<ModalType> = ({
  open,
  children,
  onCloseModal,
  customClass,
}) => {
  const { balance } = useSelector((state: StateType) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (balance) {
    }
    return () => {};
  }, [open]);

  const handleCloseModal = () => {
    onCloseModal();
  };

  return (
    <ModalMaterial
      open={open}
      onClose={handleCloseModal}
      className={`${styles.modalRoot}`}
    >
      <div className={`${styles.modal} ${customClass} `}>{children}</div>
    </ModalMaterial>
  );
};

export default Modal;
