import React, { useEffect, useState } from 'react';
import styles from './ScheduleClasses.module.scss';
import InformationStep from './InformationStep/InformationStep';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { ReactComponent as ClockIcon } from '../../../../assets/svg/clock.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/svg/filter.svg';
import { ReactComponent as InformationIcon } from '../../../../assets/svg/information.svg';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '../../../common/Button/Button';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/arrowRight.svg';
import { submit } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../redux/store';
import DataStep from './DataStep/DataStep';
import CategoriesStep from './CategoriesStep/CategoriesStep';
import { getCategories, getLevels, getClassCommission } from '../../../../redux/actions/liveClassesActions';
import { setNewClass, resetNewClass } from '../../../../redux/reducers/liveClasses';
import { useHistory } from 'react-router-dom';
import MainLoader from '../../../common/MainLoader/MainLoader';
import moment from 'moment';
import Loader from '../../../common/Loader/Loader';
const stepsArr = ['Information', 'Data', 'Categories'];

const icons: { [index: string]: React.ReactElement } = {
  1: <InformationIcon />,
  2: <ClockIcon />,
  3: <FilterIcon />,
};

const ChevronRight = (props: any) => {
  return (
    <ChevronRightIcon
      classes={{
        root: props.active || props.completed ? styles.activeChevron : styles.inactiveChevron,
      }}
    />
  );
};

const ScheduleClasses: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [disable, setDisable] = useState(false);

  const { newClass, isLoading } = useSelector((state: StateType) => state.liveClasses);
  const { message, type } = useSelector((state: StateType) => state.alert);
  const { user } = useSelector((state: StateType) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const newClassLocal: any = localStorage.getItem('newClass');
  const parsedNewClass = JSON.parse(newClassLocal);

  useEffect(() => {
    if (user.type !== 'Instructor') {
      history.push('/user/home');
    }
    setActiveStep(0);
    dispatch(getCategories(null));
    dispatch(getLevels(null));
    dispatch(getClassCommission(null));
    localStorage.removeItem('newClass');
    localStorage.removeItem('photo');

    return () => {
      localStorage.removeItem('photo');
      localStorage.removeItem('newClass');
      dispatch(resetNewClass());
      setActiveStep(0);
    };
  }, []);

  useEffect(() => {
    isLoading ? setDisable(true) : setDisable(false);
  }, [isLoading]);

  useEffect(() => {
    if (message && type === 'error') {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [message, type]);

  useEffect(() => {
    if (newClass && newClass.information) {
      handleNext();
    }
  }, [newClass]);

  const handleNext = () => {
    activeStep < 2 && setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    activeStep > 0 && setActiveStep(activeStep - 1);
  };

  const handleReset = async () => {
    await localStorage.removeItem('newClass');
    await localStorage.removeItem('photo');
    await dispatch(setNewClass({}));
    await setActiveStep(0);
    await history.push('/user/my-live-classes');
  };

  const formSubmit = () => {
    switch (activeStep) {
      case 0:
        dispatch(submit('scheduleInfo'));
        break;
      case 1:
        dispatch(submit('scheduleData'));
        break;
      case 2:
        dispatch(submit('scheduleCategories'));
        break;

      default:
        break;
    }
  };

  const FormStep = () => {
    switch (activeStep) {
      case 0:
        return <InformationStep />;
      case 1:
        return <DataStep />;
      case 2:
        return <CategoriesStep />;

      default:
        return <InformationStep />;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2>Schedule live class</h2>
        <div className={styles.stepLinks}>
          <Stepper activeStep={activeStep} classes={{ root: styles.stepperRoot }} connector={<ChevronRight />}>
            {stepsArr.map((el, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};

              return (
                <Step
                  key={el}
                  {...stepProps}
                  classes={{
                    root: index === activeStep ? styles.activeStep : styles.inactiveStep,
                    completed: styles.activeStep,
                  }}
                >
                  <StepLabel
                    classes={{
                      active: styles.activeStep,
                      completed: styles.activeStep,
                    }}
                    StepIconComponent={({ active, completed }) => {
                      return (
                        <div className={active || completed ? styles.activeIcon : styles.inactiveIcon}>
                          {icons[index + 1]}
                        </div>
                      );
                    }}
                    {...labelProps}
                  >
                    {el}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
      </div>
      <div className={styles.internalWrapper}>
        <div className={styles.main}>
          <FormStep />
          <div className={styles.buttons}>
            <Button
              color='primary'
              variant='contained'
              onClick={activeStep === 0 ? handleReset : handleBack}
              disabled={disable}
            >
              {activeStep === 0 ? 'Cancel' : 'Back'}
            </Button>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              disabled={disable}
              iconRight={<ArrowRight />}
              iconLeft={isLoading ? <Loader isWhite /> : <></>}
              onClick={(e) => {
                e.preventDefault();
                formSubmit();
              }}
            >
              {activeStep === 2 ? 'Schedule live class' : 'Continue'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleClasses;
