import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Link as MaterialLink } from '@material-ui/core';
import Input from '../../../common/Input/Input';
import Button from '../../../common/Button/Button';
import { signIn, signUp } from '../../../../redux/actions/authActions';
import { StateType } from '../../../../redux/store';
import { required, email } from '../../../../helpers/validate';

import styles from './ConfirmPage.module.scss';
import { setConfirmCode, setError } from '../../../../redux/reducers/auth';

export type ConfirmPageType = {
  confirm_code: string;
};

const ConfirmPage: React.FC<InjectedFormProps<ConfirmPageType>> = ({ handleSubmit }) => {
  const history: any = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { profile } = useSelector((state: StateType) => state.user);
  const { errors, user } = useSelector((state: StateType) => state.auth);
  const { message } = useSelector((state: StateType) => state.alert);
  const [confirmCode, setConfirm] = useState('');
  const [disable, setDisable] = useState(true);
  const [linkDisable, setLinkDisable] = useState(false);
  let localUser = localStorage.getItem('user');
  let parsedUser = localUser && JSON.parse(localUser);
  let query = new URLSearchParams(useLocation().search);
  let status = query.get('status');
  let code = query.get('confirm_code');
  let email = query.get('email');
  let queryId = query.get('id');

  useEffect(() => {
    return () => {
      localStorage.removeItem('user');
    };
  }, []);

  useEffect(() => {
    if (code && status === 'success') {
      setDisable(false);
      handleSubmit(() => {
        code && setConfirm(code);
        code && dispatch(setConfirmCode(code));
      })();
    } else if (status && status !== 'success') {
      dispatch(setError('Try to login'));
      history.push(`/auth/sign-in`);
    }
  }, [code]);

  useEffect(() => {
    if ((!location.state && !status) || (!location.state && status !== 'success')) {
      dispatch(setError('Try to login'));
      history.push(`/auth/sign-in`);
    }
  }, [location]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    handleSubmit(() => {
      code && setConfirm(code);
      code && dispatch(setConfirmCode(code));
    })();
  };
  useEffect(() => {
    return () => {
      dispatch(setError(''));
    };
  }, [errors]);

  useEffect(() => {
    if (confirmCode) {
      confirmCode && history.push(`/auth/role/${queryId}/${email}`);
    }
  }, [confirmCode]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      profile && history.push(`/`);
    }
  }, [profile]);

  const resendHandler = () => {
    if (!linkDisable) {
      const userEmail = (user && user.email) || (parsedUser && parsedUser.email);
      userEmail && userEmail && dispatch(signUp({ email: userEmail }));
      setLinkDisable(true);
      setTimeout(() => setLinkDisable(false), 30000);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Form onSubmit={onSubmit} className={styles.form}>
        <div className={styles.internalWrapper}>
          <h1>Confirm email...</h1>

          <h4>Please check your email to confirm the registration</h4>

          {/*   {errors && <div className='error'>{errors}</div>}
           */}
          <div className={styles.formButtons}>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              onClick={() => {
                disable && history.push(`/auth/sign-in`);
              }}
            >
              Done
            </Button>

            <div>
              Didn't receive the email?
              <MaterialLink
                component='button'
                onClick={() => resendHandler()}
                disabled={linkDisable}
                className={`${linkDisable ? styles.disabledLink : styles.enabledLink}`}
              >
                Send it again
              </MaterialLink>
            </div>
          </div>
        </div>
      </Form>
      <div className={styles.termsOfUse}>
        <span>
          By logging in or creating an account, you agree with our <Link to='/terms-of-use'>Terms of Use </Link>
          and <Link to='/privacy-policy'>Privacy Policy</Link>
        </span>
      </div>
    </div>
  );
};

export default reduxForm<ConfirmPageType>({
  form: 'confirm',
})(ConfirmPage);
