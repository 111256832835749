import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form, submit } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';
import { StateType } from '../../../../../redux/store';
import styles from './CategoriesStep.module.scss';
import { getCategories, createLiveClass } from '../../../../../redux/actions/liveClassesActions';
import AutocompleteInput from '../../../../common/AutocompleteInput/AutocompleteInput';
import MainLoader from '../../../../common/MainLoader/MainLoader';
import { setNewClass, CategoryLevelType } from '../../../../../redux/reducers/liveClasses';
import { alertShowThunk } from '../../../../../redux/reducers/alert';

export type CategoriesStepType = {
  categories: Array<number>;
};

const CategoriesStep: React.FC<InjectedFormProps<CategoriesStepType>> = ({ handleSubmit }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { categories, newClass } = useSelector((state: StateType) => state.liveClasses);
  const { message, type } = useSelector((state: StateType) => state.alert);
  const [valueCats, setValueCats] = useState<Array<{ id: number; name: string }>>(
    [] as Array<{ id: number; name: string }>
  );

  const localNewClass = localStorage.getItem('newClass');

  useEffect(() => {
    if (newClass?.categories?.length) {
      setValueCats(newClass.categories);
    }
  }, [newClass]);

  useEffect(() => {
    /* message && setLoading(false);
    message && history.push('/user/my-live-classes');
    message && dispatch(setNewClass({})); */
    if (type && message) {
      dispatch(alertShowThunk(type, message, type === 'error' ? Number(0) : 5000));
      type === 'success' && history.push('/user/my-live-classes');
    }
  }, [message, type]);

  const onSubmit = () => {
    // e.preventDefault();

    handleSubmit((value) => {
      const reincarnateCats = (arr: Array<any>) => {
        return arr.map((el: any) => {
          return el.id;
        });
      };
      const reincarnatedArr = value?.categories
        ? reincarnateCats(value?.categories)
        : reincarnateCats(newClass.categories);

      const finalValue = {
        ...newClass,
        categories: reincarnatedArr,
      };

      const levels = Array.from(
        new Set(
          finalValue.information.levels.map((el: any) => {
            return el.id;
          })
        )
      );
      const frequency = finalValue.data.oneTime ? 'One Time' : 'Recurring';
      const weekdays =
        finalValue.data.weekdays &&
        finalValue.data.weekdays.map((el: any) => {
          return el.name;
        });
      const weekdaysObj = weekdays && {
        weekdays: weekdays,
      };
      const end_date = !finalValue.data.oneTime &&
        finalValue.data.endDate && {
          end_date: finalValue.data.endDate,
        };
      const newObj = {
        title: finalValue.information.title,
        description: finalValue.information.description,
        photo: finalValue.information.photo,
        levels: levels,
        frequency: frequency,
        price: finalValue.data.price,
        start_time: finalValue.data.time,
        start_date: finalValue.data.startDate,
        duration: finalValue.data.duration,
        categories: reincarnatedArr,
        ...end_date,
        ...weekdaysObj,
      };
      dispatch(createLiveClass(newObj));
      setLoading(true);
      dispatch(setNewClass({ ...newClass, categories: value?.categories ?? newClass.categories }));
    })();
  };

  const handleDelete = (id: number) => {
    const filteredArr = valueCats.filter((el) => el.id !== id);
    setValueCats(filteredArr);
  };

  return (
    <Form onSubmit={onSubmit} className={styles.form}>
      <MainLoader loading={loading} />
      <div className={styles.inputs}>
        <AutocompleteInput
          title={'Categories *'}
          data={categories}
          categoriesDefault={newClass.categories}
          defaultValue={newClass.categories}
          label={'Add keywords to your live class to help students to find your live class easily'}
        />
      </div>
    </Form>
  );
};

export default reduxForm<CategoriesStepType>({
  form: 'scheduleCategories',
})(CategoriesStep);
