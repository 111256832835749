import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import SignInPage from './SignInPage/SignInPage';
import ConfirmPage from './ConfirmPage/ConfirmPage';
import SignUpPage from './SignUpPage/SignUpPage';
import ForgotPasswordPage from './ForgotPasswordPage/ForgotPasswordPage';
import styles from './AuthPage.module.scss';
import AuthLayout from '../../layout/AuthLayout/AuthLayout';
import SelectRole from './SelectRole/SelectRole';
import CreateAccount from './CreateAccount/CreateAccount';
import { useHistory } from 'react-router-dom';
import Stripe from './Stripe/Stripe';
import MainLayout from '../../layout/MainLayout/MainLayout';
import ErrorPage from '../ErrorPages/ErrorPage';

const AuthPage: React.FC = (props: any) => {
  const history = useHistory();

  const authRoutes = [
    'sign-in',
    'sign-up',
    'confirm',
    'role',
    'create-account',
    'reset-password',
    'stripe',
  ];

  useEffect(() => {
    if (props.match.params && props.match.params.type) {
      const matched = authRoutes.find(
        (el: any) => el === props.match.params.type
      );

      !matched && history.push('/page-not-found');
      if (
        matched === 'role' &&
        (!props.match.params.id || !props.match.params.email)
      ) {
        history.push('/page-not-found');
      }
    }
  }, [props.match]);

  const typeFromRoute = () => {
    const matched = authRoutes.find(
      (el: any) => el === props.match.params.type
    );

    if (matched && matched === 'reset-password') {
      return 'forgot';
    } else if (matched) {
      return matched;
    } else {
      return '';
    }
  };
  return (
    <>
      <AuthLayout type={typeFromRoute()}>
        <Route path='/auth/sign-in' exact render={() => <SignInPage />} />

        <Route path='/auth/sign-up' exact render={() => <SignUpPage />} />

        <Route path='/auth/confirm' exact render={() => <ConfirmPage />} />
        <Route
          path='/auth/role/:id/:email'
          exact
          render={() => <SelectRole />}
        />
        <Route
          path='/auth/create-account'
          exact
          render={() => <CreateAccount />}
        />

        <Route
          path='/auth/reset-password'
          exact
          render={() => <ForgotPasswordPage />}
        />
        <Route path='/auth/stripe' exact render={() => <Stripe />} />
        {/*  <Route
        path='*'
        render={() => (
          <MainLayout>
            <ErrorPage />
          </MainLayout>
        )}
      /> */}
      </AuthLayout>
    </>
  );
};

export default AuthPage;
