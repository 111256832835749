import React, { useState, useEffect } from 'react';
import styles from './LoadMore.module.scss';
import Button from '../../../../common/Button/Button';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { getListOfUsers } from '../../../../../redux/actions/userActions';
import { filterClasses } from '../../../../../redux/actions/liveClassesActions';
import { liveClasses } from '../../../../../api/liveClasses';
import { StateType } from '../../../../../redux/store';

const filterInitialValues = {
  status: [],
  // rating: [0, 5],
  categories: [],
  start_date: '',
  start_time: '',
  end_date: '',
  end_time: '',
  price: [0, 1000],
  levels: [],
  limit: 5,
  offset: 0,
};

const LoadMore = ({ users, isLiveClasses }: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [filterObj, setFilterObj] = useState<any>({
    filter: filterInitialValues,
    limit: isLiveClasses ? 5 : 12,
    offset: 0,
  });
  const { disableLoadmore } = useSelector((state: StateType) => state.liveClasses);
  const [disableButton, setDisableButton] = useState(false);

  const queryStr = (obj: any) => {
    const str = queryString.stringify(obj, { arrayFormat: 'bracket' });
    isLiveClasses ? history.replace(`live-classes?${str}`) : history.replace(`instructors-list?${str}`);
  };

  const [offset, setOffset] = useState(0);

  let query = queryString.parse(useLocation().search, {
    arrayFormat: 'bracket',
  }) as {
    limit: string;
    offset: string;
  };

  useEffect(() => {
    if (users && !isLiveClasses) {
      users.length % 12 ? setDisableButton(true) : setDisableButton(false);
    }
  }, [users]);

  useEffect(() => {
    if (isLiveClasses) {
      disableLoadmore ? setDisableButton(disableLoadmore) : setDisableButton(false);
    }
  }, [disableLoadmore]);

  useEffect(() => {
    if (+query?.offset && !isLiveClasses) {
      dispatch(
        getListOfUsers({
          filter: {
            type: 'Instructor',
            status: ['Active'],
          },
          limit: 12,
          offset: +query?.offset,
        })
      )
        //@ts-ignore
        .then((res) => {
          res.payload.data.length < 12 ? setDisableButton(true) : setDisableButton(false);
          setOffset(offset + 12);
        });
    } else if (!isLiveClasses) {
      !users.length &&
        dispatch(
          getListOfUsers({
            filter: {
              type: 'Instructor',
              status: ['Active'],
            },
            limit: 12,
          })
        );
    }
  }, [location]);

  const loadMore = () => {
    /*   dispatch(
      getListOfUsers({
        filter: { type: 'Instructor' },
        limit: 12,
        offset: offset,
      })
    ); */

    if (isLiveClasses) {
      queryStr({
        ...query,
        offset: !query.offset ? 5 : Number(query.offset) + 5,
        limit: 5,
      });
    } else {
      queryStr({
        ...filterObj,
        offset: offset + 12,
      });
    }
  };

  return (
    <div className={`${styles.loadMoreButton} ${disableButton ? styles.hideButton : ''}`}>
      <Button
        cancel
        onClick={(e: any) => {
          e.preventDefault();
          loadMore();
        }}
        customClass={styles.loadMoreButton}
        disabled={disableButton}
      >
        Load more
      </Button>
    </div>
  );
};

export default LoadMore;
