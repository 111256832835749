import React, { useEffect, useState, ChangeEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';
import Input from '../../../../common/Input/Input';
import { StateType } from '../../../../../redux/store';
import {
  required,
  email,
  password,
  passwordsCheck,
} from '../../../../../helpers/validate';
import styles from './PasswordTab.module.scss';
import { setNewClass } from '../../../../../redux/reducers/liveClasses';
import Checkbox from '@material-ui/core/Checkbox';
import SelectableCheckbox from '../../../../common/SelectableCheckbox/SelectableCheckbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import AutocompleteInput from '../../../../common/AutocompleteInput/AutocompleteInput';
import { updatePassword } from '../../../../../redux/actions/userActions';
import PersonIcon from '@material-ui/icons/Person';
import Button from '../../../../common/Button/Button';
import UserPhoto from '../UserPhoto/UserPhoto';

export type PasswordTabType = {
  old_password: string;
  password: string;
  confirm_password: string;
};

const PasswordTab: React.FC<InjectedFormProps<PasswordTabType>> = ({
  handleSubmit,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state: StateType) => state.user);

  const [recurring, setRecurring] = useState(false);

  const localNewClass = localStorage.getItem('newClass');

  const onSubmit = (e: any) => {
    e && e.preventDefault();
    const parsedNewClass = localNewClass
      ? JSON.parse(localNewClass as string)
      : {};

    handleSubmit((value) => {
      dispatch(
        updatePassword({
          old_password: value.old_password,
          new_password: value.password,
        })
      );
    })();
  };

  return (
    <Form onSubmit={onSubmit} className={styles.form}>
      <div className={styles.inputs}>
        <h3>Current password</h3>
        <Field
          component={Input}
          name='old_password'
          label='Current password'
          type='password'
          validate={[required]}
        />
        <h3>New password</h3>
        <Field
          component={Input}
          name='password'
          label='New password'
          type='password'
          validate={[required, password]}
        />
        <h3>Confirm new password</h3>
        <Field
          component={Input}
          name='confirm_password'
          label='Confirm new password'
          type='password'
          validate={[required, passwordsCheck]}
        />
      </div>

      {/*    {errors && <div className='error'>{errors}</div>} */}
    </Form>
  );
};

export default reduxForm<PasswordTabType>({
  form: 'passwordTab',
})(PasswordTab);
