import React, { useEffect, useState } from 'react';
import styles from './Settings.module.scss';
import ProfileTab from './ProfileTab/ProfileTab';
import Stepper from '@material-ui/core/Stepper';
import { Step, Hidden } from '@material-ui/core';
import StepLabel from '@material-ui/core/StepLabel';
import { ReactComponent as ClockIcon } from '../../../../assets/svg/clock.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/svg/filter.svg';
import { ReactComponent as InformationIcon } from '../../../../assets/svg/information.svg';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '../../../common/Button/Button';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/arrowRight.svg';
import { ReactComponent as Profile } from '../../../../assets/svg/liveClasses/user.svg';
import { ReactComponent as Notifications } from '../../../../assets/svg/notification.svg';
import { ReactComponent as Password } from '../../../../assets/svg/lock.svg';
import { ReactComponent as Wallet } from '../../../../assets/svg/walletSettings.svg';
import { submit } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../redux/store';
import PasswordTab from './PasswordTab/PasswordTab';
import NotificationsTab from './NotificationsTab/NotificationsTab';
import { IconButton, Modal, Tab, Tabs, Slider } from '@material-ui/core';
import { getCategories, getLevels } from '../../../../redux/actions/liveClassesActions';
import { setNewClass } from '../../../../redux/reducers/liveClasses';
import { useHistory } from 'react-router-dom';

import { TabPanel } from '../Stream/VideoLesson/VideoLesson';
import MainLoader from '../../../common/MainLoader/MainLoader';
import { alertShowThunk } from '../../../../redux/reducers/alert';
import { getSpecificUser, getNotifications } from '../../../../redux/actions/userActions';
import { setSettingsStep } from '../../../../redux/reducers/user';
import UserPhoto from './UserPhoto/UserPhoto';

const stepsArr = ['Information', 'Data', 'Categories'];

const icons: { [index: string]: React.ReactElement } = {
  1: <InformationIcon />,
  2: <ClockIcon />,
  3: <FilterIcon />,
};

const ChevronRight = (props: any) => {
  return (
    <ChevronRightIcon
      classes={{
        root: props.active || props.completed ? styles.activeChevron : styles.inactiveChevron,
      }}
    />
  );
};

const Settings: React.FC = () => {
  const { message, type } = useSelector((state: StateType) => state.alert);
  const { balance, user, notifications, settingsStep } = useSelector((state: StateType) => state.user);
  const [tabsValue, setTabsValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setLoading(false);
    if (notifications && !notifications.id) {
      dispatch(getNotifications());
    }

    return () => {
      /*   setTabsValue(0);
      dispatch(setSettingsStep(0)); */
    };
  }, []);

  useEffect(() => {
    if (notifications && !notifications.id) {
      dispatch(getNotifications());
    }
  }, [notifications]);

  useEffect(() => {
    if (message) {
      dispatch(alertShowThunk(type, message));
    }
  }, [message]);

  const TabStep = () => {
    switch (tabsValue) {
      case 0:
        return <ProfileTab />;
      case 1:
        return <PasswordTab />;
      case 2:
        return <NotificationsTab />;

      default:
        return <ProfileTab />;
    }
  };
  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabsValue(newValue);
  };
  return (
    <div className={styles.wrapper}>
      <MainLoader loading={loading} />
      <div className={styles.header}>
        <Hidden smDown>
          <h1>Settings</h1>
        </Hidden>
        <div className={styles.stepLinks}>
          <div className={styles.tabsWrapper}>
            <Tabs
              value={tabsValue}
              onChange={handleChangeTabs}
              variant='fullWidth'
              indicatorColor='primary'
              textColor='primary'
              aria-label='icon tabs example'
              classes={{ root: styles.tabsRoot }}
              TabIndicatorProps={{ className: styles.indicatorRoot }}
            >
              <Tab
                classes={{ root: styles.tabRoot, selected: styles.selectedTab }}
                icon={<Profile />}
                aria-label='profile'
                id={`scrollable-auto-tab-${0}`}
                aria-controls={`scrollable-auto-tabpanel-${0}`}
                disableRipple
                label='Profile'
              />
              <Tab
                classes={{
                  root: `${styles.tabRoot}`,
                  selected: styles.selectedTab,
                }}
                icon={<Password />}
                aria-label='password'
                id={`scrollable-auto-tab-${1}`}
                aria-controls={`scrollable-auto-tabpanel-${2}`}
                disableRipple
                label='Password'
              />
              <Tab
                classes={{ root: styles.tabRoot, selected: styles.selectedTab }}
                icon={<Notifications />}
                aria-label='notifications'
                id={`scrollable-auto-tab-${3}`}
                aria-controls={`scrollable-auto-tabpanel-${3}`}
                disableRipple
                label='Notifications'
              />
            </Tabs>
          </div>
        </div>
        <div className={styles.balance}>
          <Hidden mdUp>
            <h1>Settings</h1>
          </Hidden>
          <div>
            <Wallet /> Balance:<span>${balance}</span>
          </div>
        </div>
      </div>
      <UserPhoto isFileUpload={tabsValue === 0 ? true : false} user={user} currTab={tabsValue} />
      <div className={styles.internalWrapper}>
        <div className={styles.main}>
          <TabPanel value={tabsValue} index={tabsValue}>
            <TabStep />
          </TabPanel>

          <div className={styles.buttons}>
            {/*     <Button
              color='primary'
              variant='contained'
              onClick={() => console.log('cancel button')}
            >
              Cancel
            </Button> */}
            {/*  <Button
              type='submit'
              color='primary'
              variant='contained'
              onClick={() => {
                formSubmit();
              }}
            >
              Save
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
