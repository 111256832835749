import React, { useState, useEffect } from "react";
import styles from "./Home.module.scss";
import { useLocation } from "react-router-dom";
import SearchBar from "../SearchBar/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../../redux/store";
import {
  getCategories,
  filterClasses,
  getCategoriesClasses,
} from "../../../../redux/actions/liveClassesActions";
import {
  updateLiveClasses,
  resetClasses,
} from "../../../../redux/reducers/liveClasses";
import ClassesList from "../ClassesList/ClassesList";
import { Echo } from "../../../../index";
import queryString from "query-string";
import { Categories } from "./Categories/Categories";

const Home: React.FC = () => {
  const { liveClasses, categories, categoriesClasses } = useSelector(
    (state: StateType) => state.liveClasses
  );
  const [selectedCats, setCategories] = useState<any>(categories);
  const [runningClasses, setRunningClasses] = useState<any>([]);
  const [upcomingClasses, setUpcomingClasses] = useState<any>([]);

  const [selectedNumber, setSelectedNumber] = useState(0);

  const dispatch = useDispatch();
  const location = useLocation();

  const query = queryString.parse(useLocation().search, {
    arrayFormat: "bracket",
  });

  useEffect(() => {
    localStorage.removeItem("password");
    dispatch(getCategories(null));
    return () => {
      dispatch(resetClasses());
    };
  }, []);

  useEffect(() => {
    const modifiedArr =
      categories &&
      categories.map((el: any) => {
        // @ts-ignore
        return {
          ...el,
          isSelected: Boolean(
            query.categories &&
              // @ts-ignore
              query.categories.find((element) => {
                return Number(element) === Number(el.id);
              })
          ),
        };
      });
    const arrWithAll = modifiedArr.find((el) => !el.isSelected);
    setCategories([
      {
        id: 999,
        name: "All categories",
        photo: "all",
        isSelected: !arrWithAll ? true : false,
      },
      ...modifiedArr,
    ]);
    setSelectedNumber(Number(query.categories && query.categories.length));
  }, [categories, location]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    query && dispatch(filterClasses({ filter: { ...query, order: "rand" } }));

    if (token) {
      dispatch(getCategoriesClasses(null));
    }
  }, [location]);

  useEffect(() => {
    if (liveClasses) {
      const runningClasses = liveClasses.filter(
        (el: any) => el.status === "Running"
      );
      const upcomingClasses = liveClasses.filter(
        (el: any) => el.status === "Upcoming"
      );

      setRunningClasses([...runningClasses]);
      setUpcomingClasses([...upcomingClasses]);
    }
  }, [liveClasses]);

  useEffect(() => {
    Echo.channel("LiveClasses").listen("LiveClassUpdated", (e: any) => {
      dispatch(updateLiveClasses(e.liveClass));
    });
    return () => {
      Echo.channel("LiveClasses").stopListening("LiveClassUpdated");
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchWrapper}>
        <div className={styles.filter}>
          <Categories categories={selectedCats} slctNumber={selectedNumber} />
          <SearchBar />
        </div>
      </div>

      <div className={styles.contentWrapper}>
        {/* {liveClasses && <ClassesList arr={liveClasses} type={'recommended'} />} */}
        {Boolean(runningClasses.length) && (
          <ClassesList arr={runningClasses} type={"running"} />
        )}
        {liveClasses && (
          <ClassesList
            arr={upcomingClasses ? upcomingClasses : liveClasses}
            type={"recommended"}
          />
        )}
        {categoriesClasses &&
          categoriesClasses.map((el: any, index: number) => {
            return (
              <ClassesList
                arr={el.liveClasses}
                totalCount={el.liveClassesCount}
                title={el.name}
                sectionId={el.id}
                icon={el.photo}
                type={"category"}
                key={`${index}`}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Home;
