import { createAsyncThunk } from '@reduxjs/toolkit';

import { SignUpType, SignInType } from '../../types/auth';
import { auth } from '../../api/auth';
import { debug } from 'console';

type DataType = {
  confirm_code: number;
  direct_message: string;
  email: string;
  id: number;
  status: string;
};

type UpdateUserType = {
  id: number;
  email: string;
  password?: string;
  first_name?: string;
  last_name?: string;
  about_me?: string;
  phone_number?: number;
  photo?: string;
  is_agree_terms?: number;
  google_id?: string;
  twitter_id?: string;
  timezone?: string;
  direct_message?: string;
  status?: string;
};

type updateUserType = {};

export const signUp = createAsyncThunk(
  'auth/sign-up',
  async (values: SignUpType | {}, thunkAPI) => {
    try {
      const { data } = await auth.signUp(values);
      return data;
    } catch (err) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('newUser');
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const signIn = createAsyncThunk(
  '/auth/sign-in',
  async (values: any | {}, thunkAPI) => {
    try {
      const token = await auth.signIn(values);
      return token;
    } catch (err) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('newUser');
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  'auth/update',
  async (values: UpdateUserType | {}, thunkAPI) => {
    try {
      const { data } = await auth.update(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const confirmUser = createAsyncThunk(
  'auth/create-account',
  async (values: UpdateUserType | {}, thunkAPI) => {
    try {
      const { data } = await auth.confirm(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const resendConfirm = createAsyncThunk(
  'auth/confirm',
  async (values: UpdateUserType | {}, thunkAPI) => {
    try {
      const { data } = await auth.resendConfirm(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/forgotReset',
  async (values: UpdateUserType | {}, thunkAPI) => {
    try {
      const data = await auth.resetPassword(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const setPassword = createAsyncThunk(
  'auth/forgotSet',
  async (values: UpdateUserType | {}, thunkAPI) => {
    try {
      const data = await auth.setPassword(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const setUserType = createAsyncThunk(
  'auth/userType',
  async (values: UpdateUserType | {}, thunkAPI) => {
    try {
      const { data } = await auth.setUserType(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const switchType = createAsyncThunk(
  'auth/switchType',
  async (values: UpdateUserType | {}, thunkAPI) => {
    try {
      const data = await auth.switchType(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const googleAuth = createAsyncThunk(
  'auth/googleAuth',
  async (values: { code: string; sign_up: boolean }, thunkAPI) => {
    try {
      const { data } = await auth.googleAuth(values);
      return data;
    } catch (err) {
      localStorage.clear();
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
