import React, { useEffect, ReactChild } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Header from '../../components/Header/Header';
import Content from '../Content';
import Footer from '../../components/Footer/Footer';
import { getProfile, getBalance } from '../../../redux/actions/userActions';
import { StateType } from '../../../redux/store';

import styles from './MainLayout.module.scss';

type MainLayoutType = {
  children: ReactChild | JSX.Element | React.ReactNode;
  type?: string;
};

const MainLayout: React.FC<MainLayoutType> = ({ children, type }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const { user } = useSelector((state: StateType) => state.user);
  const token = localStorage.getItem('token');

  /*   useEffect(() => {
    if (
      !profile &&
      !user &&
      !(
        location.pathname.includes('login') ||
        location.pathname.includes('sign-up') ||
        location.pathname.includes('forgot-password')
      )
    ) {
      history.push('/');
    }
  }, [profile]); */

  return (
    <div className={`${styles.mainLayout} ${type ? styles[type] : ''}`}>
      <Header logoIsDark />
      <Content>{children}</Content>
      <Footer />
    </div>
  );
};

export default MainLayout;
