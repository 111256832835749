import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form/lib/Field';
import { TextField, TextFieldProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import SelectTimezoneMaterialUi from 'select-timezone-material-ui';
import SearchIcon from '@material-ui/icons/Search';

import styles from './Input.module.scss';

type CustomInputTypes = {
  input?: WrappedFieldInputProps;
  meta?: WrappedFieldMetaProps;
  defaultValue?: string | number;
  initialValue?: string | number;
  label?: string;
  backendError?: string;
  handleChange?: (value: string) => void;
  parentStyle?: Object;
  maxLength?: number;
  isTimezone?: boolean;
  setZone?: Function;
  isSearch?: boolean;
  isMultiline?: boolean;
  emptyFields?: boolean;
  rows?: number;
  onClick?: Function;
  iconStart?: any;
  balanceValue?: boolean;
  zero?: boolean;
  clearValue?: boolean;
};

const customStyles = (theme: any) => ({
  root: {
    flexGrow: 1,
    width: '100%',

    backgroundColor: theme.palette.background.paper,
    color: '#000',
    '&$focused $notchedOutline': {
      borderColor: '#4A90E2',
      borderWidth: 1,
    },
  },
  tabsIndicator: {
    backgroundColor: '#61dafb',
  },
  tabRoot: {
    '&:hover': {
      color: '#61dafb',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#61dafb',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#61dafb',
    },
  },
  tabSelected: {},
  helperText: {
    color: 'red',
  },
  outlined: {
    transform: 'translate(14px, 14px) scale(1)',
  },
  error: {
    color: '#f7542e',
  },
  control: {
    paddingLeft: '10px',
  },
});

const Input: React.FC<TextFieldProps & CustomInputTypes> = ({
  required,
  id,
  label,
  defaultValue,
  placeholder,
  name,
  type = 'text',
  classes,
  input,
  meta,
  backendError,
  handleChange,
  parentStyle,
  maxLength,
  isTimezone,
  setZone,
  isSearch,
  isMultiline,
  emptyFields,
  rows,
  onClick,
  iconStart,
  balanceValue,
  zero,
  clearValue,
}) => {
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });
  const [value, setValue] = useState('');
  const [timezone, setTimezone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [labelOff, setLabelOff] = useState(false);
  const [timezoneError, setTimezoneError] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (clearValue) {
      setValue('');
    }
  }, [clearValue]);

  useEffect(() => {
    if (isTimezone) {
      defaultValue && setValue(defaultValue as string);
    } else {
      defaultValue && setValue(defaultValue as string);
    }
  }, [defaultValue]);

  useEffect(() => {
    let isMetaError = meta && meta.touched && (!!meta.error || !!backendError);
    setError(!!isMetaError);
    if (meta && meta.error) {
      meta.touched && setErrorMessage(`${meta.error || label}`);
      isTimezone && meta.touched ? setTimezoneError(true) : setTimezoneError(false);
    } else {
      setErrorMessage('');
    }
  }, [meta]);

  useEffect(() => {
    handleChange && handleChange(value as string);
    input && input.onChange(value);
  }, [value]);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handlerOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    let valWithZero = zero ? val.match(/^[0-9]\d*$/) : val.match(/^[1-9]\d*$/);
    if (balanceValue && val && val.length < 5 && valWithZero) {
      +val >= 0 && setValue(`${+val}`);
    } else if (!balanceValue) {
      /*   let newVal = val.replace(/  /g, ' &nbsp;');
      console.log('val', newVal); */
      setValue(val);
    } else if (balanceValue && val.length === 0) {
      setValue('');
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (timezone) {
      setZone && setZone(timezone);
    }
  }, [timezone]);

  let inputTimeZone = isTimezone && document.getElementsByTagName('input')[2]?.value;

  useEffect(() => {
    if (isTimezone) {
      document.getElementsByTagName('input')[2].maxLength = 20;

      if (maxLength) {
        document.getElementsByTagName('input')[3].maxLength = 20;
        document.getElementsByTagName('input')[3].value ? setLabelOff(true) : setLabelOff(false);
      }
    }
  }, [isTimezone]);

  useEffect(() => {
    inputTimeZone ? setLabelOff(true) : setLabelOff(false);
  }, [inputTimeZone]);

  let currDate = new Date().toISOString().slice(0, 10);

  let minDateObj = type === 'date' ? { min: currDate } : {};

  return (
    <div
      className={`${styles.wrapper} ${parentStyle && parentStyle} ${isTimezone && styles.timezoneWrapper} ${
        timezoneError && styles.errorWrapper
      }`}
    >
      {isTimezone ? (
        <SelectTimezoneMaterialUi
          label={timezone ? timezone : `${defaultValue ? defaultValue : ''}`}
          FormHelperTextProps={{
            classes: {
              root: styles.error,
              error: styles.error,
              focused: styles.focusedHelper,
            },
          }}
          helperText={meta && meta.touched && meta.active ? `${errorMessage}` : ''}
          onChange={(e) => {
            input && input.onChange(`${e}`);
            setTimezone(`${e}`);
          }}
          showTimezoneOffset
          InputLabelProps={{
            classes: {
              root: styles.inputLabelTimezone,
              error: styles.error,
              shrink: styles.focusedHelper,
            },

            shrink: false,
          }}
        />
      ) : (
        <TextField
          autoComplete={'off'}
          id={id}
          name={name}
          label={''}
          type={type === 'password' ? (values.showPassword ? 'text' : 'password') : type}
          onKeyPress={(e: any) => {
            if (balanceValue) {
              if (e.charCode >= 48 && e.charCode <= 57) {
                return true;
              } else if (balanceValue) {
                e.preventDefault();
              }
            }
          }}
          placeholder={input && input.value ? '' : label}
          className={styles.input}
          required={required}
          classes={{ root: classes && classes.root }}
          variant='outlined'
          multiline={isMultiline ? true : false}
          rows={rows ? rows : 1}
          InputLabelProps={{
            classes: {
              root: styles.inputLabel,
              error: styles.error,
            },
            shrink: false,
          }}
          {...input}
          onChange={handlerOnChange}
          defaultValue={defaultValue}
          value={value}
          inputRef={ref}
          error={emptyFields ? emptyFields : error}
          helperText={meta && meta.touched && !meta.active && errorMessage}
          InputProps={{
            endAdornment: type === 'password' && (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {values.showPassword ? <Visibility style={{ color: '#9300ff' }} /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment:
              isSearch || iconStart ? (
                <InputAdornment classes={{ root: styles.searchIcon }} position='start'>
                  {iconStart ? iconStart : <SearchIcon />}
                </InputAdornment>
              ) : (
                ''
              ),
            classes: {
              root: styles.inputProps,
              error: styles.errorInput,
            },
          }}
          inputProps={{
            maxLength: maxLength ? maxLength : 65,
            defaultValue: defaultValue,
            ...minDateObj,
          }}
          FormHelperTextProps={{
            classes: {
              error: styles.error,
            },
          }}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default withStyles(customStyles)(Input);
