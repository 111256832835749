import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form, submit } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';
import Input from '../../../../common/Input/Input';
import { required, email, password } from '../../../../../helpers/validate';
import styles from './EmailForm.module.scss';
import { ReactComponent as Stripe } from '../../../../../assets/svg/stripeLogo.svg';
import { Link as MaterialLink } from '@material-ui/core';
import { StateType } from '../../../../../redux/store';
import Button from '../../../../common/Button/Button';

export type EmailFormType = {
  email: string;
};

const EmailForm: React.FC<InjectedFormProps<EmailFormType>> = ({
  handleSubmit,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state: StateType) => state.user);

  const onSubmit = (e: any) => {
    handleSubmit((value) => {
      // dispatch();
    })();
  };

  return (
    <Form onSubmit={onSubmit} className={styles.form}>
      <div className={styles.logo}>
        <Stripe />

        <a href={`${user.stripe_account?.login_link}`} target='_blank'>
          View stripe account
        </a>
      </div>
      {/* <div className={styles.inputs}>
        <div>
          <h4>Payment email address:</h4>
          <p>All withdrawals will be sent to your Stripe account</p>
          <Field
            component={Input}
            name='email'
            label='clever@crew.demo'
            type='email'
            validate={[email]}
          />
        </div>
        <Button
          children={'Save'}
          onClick={() => console.log('save')}
          disabled={true}
        />
       
      </div> */}
    </Form>
  );
};

export default reduxForm<EmailFormType>({
  form: 'stripeEmail',
})(EmailForm);
