import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form, submit } from 'redux-form';
import { useHistory, Link, useLocation } from 'react-router-dom';
import styles from './ReplenishForm.module.scss';
import Button from '../../../../common/Button/Button';
import { loadStripe } from '@stripe/stripe-js';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { replenishBalance, getBalance } from '../../../../../redux/actions/userActions';
import { StateType } from '../../../../../redux/store';
import SelectedInput from '../../../../common/SelectedInput/SelectedInput';
import StripeForm from '../StripeForm/StripeForm';
import Modal from '../../../../components/Modal/Modal';
import moment from 'moment';
import { alertShowThunk } from '../../../../../redux/reducers/alert';
import { ReactComponent as Coins } from '../../../../../assets/svg/Coins.svg';

export type ReplenishFormType = {
  balance?: number;
};
type Props = {
  minValue?: number;
};

type AddProps = InjectedFormProps<ReplenishFormType> & Props;
const priceArr = [
  { id: 0, name: '10' },
  { id: 1, name: '20' },
  { id: 2, name: '50' },
  { id: 3, name: '100' },
];
const ReplenishForm: React.FC<AddProps> = ({ handleSubmit, minValue }) => {
  const { user } = useSelector((state: StateType) => state.user);
  const [currValue, setCurrValue] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (user.stripe_customer) {
      const expireMonth = user.stripe_customer.exp_month;
      const expireYear = user.stripe_customer.exp_year;
      const expireDate = moment(`${expireMonth}/${expireYear}`, 'M/YYYY').toDate();
      const now = moment();
      const month = now.format('M');
      const year = now.format('YYYY');
      const currentDate = moment(`${month}/${year}`, 'M/YYYY').toDate();

      if (+expireDate === +currentDate) {
        dispatch(alertShowThunk('warning', 'Your card is expiring in current month!', 10000));
      } else if (+expireDate < +currentDate) {
        setDisable(true);
        dispatch(alertShowThunk('error', 'Your card is expired!', 10000));
      }
    }
  }, [user]);

  useEffect(() => {
    if (location.pathname === '/user/wallet') {
      setShowModal(true);
    }
  }, [location]);

  useEffect(() => {
    if (minValue) {
      minValue > currValue && setDisable(true);
    }
  }, [minValue]);

  useEffect(() => {
    user.stripe_customer && setOpenModal(false);
  }, [user]);

  const onSubmit = (e: any) => {
    e && e.preventDefault();

    if (user.stripe_customer) {
      handleSubmit((value: any) => {
        let balanceValue = value && value.balance ? +value.balance : 10;
        balanceValue >= 10 &&
          //@ts-ignore
          dispatch(replenishBalance(balanceValue)).then(
            (res: any) => res && res?.payload.success && dispatch(getBalance())
          );
      })();
    } else {
      setOpenModal(true);
    }
  };

  return (
    <>
      {!user.stripe_customer && !showModal ? (
        <StripeForm />
      ) : (
        <>
          <Modal open={openModal} onCloseModal={() => setOpenModal(false)}>
            <div className={styles.stripeModal}>
              <div className={styles.infoModal}>
                <Coins className={styles.coins} />
                <h2>Add your card to Stripe account</h2>
                <p>Your account will be replenish on {currValue}</p>
              </div>

              <StripeForm balance={currValue} />
            </div>
          </Modal>
          <Form onSubmit={onSubmit} className={`${styles.form} ${styles.walletPage}`}>
            <Field
              component={(props: any) => (
                <SelectedInput
                  {...props}
                  onChange={(e: any) => {
                    e.name && setCurrValue(+e.name);
                    props.input && props.input.onChange && props.input.onChange(+e.name);
                  }}
                  name={`$${currValue}`}
                  customStyles={styles.moneyInput}
                  disableValues={true}
                  listStyle={styles.selectedList}
                />
              )}
              name='balance'
              type='text'
              iconStart={<AttachMoneyIcon />}
              parentStyle={styles.moneyInput}
              classes={{ root: styles.rootMoneyInput }}
              data={priceArr}
            />

            <Button
              color='primary'
              variant='contained'
              customClass={styles.balanceButton}
              type='submit'
              disabled={disable}
            >
              Top up balance
            </Button>
          </Form>
        </>
      )}
    </>
  );
};

export default reduxForm<ReplenishFormType, Props>({
  form: 'replenishForm',
})(ReplenishForm);
