import React, { useRef, useState, useEffect } from 'react';
import styles from './LiveClass.module.scss';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../redux/store';
import {
  getClasses,
  getClass,
  getInstrClasses,
  subscribeToClass,
  unsubscribeToClass,
  cancelInstructorClass,
  getUserClasses,
  getClassUsers,
  getClassUsersInfo,
} from '../../../../redux/actions/liveClassesActions';
import { ReactComponent as Twitter } from '../../../../assets/svg/share/twitter.svg';
import { ReactComponent as Facebook } from '../../../../assets/svg/share/facebook.svg';
import { ReactComponent as Copy } from '../../../../assets/svg/share/copy.svg';
import { ReactComponent as Clock } from '../../../../assets/svg/liveClasses/clock.svg';
import { ReactComponent as Signal } from '../../../../assets/svg/liveClasses/signal.svg';

import { ReactComponent as Share } from '../../../../assets/svg/share.svg';
import ClassesList from '../ClassesList/ClassesList';
import Rating from '@material-ui/lab/Rating';
import { MenuItem, Menu, Box, Hidden } from '@material-ui/core';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { stringLimiter, timeHandler, compareTime, timeLocalHandler, dateHandler } from '../../../../helpers/functions';
import Attachments from './Attachments/Attachments';
import { alertShowThunk } from '../../../../redux/reducers/alert';
import { LiveClassType } from '../../../../redux/reducers/liveClasses';
import { UserType } from '../../../../redux/reducers/user';
import MainLoader from '../../../common/MainLoader/MainLoader';
import { getBalance } from '../../../../redux/actions/userActions';

import { Echo } from '../../../../index';
import { setClass } from '../../../../redux/reducers/liveClasses';
import StatusFlag from '../../../common/StatusFlag/StatusFlag';
import ModalWindow from '../LiveClasses/ModalWindow/ModalWindow';
import Modal from '../../../components/Modal/Modal';
import ModalCancelClass from './ModalCancelClass/ModalCancelClass';
import ModalEndStream from '../Stream/VideoLesson/ModalEndStream/ModalEndStream';

import { LiveClassHeader } from './LiveClassHeader/LiveClassHeader';
import { ClassInfo } from './ClassInfo/ClassInfo';
import MobileHeader from './MobileHeader/MobileHeader';
import { shareArr } from '../../../../assets/styles/assets';
import AuthorInfo from './AuthorInfo/AuthorInfo';

let icons: any = {
  twitter: (
    <TwitterShareButton url={`${window.location.href}`}>
      <Twitter /> Share on Twitter
    </TwitterShareButton>
  ),
  facebook: (
    <FacebookShareButton url={`${window.location.href}`}>
      <Facebook /> Share on Facebook
    </FacebookShareButton>
  ),
  copy: (
    <div onClick={() => navigator.clipboard.writeText(`${window.location.href}`)}>
      <Copy /> Copy link
    </div>
  ),
};

const LiveClass: React.FC = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { liveClasses, liveClass, instrClasses, userClasses } = useSelector((state: StateType) => state.liveClasses);
  const [filteredClasses, setFilteredClasses] = useState<any>([]);
  const [recurringClasses, setRecurringClasses] = useState<any>([]);
  const [signUp, setSignUp] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openEndModal, setOpenEndModal] = useState(false);
  const [instructor, setInstructor] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user, balance } = useSelector((state: StateType) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const params: { id: string; stream: string } = useParams();
  const token = localStorage.getItem('token');

  useEffect(() => {
    params?.id && getClassNclasses(+params.id);
    checkClassesAndGetBalance();
  }, []);

  useEffect(() => {
    checkClassesAndGetBalance();
  }, [userClasses]);

  const checkClassesAndGetBalance = () => {
    dispatch(getBalance());
    if (liveClass && userClasses && userClasses.length > 0) {
      const liveClassMatched = userClasses.find((el: LiveClassType) => el.id === liveClass.id);

      liveClassMatched ? setSignUp(true) : setSignUp(false);
    } else if (userClasses.length === 0) {
      setSignUp(false);
    }
  };

  useEffect(() => {
    if (user && liveClass) {
      user.type === 'Instructor' && Number(liveClass.owner) === Number(user.id)
        ? setInstructor(true)
        : setInstructor(false);
    }
  }, [user, liveClass]);

  useEffect(() => {
    if (instrClasses) {
      const currentArr = instrClasses.filter(
        (el: LiveClassType) => el.id !== liveClass.id && (el.status === 'Upcoming' || el.status === 'Running')
      );

      const recurringArr = instrClasses.filter(
        (el: LiveClassType) => el.schedule_id && el.schedule_id === liveClass.schedule_id
      );
      const slicedArr = currentArr.slice(0, 6);
      slicedArr.length > 0 ? setFilteredClasses(slicedArr) : setFilteredClasses([] as Array<LiveClassType>);

      setRecurringClasses(recurringArr);
    }
  }, [instrClasses]);

  useEffect(() => {
    const id = +params.id;
    if (params && params.id) {
      if ((liveClass && liveClass.id !== id) || !liveClass.id) {
        window.scrollTo(0, 0);
        setLoading(true);
        getClassNclasses(id);
      }
    }
  }, [params.id]);

  useEffect(() => {
    if (liveClass.id) {
      console.log('LiveClassUpdated listen');
      Echo.channel('LiveClasses').listen('LiveClassUpdated', (e: any) => {
        if (e.liveClass.id === liveClass.id) {
          dispatch(setClass(e.liveClass));
          //dispatch(getClasses(null));
        }
      });
    }
    return () => {
      Echo.channel('LiveClasses').stopListening('LiveClassUpdated');
    };
  }, [liveClass.id]);

  const cancelInstClass = async (recurrent?: boolean) => {
    await dispatch(
      cancelInstructorClass({
        id: liveClass.id,
        update_all: recurrent,
        status: 'Canceled',
      })
    );

    await getClassNclasses(liveClass.id);
    await setOpenCancelModal(false);
  };

  const cancelStudentClass = async (liveClass: LiveClassType) => {
    await dispatch(unsubscribeToClass(liveClass.id));
    await dispatch(getUserClasses(null));
    await history.push('/user/live-classes');
  };

  const getClassNclasses = (id: number) => {
    dispatch(getClass(id))
      //@ts-ignore
      .then(async (res) => {
        await dispatch(
          getInstrClasses({
            id: res.payload.owner,
            status: ['Upcoming', 'Running'],
          })
        );
        await dispatch(getUserClasses(null));
        await setTimeout(() => setLoading(false), 300);
      });
  };

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleClassButton = () => {
    if (!token) {
      history.push(`/auth/sign-in`);
    } else if (user && user.type === 'Instructor' && Number(liveClass.owner) !== Number(user.id)) {
      dispatch(alertShowThunk('error', 'You should switch to the student account'));
    } else if (user && user.type === 'Instructor' && Number(liveClass.owner) === Number(user.id)) {
      history.push(`/user/live-classes/${liveClass.id}/stream`);
    } else if (user.type === 'Student') {
      subscribe();
      //   setSignUp(!signUp);
    }
    if (signUp && liveClass.is_joinable) {
      history.push(`/user/live-classes/${liveClass.id}/stream`);
    }
  };

  const cancelClassButton = async () => {
    //check if there is no more than five mins to the start
    if (compareTime(liveClass.gtm_start_datetime, user.timezone)) {
      if (Number(liveClass.owner) === Number(user.id) && user.type === 'Instructor') {
        handleOpenCancelModal();
      } else {
        handleOpenCancelModal();
      }
    } else if (!compareTime(liveClass.gtm_start_datetime, user.timezone)) {
      // the time is less than 5 minutes
      {
        console.log('<5 mins');
        /*  await dispatch(unsubscribeToClass(liveClass.id));
        await dispatch(getUserClasses(null)); */
      }
    }
  };
  const endClassButton = async () => {
    handleOpenEndModal();
  };

  const subscribe = async () => {
    if (liveClass && liveClass.price === 0) {
      await dispatch(subscribeToClass(liveClass.id));
      await dispatch(getUserClasses(null));
      await setSignUp(true);
      await dispatch(getClassUsersInfo(liveClass.id));
    } else if (liveClass && liveClass.price > 0) {
      /*       await dispatch(subscribeToClass(liveClass.id));
      await dispatch(getUserClasses(null)); */
      !signUp && handleOpenModal();
    }
  };

  const durationHandler = (duration: string) => {
    const hours = +duration.slice(0, 2);
    const minutes = +duration.slice(3, 5);

    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes > 0 ? minutes + ' minutes' : ''}`;
  };

  const photoErrHandler = (err: any) => {
    return (err.target.src = require(`../../../../assets/images/1.png`));
  };

  const instrHasName = liveClass && liveClass.user && liveClass.user.first_name && liveClass.user.last_name;

  const joinButtonTitle = (liveClass: LiveClassType, user: UserType) => {
    if (user && user.type === 'Instructor' && Number(liveClass.owner) === Number(user.id)) {
      return liveClass.status === 'Running' ? 'Enter to class' : 'Start live class';
    } else if ((liveClass.status === 'Upcoming' || liveClass.status === 'Running') && !signUp) {
      return liveClass.status === 'Running' ? 'Book and join' : 'Sign up for the class';
    } else if (liveClass.status === 'Running' || (liveClass.status === 'Upcoming' && signUp)) {
      return 'Join live class';
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenCancelModal = () => {
    setOpenCancelModal(true);
  };
  const handleOpenEndModal = () => {
    setOpenEndModal(true);
  };

  const HeaderClass = () => {
    return (
      <>
        <h1>{liveClass.title}</h1>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={(e: any) => {
            anchorRef && handleToggle(e);
          }}
          className={styles.shareButton}
          variant='contained'
        >
          Share <Share />
        </Button>
        <Menu
          id='fade-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          classes={{ paper: styles.shareMenu }}
        >
          {shareArr &&
            shareArr.map((el) => {
              return (
                <MenuItem
                  key={`${el.id}`}
                  classes={{
                    root: `${styles.shareMenuItem} `,
                  }}
                  onClick={handleClose}
                >
                  <div className={styles.linkTitle}>{icons[`${el.icon}`]}</div>
                </MenuItem>
              );
            })}
        </Menu>
      </>
    );
  };

  const showClassName = () => {
    return !instructor && !signUp;
  };
  return (
    <div className={styles.wrapper}>
      <MainLoader loading={loading} />

      <ModalWindow
        signUpFunc={(e: boolean) => setSignUp(e)}
        selectedClass={liveClass}
        open={openModal}
        closeModal={() => setOpenModal(false)}
      />
      <ModalCancelClass
        open={openCancelModal}
        recurrent={liveClass.schedule_id && user.type === 'Instructor' ? true : false}
        onCloseModal={() => setOpenCancelModal(false)}
        cancelCurrClass={() => (user.type === 'Instructor' ? cancelInstClass() : cancelStudentClass(liveClass))}
        cancelAllClasses={() => liveClass.schedule_id && user.type === 'Instructor' && cancelInstClass(true)}
      />
      <ModalEndStream open={openEndModal} onCloseModal={() => setOpenEndModal(false)} />
      {liveClass && liveClass.gtm_start_datetime && (
        <div className={styles.currentClassWrapper}>
          <MobileHeader liveClass={liveClass} userId={+user?.id} />
          <div className={styles.wrapperImg}>
            <div className={styles.imageBlock}>
              <img
                src={`${process.env.REACT_APP_SERVER_HOST}/storage/live-class/${liveClass.md_photo}`}
                alt={liveClass.status}
                className={styles.image}
                onError={(err) => photoErrHandler(err)}
              />
              <StatusFlag customStyles={styles.statusFlag} status={liveClass.status} />
            </div>
            <div
              className={`${styles.buttonsWrapper} ${!user.id || (!instructor && !signUp) ? styles.notAuthUser : ''}`}
            >
              {(liveClass.status === 'Running' || liveClass.status === 'Upcoming') && (
                <>
                  {(!user.type ||
                    (user.type && Number(user.id) !== Number(liveClass.owner)) ||
                    (user.type === 'Instructor' && Number(user.id) === Number(liveClass.owner))) && (
                    <Button
                      className={styles.startClassButton}
                      color='primary'
                      variant='contained'
                      onClick={handleClassButton}
                      classes={{ disabled: styles.disabledButton }}
                      disabled={
                        signUp &&
                        (compareTime(liveClass.gtm_start_datetime, user.timezone) || !liveClass.is_joinable) &&
                        liveClass.status !== 'Running'
                      }
                    >
                      {liveClass && joinButtonTitle(liveClass, user)}
                    </Button>
                  )}
                  {user && (
                    <div className={styles.editCancelWrapper}>
                      {instructor && Number(liveClass.owner) === Number(user.id) && liveClass.status === 'Upcoming' && (
                        <Button
                          className={styles.editClassButton}
                          color='primary'
                          variant='contained'
                          onClick={() => history.push(`/user/edit-live-class/${liveClass.id}`)}
                          disabled={!compareTime(liveClass.gtm_start_datetime, user.timezone)}
                        >
                          Edit live class
                        </Button>
                      )}
                      {(signUp || instructor) && liveClass.status === 'Upcoming' && (
                        <Button
                          className={styles.cancelClassButton}
                          color='primary'
                          variant='contained'
                          onClick={() => cancelClassButton()}
                          classes={{ disabled: styles.disabledButton }}
                          disabled={!compareTime(liveClass.gtm_start_datetime, user.timezone)}
                        >
                          Cancel live class
                        </Button>
                      )}
                      {(signUp || instructor) &&
                        liveClass.status === 'Running' &&
                        Number(liveClass.owner) === Number(user.id) && (
                          <Button
                            className={styles.cancelClassButton}
                            color='primary'
                            variant='contained'
                            onClick={() => endClassButton()}
                            classes={{ disabled: styles.disabledButton }}
                            disabled={false}
                          >
                            End live class
                          </Button>
                        )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={styles.descWrapper}>
            <div className={styles.descHeader}>
              <Hidden smDown>
                <div className={styles.title}>
                  <LiveClassHeader title={liveClass.title} shareArr={shareArr} />
                </div>

                <AuthorInfo isOwner={liveClass.owner === user.id} liveClass={liveClass} instrHasName={instrHasName} />
              </Hidden>

              <ClassInfo liveClass={liveClass} user={user} />
              <div className={styles.description}>
                {liveClass.description && stringLimiter(liveClass.description, 400)}
              </div>
              <div className={styles.categories}>
                {liveClass.categories &&
                  liveClass.categories.map((el: any) => {
                    return (
                      <div key={`${el.id}`} className={styles.singleCategory}>
                        #{el.name}
                      </div>
                    );
                  })}
              </div>
            </div>
            {false && <Attachments />}
          </div>
        </div>
      )}
      <div className={styles.contentWrapper}>
        {/* {liveClasses && <ClassesList arr={liveClasses} type={'recommended'} />} */}
        {recurringClasses && recurringClasses.length > 1 && <ClassesList arr={recurringClasses} type={'recurring'} />}
        {filteredClasses && filteredClasses.length > 0 && (
          <ClassesList
            arr={filteredClasses}
            type={`instructor`}
            authorName={
              instrHasName ? `${liveClass.user.first_name} ${liveClass.user.last_name}` : 'current instructor'
            }
          />
        )}
      </div>
    </div>
  );
};

export default LiveClass;
