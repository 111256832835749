import React, { useRef, useState, useEffect, SyntheticEvent } from 'react';
import { OTSession, OTStreams, preloadScript } from 'opentok-react';
import styles from './VideoLesson.module.scss';
import Publisher from './Publisher';
import Subscriber from './Subscriber';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../../redux/store';
import {
  cancelInstructorClass,
  createStreamSession,
  filterClasses,
  getClasses,
  getClassUsers,
  joinClass,
  leaveClass,
  updateClass,
} from '../../../../../redux/actions/liveClassesActions';
import { setStreamUsers, LiveClassType, setFullscreen } from '../../../../../redux/reducers/liveClasses';
import { current } from '@reduxjs/toolkit';
import PersonIcon from '@material-ui/icons/Person';
import { Echo } from '../../../../../index';
import { getFilters } from '../../../../../helpers/liveClassesfilters';
import Button from '../../../../common/Button/Button';
import { ReactComponent as Camera } from '../../../../../assets/svg/liveClasses/camera.svg';
import { IconButton, Modal, Tab, Tabs, Slider, Hidden } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogOut } from '../../../../../assets/svg/logOut.svg';
import { ReactComponent as Users } from '../../../../../assets/svg/users.svg';
import { ReactComponent as ChatIcon } from '../../../../../assets/svg/chat.svg';
import { ReactComponent as Attachments } from '../../../../../assets/svg/share/copy.svg';

import { Link as MaterialLink } from '@material-ui/core';
import { KeyObject } from 'crypto';
import ModalStream from './ModalStream';
import { studentMenu } from '../../../../../helpers/values';
import StatusFlag from '../../../../common/StatusFlag/StatusFlag';
import {compareTime, photoUserErrHandler} from '../../../../../helpers/functions';
import ImageWithFallbacks from '../../../../components/ImageWithFallbacks/ImageWithFallbacks';
import ModalEndStream from './ModalEndStream/ModalEndStream';
import MobileHeader from '../../LiveClass/MobileHeader/MobileHeader';
import Chat from './Chat/Chat';

const VonageConfig = {
  apiKey: String(process.env.REACT_APP_VONAGE_API_KEY ?? 'vonageApiKey'),
  sessionId: String(process.env.REACT_APP_VONAGE_SESSION_ID ?? 'vonageSessionId'),
  token: String(process.env.REACT_APP_VONAGE_TOKEN ?? 'vonageToken'),
};

type VideoLessonType = {
  Flag: React.FC;
  correctBrowser: boolean;
};

type TabPanelProps = {
  children?: React.ReactNode;
  className?: any;
  index: any;
  value: any;
};

const countdownTimer = (startDate: string, startTime: string) => {
  const classDate = new Date(`${startDate} ${startTime}`);
  const difference = +classDate - +new Date();
  let time = ``;

  if (difference > 0) {
    const parts: any = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
    const transformFormat = (value: number) => {
      return `${value}`.length === 1 ? `0${value}` : `${value}`;
    };
    time = `${transformFormat(parts.minutes)}:${transformFormat(parts.seconds)}`;
  }
  return time;
};

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className={className}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const VideoLesson: React.FC<VideoLessonType> = ({ Flag, correctBrowser }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state: StateType) => state.user);
  const { liveClass, liveClassUsers, userClasses } = useSelector((state: StateType) => state.liveClasses);

  const [tokenExpired, setTokenExpired] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tabsValue, setTabsValue] = useState(0);
  const [volumeValue, setVolumeValue] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [timeLeftover, setTimeLeftover] = useState('');
  const [streamTemporaryDestroyed, setStreamTemporaryDestroyed] = useState(false);

  const otSession = useRef();

  useEffect(() => {
    return () => {
      // @ts-ignore
      otSession.current && otSession.current.sessionHelper.session.off();
    };
  }, []);

  useEffect(() => {
    if (liveClass.id) {
      return () => {
        setPendingMode();
      };
    }
  }, [liveClass.id]);

  useEffect(() => {
    if (liveClass.id && user.id && (compareTime(liveClass.gtm_start_datetime, user.timezone) || liveClass.status === 'Canceled')) {
      history.push(`/user/live-classes/${liveClass.id}`);
    }
  }, [liveClass.id, user.id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeftover(countdownTimer(liveClass.start_date, liveClass.start_time));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    if (liveClass.id && user && (tokenExpired || !liveClass.session_id || !liveClass.token)) {
      dispatch(createStreamSession({ liveClassId: liveClass.id }));
    }
  }, [tokenExpired, liveClass.id]);

  useEffect(() => {
    if (liveClass.id) {
      dispatch(getClassUsers(liveClass.id));
      Echo.channel('LiveClasses').listen('LiveClassUsersUpdated', (e: any) => {
        console.log('LiveClassUsersUpdated');
        // ToDo: temporary hotfix. need to handle this case on server-side using separate websockets channels
        let users: any = [];
        if (e.streamUsers.length > 0 && e.streamUsers[0].class_id === liveClass.id) {
          users = e.streamUsers;
        }
        dispatch(setStreamUsers(users));
      });
    }
    return () => {
      Echo.channel('LiveClasses').stopListening('LiveClassUsersUpdated');
    };
  }, [liveClass.id]);

  const checkFullscreen = () => {
    //@ts-ignore
    if (!document.fullscreenElement || !document.webkitFullscreenElement) {
      dispatch(setFullscreen(false));
      //@ts-ignore
    } else if (document.fullscreenElement || document.webkitFullscreenElement) {
      dispatch(setFullscreen(true));
    }
  };
  useEffect(() => {
    window.addEventListener('fullscreenchange', checkFullscreen);
    window.addEventListener('webkitfullscreenchange', checkFullscreen);
    return () => {
      window.removeEventListener('fullscreenchange', checkFullscreen);
      window.removeEventListener('webkitfullscreenchange', checkFullscreen);
    };
  }, []);

  const setPendingMode = () => {
    console.log('setPendingMode');
    console.log(user.type);
    console.log(liveClass);
    if (user && user.type === 'Instructor' && liveClass.status === 'Running') {
      dispatch(updateClass({ id: liveClass.id, fields: { is_pending: true } }));
    }
  };

  // let publisherConnection =
  const sessionEvents = {
    sessionConnected: (event: any) => {
      console.log('VideoLesson: sessionConnected');
      if (user && user.type === 'Student' && liveClass.class_users_info) {
        dispatch(
          joinClass({
            id: liveClass.id,
            fields: { clientId: event.target.connection.connectionId },
          })
        );
      }
    },
    sessionDisconnected: () => {
      console.log('VideoLesson: sessionDisconnected');
    },
    connectionCreated: (event: any) => {
      console.log('VideoLesson: connectionCreated');
    },
    connectionDestroyed: (event: any) => {
      console.log('VideoLesson: connectionDestroyed');
      dispatch(
        leaveClass({
          id: liveClass.id,
          fields: { clientId: event.connection.connectionId },
        })
      );
    },
    streamCreated: (event: any) => {
      console.log('VideoLesson: stream created', event.stream.videoType);
      if (event.stream.videoType !== 'screen') {
        setStreamTemporaryDestroyed(false);
      }
    },
    streamDestroyed: (event: any) => {
      console.log('VideoLesson: stream destroyed', event.stream.videoType);
      if (event.stream.videoType !== 'screen') {
        setStreamTemporaryDestroyed(true);
      }
    },
  };

  const onError = (err: any) => {
    console.log(`VideoLesson: Failed to connect: ${err.message}`);
    if (err.code === 1004) {
      setTokenExpired(true);
    }
  };

  const checkPublisher = () => {
    return (
      user &&
      user.type === 'Instructor' &&
      Number(liveClass.owner) === Number(user.id) &&
      (liveClass.status === 'Upcoming' || liveClass.status === 'Running')
    );
  };

  const checkSubscriber = () => {
    let currentUser = null;
    if (user && user.type === 'Student' && liveClass.class_users_info) {
      currentUser = liveClass.class_users_info.find((el: any) => {
        return el.user_id === user.id && !Boolean(el.is_blocked);
      });
    }
    return currentUser;
  };

  const startLiveClass = () => {
    dispatch(
      updateClass({
        id: liveClass.id,
        fields: { status: 'Running', is_pending: false },
      })
    );
  };

  const handleOpenModal = () => {
    if (liveClass.status === 'Passed' || liveClass.status === 'Canceled') {
      history.push(`/user/live-classes/${liveClass.id}`);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabsValue(newValue);
  };

  const streamStartedAgo = () => {
    return 'Started 24 minutes ago';
  };

  return (
    <div className={styles.wrapper}>
      <MobileHeader liveClass={liveClass} userId={+user?.id} />
      <div className={styles.currentClassWrapper}>
        <div className={styles.mediaFrameWrapper}>
          <div className={`${styles.mediaFrame} ${checkSubscriber() ? styles.mediaFrameSubs : ''}`}>
            {correctBrowser &&
              liveClass &&
              liveClass.session_id &&
              liveClass.token &&
              (checkPublisher() || checkSubscriber()) && (
                <OTSession
                  // @ts-ignore
                  ref={otSession}
                  apiKey={VonageConfig.apiKey}
                  sessionId={liveClass.session_id}
                  token={liveClass.token}
                  eventHandlers={sessionEvents}
                  onError={onError}
                >
                  {checkPublisher() ? (
                    <Publisher />
                  ) : checkSubscriber() && liveClass.status === 'Running' && !liveClass.is_pending ? (
                    <OTStreams>
                      <Subscriber />
                    </OTStreams>
                  ) : (
                    ''
                  )}
                </OTSession>
              )}
            <div className={`${styles.statusFlag} ${liveClass.status === 'Running' && styles.pulseRunning}`}>
              <StatusFlag status={liveClass.status} />
            </div>
            {/* there is code below for the preview  */}
            {(user.type === 'Instructor' && (liveClass.status === 'Passed' || liveClass.status === 'Canceled')) ||
            (user.type === 'Student' &&
              (liveClass.status !== 'Running' ||
                (liveClass.status === 'Running' && (liveClass.is_pending || streamTemporaryDestroyed)))) ? (
              <div className={`${styles.previewMask} ${liveClass.status === 'Passed' && styles.previewEnd}`}>
                {liveClass.status === 'Passed' ? (
                  <div className={styles.classEnd}>
                    <img src={require('../../../../../assets/images/check.png')} alt='preloader' />
                    <p>Live Class ended</p>
                    <p>Thanks for watching!</p>
                  </div>
                ) : (
                  <div className={styles.loader}>
                    <div>
                      <img src={require('../../../../../assets/preloader/preloader_bl.gif')} alt='preloader' />
                      <div className={styles.timerText}>
                        {liveClass.is_pending || streamTemporaryDestroyed || liveClass.status === 'Canceled' ? (
                          <div>
                            The class is {liveClass.status === 'Canceled' ? 'canceled' : 'paused'}
                            ...
                          </div>
                        ) : (
                          <>
                            <div>{timeLeftover ? `Live class starting: ` : `Let's start`}</div>
                            {timeLeftover && <div className={styles.timerCountdown}>{timeLeftover}</div>}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className={styles.mediaControls}>
            {liveClass.user && (
              <>
                <div className={styles.classDescription}>
                  <h3>
                    {liveClass.user.first_name} {liveClass.user.last_name}
                  </h3>
                  <h1>{liveClass.title}</h1>
                  <div className={styles.classCategories}>
                    {liveClass.categories.map((el: any) => {
                      return <div key={`${el.id}`}>#{el.name}</div>;
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`${styles.classUsers} ${tabsValue === 1 ? styles.chatsActiveTab : ''}`}>
          <div className={styles.tabsWrapper}>
            <Tabs
              value={tabsValue}
              onChange={handleChangeTabs}
              variant='fullWidth'
              indicatorColor='primary'
              textColor='primary'
              aria-label='icon tabs example'
              classes={{ root: styles.tabsRoot }}
              TabIndicatorProps={{ className: styles.indicatorRoot }}
            >
              <Tab
                classes={{ root: styles.tabRoot, selected: styles.selectedTab }}
                icon={<Users />}
                aria-label='users'
                id={`scrollable-auto-tab-${0}`}
                aria-controls={`scrollable-auto-tabpanel-${0}`}
                disableRipple
              />
              {liveClass.status !== 'Canceled' ?
                <Tab
                  classes={{
                    root: `${styles.tabRoot} ${styles.tabDivider}`,
                    selected: styles.selectedTab,
                  }}
                  icon={<ChatIcon />}
                  aria-label='chat'
                  id={`scrollable-auto-tab-${1}`}
                  aria-controls={`scrollable-auto-tabpanel-${1}`}
                  disableRipple
                />
                :
                ''}
              {/*   
              <Tab
                classes={{ root: styles.tabRoot, selected: styles.selectedTab }}
                icon={<Attachments />}
                aria-label='attachments'
                disableRipple
              /> */}
            </Tabs>
            <TabPanel value={tabsValue} index={0}>
              <div className={styles.usersListWrapper}>
                {liveClass &&
                  liveClass.status === 'Running' &&
                  liveClass.user &&
                  !liveClass.is_pending &&
                  !streamTemporaryDestroyed && (
                    <div
                      className={`${styles.userWrapper} ${
                        user.type === 'Instructor' ? styles.teacherWrapper : styles.owner
                      }`}
                      key={liveClass.user.id}
                    >
                      <div className={styles.userImg}>
                        {liveClass.user.sm_photo ? (
                          <ImageWithFallbacks
                            src={`${process.env.REACT_APP_SERVER_HOST}/storage/photo/${liveClass.user.sm_photo}`}
                            alt='user_img'
                            fallbacks={`${photoUserErrHandler(liveClass.user.photo)}`}
                          />
                        ) : (
                          <PersonIcon />
                        )}
                      </div>
                      <div className={styles.userInfo}>
                        <h4>{`${liveClass.user.first_name} ${liveClass.user.last_name}`}</h4>
                        <p>Instructor</p>
                      </div>
                    </div>
                  )}
                {liveClassUsers.map((el: any) => {
                  return (
                    <div className={styles.userWrapper} key={el.id}>
                      <div className={styles.userImg}>
                        {el.user.sm_photo ? (
                          <ImageWithFallbacks
                            src={`${process.env.REACT_APP_SERVER_HOST}/storage/photo/${el.user.sm_photo}`}
                            alt='user_img'
                            fallbacks={`${photoUserErrHandler(el.user.photo)}`}
                          />
                        ) : (
                          <PersonIcon />
                        )}
                      </div>
                      <div className={styles.userInfo}>
                        <h4>{`${el.user.first_name} ${el.user.last_name}`}</h4>
                        <p>{el.user.last_type}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            {liveClass.status !== 'Canceled' ?
              <TabPanel value={tabsValue} index={1} className={styles.chatTab}>
                <Chat userId={user.id} />
              </TabPanel>
              :
              ''}
          </div>
          <div className={styles.startEndButtons}>
            {checkPublisher() &&
            (liveClass.status === 'Upcoming' || (liveClass.status === 'Running' && liveClass.is_pending)) ? (
              <Button
                customClass={styles.startButton}
                iconLeft={<Camera />}
                variant='contained'
                color='primary'
                onClick={startLiveClass}
              >
                {liveClass.is_pending ? 'Continue' : 'Start'} stream
              </Button>
            ) : (
              ''
            )}
            {checkPublisher() && liveClass.status === 'Running' && (
              <Button
                customClass={styles.endButton}
                iconLeft={<LogOut />}
                variant='contained'
                color='primary'
                onClick={handleOpenModal}
              >
                End
              </Button>
            )}
            {(liveClass.status === 'Passed' || liveClass.status === 'Canceled' || user.type === 'Student') && (
              <Button
                customClass={styles.endButton}
                iconLeft={<LogOut />}
                variant='contained'
                color='primary'
                onClick={handleOpenModal}
              >
                Leave
              </Button>
            )}
          </div>
        </div>
      </div>

      <ModalEndStream open={openModal} onCloseModal={() => handleCloseModal()} />
    </div>
  );
};

export default VideoLesson;
