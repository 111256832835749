import React from 'react';
import { GoogleLogin as LoginGoogle } from 'react-google-login';
import Button from '../../common/Button/Button';
import { ReactComponent as GoogleIcon } from '../../../assets/svg/google.svg';
import { clientIdGoogleLogin } from '../../../helpers/constants';
import styles from './GoogleLogin.module.scss';
import { useDispatch } from 'react-redux';
import { googleAuth } from '../../../redux/actions/authActions';
import {alertShowThunk} from "../../../redux/reducers/alert";

const GoogleLogin: React.FC<{ text: string; signUp?: boolean }> = ({
  text,
  signUp = false,
}) => {
  const dispatch = useDispatch();

  const onSuccess = (response: any) => {
    response &&
      response.accessToken &&
      //@ts-ignore
      dispatch(
        googleAuth({ code: response.accessToken, sign_up: signUp })
        //@ts-ignore
      ).then((res) => {});
  };

  const onFailure = (response: any) => {
    /*  console.log('onFailure', response); */
  };

  const onClick = (event: any, googleOnClick: any) => {
    event.preventDefault();
    let ua = navigator.userAgent || navigator.vendor;
    if(ua.indexOf('Instagram') > -1){
      dispatch(alertShowThunk('warning', 'To log into the system open the app in your app browser'));
    }
    else{
      googleOnClick();
    }
  };

  return (
    <LoginGoogle
      clientId={clientIdGoogleLogin}
      buttonText='Login'
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
      render={(props) => {
        return (
          <Button
            type='submit'
            variant='contained'
            customClass={styles.googleButton}
            onClick={(event) => onClick(event, props.onClick)}
          >
            <GoogleIcon /> {text}
          </Button>
        );
      }}
    />
  );
};

export default GoogleLogin;
