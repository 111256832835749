import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'redux-form';
import styles from './StripeForm.module.scss';
import Button from '../../../../common/Button/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { setupPaymentMethod, getProfile, replenishBalance, getBalance } from '../../../../../redux/actions/userActions';

const CheckoutForm: React.FC<{ balance?: number }> = ({ balance }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const matchesMax = useMediaQuery('(max-width:767px)');

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: matchesMax ? '14px' : '16px',
        '::placeholder': {
          fontSize: matchesMax ? '14px' : '16px',
        },
      },
      invalid: {
        color: '#f44336',
        iconColor: '#f44336',
      },
    },
  };

  const handleSubmit = async (event: any) => {
    event && event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card: any = await elements.getElement(CardElement);

    await stripe
      .createToken(card)
      .then((res) => {
        setDisableButton(true);
        if (res.token) {
          res.token?.id &&
            //@ts-ignore
            dispatch(setupPaymentMethod({ id: res.token?.id })).then(async (res: any) => {
              if (res && res.payload?.success && balance) {
                await dispatch(replenishBalance(balance));
                await dispatch(getBalance());
              }
              await dispatch(getProfile());
              await setDisableButton(false);
            });
        } else {
          setDisableButton(false);
          res.error?.message && setErrorMessage(res.error.message);
          setTimeout(() => setErrorMessage(''), 33000);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Form onSubmit={handleSubmit} className={styles.form}>
      <div>
        <CardElement
          className={`${styles.cardElement} ${errorMessage ? styles.errorStripe : ''}`}
          options={CARD_ELEMENT_OPTIONS}
        />
        {errorMessage && <p className={styles.errorStripe}>{errorMessage}</p>}
      </div>
      <Button
        color='primary'
        variant='contained'
        customClass={styles.balanceButton}
        type='submit'
        disabled={!stripe || disableButton}
      >
        Top up balance
      </Button>
    </Form>
  );
};

const StripeForm: React.FC<{ balance?: number }> = ({ balance }) => {
  const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_KEY));

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm balance={balance} />
    </Elements>
  );
};

export default StripeForm;
