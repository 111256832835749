import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';
import Input from '../../../common/Input/Input';
import Button from '../../../common/Button/Button';
import { signIn } from '../../../../redux/actions/authActions';
import { StateType } from '../../../../redux/store';
import { required, email, password } from '../../../../helpers/validate';
import styles from './SignInPage.module.scss';
import { alertShowThunk } from '../../../../redux/reducers/alert';
import GoogleLogin from '../../../components/GoogleLogin/GoogleLogin';

export type SignInPageType = {
  email: string;
  password: string;
};

const SignInPage: React.FC<InjectedFormProps<SignInPageType>> = ({ handleSubmit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state: StateType) => state.user);
  const { errors, token, user, googleSignUp } = useSelector((state: StateType) => state.auth);

  const [emptyFields, setEmptyFields] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = (e: any) => {
    e.preventDefault();

    handleSubmit((value) => {
      if (value && value.email && value.email) {
        dispatch(signIn(value));
        setEmptyFields(false);
      } else {
        setEmptyFields(true);
      }
    })();
  };

  useEffect(() => {
    token && history.push(`/user/home`);
  }, [token]);

  useEffect(() => {
    if (googleSignUp && googleSignUp.id) {
      history.push(`/auth/role/${googleSignUp.id}/${googleSignUp.email}`);
    }
  }, [googleSignUp]);

  useEffect(() => {
    if (errors) {
      if (errors === 'Unauthorized') {
        setError('Wrong email or password');
        // dispatch(alertShowThunk('error', errors));
      } else if (typeof errors === 'string') {
        dispatch(alertShowThunk('error', errors));
      }
    }
  }, [errors]);

  return (
    <div className={styles.wrapper}>
      <Form onSubmit={onSubmit} className={styles.form} onFocus={() => setError('')}>
        <div className={styles.internalWrapper}>
          <h1>Log in</h1>

          <h4>Email</h4>
          <Field
            component={Input}
            name='email'
            label='Type email here...'
            type='email'
            validate={[required, email]}
            emptyFields={emptyFields}
          />
          <h4>Password</h4>
          <Field
            component={Input}
            name='password'
            label='Type password here...'
            type='password'
            validate={[required, password]}
            emptyFields={emptyFields}
          />

          {error && <div className={styles.errorMessage}>{error}</div>}

          <div className={styles.formButtons}>
            <div>
              {/* <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                      name='checkedH'
                    />
                  }
                  label='Keep me signed in'
                />
              </FormGroup> */}
            </div>
            <div>
              <Link to='/auth/reset-password'>Forgot password?</Link>
            </div>
            <div className={styles.loginButtons}>
              <Button type='submit' color='primary' variant='contained'>
                Log in
              </Button>
              <p>or</p>
              <GoogleLogin text={' Login with Google'} />
            </div>
            <div>
              Don't have an account?
              <Link to='/auth/sign-up'>Create an account</Link>
            </div>
          </div>
        </div>
      </Form>
      <div className={styles.termsOfUse}>
        <span>
          By logging in or creating an account, you agree with our <Link to='/terms-of-use'>Terms of Use </Link>
          and <Link to='/privacy-policy'>Privacy Policy</Link>
        </span>
      </div>
    </div>
  );
};

export default reduxForm<SignInPageType>({
  form: 'login',
})(SignInPage);
