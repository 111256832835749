import React from 'react';
import styles from './Loader.module.scss';

type LoaderType = {
  isWhite?: boolean;
};

const Loader: React.FC<LoaderType> = ({ isWhite }) => (
  <div className={`${styles.loader} ${isWhite ? styles.isWhite : ''}`}>
    <div className={styles.lds_ring}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);
export default Loader;
