import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { ReactComponent as Mic } from '../../../../../assets/svg/stream/mic.svg';
import { ReactComponent as MicDisabled } from '../../../../../assets/svg/stream/micDisabled.svg';
import { ReactComponent as Camera } from '../../../../../assets/svg/stream/camera.svg';
import { ReactComponent as CameraDisabled } from '../../../../../assets/svg/stream/cameraDisabled.svg';
import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
import StopScreenShareOutlinedIcon from '@material-ui/icons/StopScreenShareOutlined';

type CheckBoxType = {
  type: string;
  initialChecked: boolean;
  onChange: Function;
  className?: any;
  disabled?: any;
};

const CheckBox: React.FC<CheckBoxType> = (props) => {
  const [id, setId] = useState(uniqueId('Checkbox'));
  const [isChecked, setIsChecked] = useState(props.initialChecked);
  const [icon, setIcon] = useState();
  const [checkedIcon, setCheckedIcon] = useState();

  const onChange = (event: any) => {
    setIsChecked(event.currentTarget.checked);
  };

  useEffect(() => {
    setIsChecked(props.initialChecked);
  }, [props.initialChecked]);

  useEffect(() => {
    props.onChange(isChecked);
  }, [isChecked]);

  useEffect(() => {
    switch(props.type){
        case 'video':
          setIcon(<CameraDisabled />);
          setCheckedIcon(<Camera />);
        break;
        case 'audio':
          setIcon(<MicDisabled />);
          setCheckedIcon(<Mic />);
        break;
        case 'share':
          setIcon(<ScreenShareOutlinedIcon />);
          setCheckedIcon(<StopScreenShareOutlinedIcon />);
        break;
    }
  }, []);

  return (
    <div>
      {/*   <label htmlFor={id}>
        {props.label}
      </label>
      <input
        type="checkbox"
        checked={isChecked}
        id={id}
        onChange={onChange}
      /> */}
      <Checkbox
        className={props.className}
        icon={icon}
        checkedIcon={checkedIcon}
        checked={isChecked}
        id={id}
        onChange={onChange}
        disabled={props.disabled}
      />
    </div>
  );
};

export default CheckBox;
