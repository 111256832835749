import { instance } from './api';

export const user = {
  getProfile: async () => {
    const { data } = await instance.post('/auth/me');
    return data;
  },

  signOut: async () => {
    const { data } = await instance.post(`/auth/logout`);
    return data;
  },
  getBalance: async () => {
    const { data } = await instance.get(`/users/wallet`);
    return data;
  },
  replenishBalance: async (value: number) => {
    const { data } = await instance.put(`/users/wallet`, {
      amount: value,
    });
    return data;
  },
  connectWithStripe: async (value?: unknown) => {
    const { data } = await instance.post(`/users/wallet/stripe`);
    return data;
  },
  setNotifications: async (value?: unknown) => {
    const { data } = await instance.put(`/users/notifications`, value);
    return data;
  },
  getNotifications: async () => {
    const { data } = await instance.get(`/users/notifications`);
    return data;
  },
  updateProfile: async (value: any) => {
    const { data } = await instance.put(`/users`, value);
    return data;
  },
  getSpecificUser: async (value: number) => {
    const { data } = await instance.get(`/users/${value}`);
    return data;
  },
  getListOfUsers: async (value: {
    filter: { type: 'Instructor' | 'Student' };
    limit?: number;
    offset?: number;
  }) => {
    const { data } = await instance.get(`/users`, {
      params: value,
    });
    return data;
  },
  updatePassword: async (value: {
    old_password: string;
    new_password: string;
  }) => {
    const { data } = await instance.put(`/users/change-password`, value);
    return data;
  },
  // student pass card`s data
  paymentMethod: async (value: any | {}) => {
    const data = await instance.post(
      `/users/wallet/stripe/payment-method`,
      value
    );
    return data;
  },
  // delete student`s card
  deleteStudentCard: async () => {
    const data = await instance.delete(`/users/wallet/stripe/payment-method`);
    return data;
  },
  // refresh stripe if ID created without creds
  refreshStripeAccount: async (value: any | {}) => {
    const data = await instance.post(
      `users/wallet/stripe/refresh-account`,
      value
    );
    return data;
  },
  // withdrawn money to Stripe account
  withdrawnStripe: async (value: any | {}) => {
    const data = await instance.post(`users/wallet/stripe/payout`, {
      amount: value,
    });
    return data;
  },
  // upload user`s photo
  setUserPhoto: async (value: string) => {
    const data = await instance.post(`users/photo`, {
      file: value,
    });
    return data;
  },
};
