import React, { useEffect, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';
import Input from '../../../common/Input/Input';
import Button from '../../../common/Button/Button';
import { signUp, updateUser } from '../../../../redux/actions/authActions';
import { StateType } from '../../../../redux/store';
import { required, email } from '../../../../helpers/validate';
import { ReactComponent as GoogleIcon } from '../../../../assets/svg/google.svg';
import styles from './SignUpPage.module.scss';
import { setNewUser, setError, clearNewUser } from '../../../../redux/reducers/auth';
import { alertShowThunk } from '../../../../redux/reducers/alert';
import GoogleLogin from '../../../components/GoogleLogin/GoogleLogin';

export type SignUpPageType = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  error: string;
};

const SignUpPage: React.FC<InjectedFormProps<SignUpPageType>> = ({ handleSubmit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, errors, googleSignUp } = useSelector((state: StateType) => state.auth);
  const { message } = useSelector((state: StateType) => state.alert);
  const [submitted, setSubmitted] = useState(false);

  let confirmCode = localStorage.getItem('confirm_code');
  let userLocal = localStorage.getItem('user');
  let parsedUser = userLocal && JSON.parse(userLocal);

  useEffect(() => {
    localStorage.removeItem('confirm_code');

    return () => {};
  }, []);

  useEffect(() => {
    if (googleSignUp && googleSignUp.id) {
      history.push(`/auth/role/${googleSignUp.id}/${googleSignUp.email}`);
    }
  }, [googleSignUp]);

  const submittedChecker = () => {
    if (parsedUser && parsedUser.status && parsedUser.status === 'Inactive') {
      history.push(`/auth/confirm`, { from: 'signUpPage' });
    }
  };
  /*   useEffect(() => {
    if (
      submitted &&
      parsedUser &&
      parsedUser.status &&
      parsedUser.status === 'Inactive'
    ) {
      history.push(`/auth/confirm`);
    }
  }, [submitted]); */

  useEffect(() => {
    if (message === 'Check your email, please') {
      history.push(`/auth/confirm`, { from: 'signUpPage' });
    }
  }, [message]);

  useEffect(() => {
    if (errors) {
      if (errors === 'Unauthorized') {
        setError('Wrong email or password');
      } else if (typeof errors === 'string') {
        dispatch(alertShowThunk('error', errors));
      }
    }
  }, [errors]);

  useEffect(() => {
    if (confirmCode && user && user.status === 'Inactive') {
      user.status === 'Inactive' && history.push(`/auth/confirm`, { from: 'signUpPage' });
    } else if (user && user.types && user.types.length > 0) {
      if (user.types.length === 1) {
        user.types[0].type === 'Student' ? onSetProfile('Student') : onSetProfile('Instructor');
        history.push(`/auth/create-account`);
      } else {
        // if user already has an account with two roles
        submitted && dispatch(setError('You already have an account'));

        dispatch(clearNewUser());
        history.push(`/auth/sign-in`);
      }
    }
  }, [user]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    setSubmitted(true);
    handleSubmit((value) => {
      dispatch(signUp(value));
    })();
  };

  const onSetProfile = (value: string) => {
    const userObj = {
      id: user.id,
      email: user.email,
      role: value,
      is_multiroled: true,
    };
    user.id && dispatch(setNewUser(userObj));
  };

  return (
    <div className={styles.wrapper}>
      <Form onSubmit={(e: any) => onSubmit(e)} className={styles.form}>
        <div className={styles.internalWrapper}>
          <h1>Create account</h1>

          <h4>Email</h4>
          <Field component={Input} name='email' label='Type email here...' type='email' validate={[required, email]} />

          <div className={styles.formButtons}>
            <Button type='submit' color='primary' variant='contained'>
              Continue
            </Button>
            <p>or</p>
            <GoogleLogin text={' Continue with Google'} signUp />

            <div>
              Already have an account?
              <Link to='/auth/sign-in'>Log in</Link>
            </div>
          </div>
        </div>
      </Form>
      <div className={styles.termsOfUse}>
        <span>
          By logging in or creating an account, you agree with our <Link to='/terms-of-use'>Terms of Use </Link>
          and <Link to='/privacy-policy'>Privacy Policy</Link>
        </span>
      </div>
    </div>
  );
};

export default reduxForm<SignUpPageType>({
  form: 'sign-up',
})(SignUpPage);
