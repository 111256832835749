import React, { useState, useEffect } from 'react';
import styles from './FileInput.module.scss';
import { ReactComponent as Camera } from '../../../assets/svg/camera.svg';
import { useDispatch } from 'react-redux';
import { alertShowThunk } from '../../../redux/reducers/alert';
import {photoUserErrHandler} from "../../../helpers/functions";
import ImageWithFallbacks from "../../components/ImageWithFallbacks/ImageWithFallbacks";

const FileInput = ({
  value,
  onChangeField,
  input,
  setPhoto,
  photoPreview,
  defaultPreview,
  defaultValue,
  isUserPhoto,
  ...rest
}: any) => {
  const [file, setFile] = useState({} as any);
  const [fileURL, setFileURL] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    // if file uploaded than code below show the preview
    if (file && file[0]) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setFileURL(reader.result as string);
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file[0]);
      onChangeField(file[0]);
    }
  }, [file]);

  useEffect(() => {
    if (defaultValue) {
      setFileURL(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    fileURL && input && input.onChange(fileURL);
  }, [fileURL]);

  const maxSizeFile = (files: any) => {
    if (files && files.length > 0) {
      const fileSize = files[0].size / (1024 * 1024);

      return fileSize <= 10
        ? setFile([...files])
        : dispatch(
            alertShowThunk('error', 'the image is too large, try another')
          );
    }
  };

  return (
    <>
      <div className={styles.photoUploader}>
        <label>
          {fileURL || photoPreview ? (
            <>
              <ImageWithFallbacks
                className={styles.previewPhoto}
                src={
                  fileURL
                    ? fileURL
                    : `${process.env.REACT_APP_SERVER_HOST}/storage/${
                      isUserPhoto ? 'photo' : 'live-class'
                    }/${photoPreview}`
                }
                alt='userImg'
                fallbacks={`${photoUserErrHandler(defaultPreview)}`}
              />
              {!fileURL && (
                <div className={styles.previewMask}>
                  <Camera />
                  <div className={styles.photoText}>
                    <h4>Click to add a profile image</h4>
                    <p>JPEG or PNG, no larger than 10MB</p>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <Camera />
              <div className={styles.photoText}>
                <h4>Click to add a profile image</h4>
                <p>JPEG or PNG, no larger than 10MB</p>
              </div>
            </>
          )}
          <input
            {...rest}
            accept='image/png, image/jpeg'
            style={{ display: 'none' }}
            type='file'
            name='photo'
            onChange={(e: any) => {
              maxSizeFile(e.target.files);
            }}
          />
        </label>
      </div>
    </>
  );
};

export default FileInput;
