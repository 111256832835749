import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './MyLiveClasses.module.scss';
import Button from '../../../common/Button/Button';
import { StateType } from '../../../../redux/store';
import {
  getClasses,
  getCategories,
  getLevels,
  filterClasses,
  getUserClasses,
} from '../../../../redux/actions/liveClassesActions';
import ClassesList from '../ClassesList/ClassesList';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { getFilters } from '../../../../helpers/liveClassesfilters';
import { ReactComponent as Camera } from '../../../../assets/svg/liveClasses/camera.svg';
import { alertShowThunk } from '../../../../redux/reducers/alert';
import { user } from '../../../../api/user';
import { updateUserClasses } from '../../../../redux/reducers/liveClasses';
import { Echo } from '../../../..';
import SelectedInput from '../../../common/SelectedInput/SelectedInput';
import { Hidden } from '@material-ui/core';

const statusArr: Array<{ id: number; name: string; isSelected: boolean }> = [
  { id: 0, name: 'Any status', isSelected: true },
  { id: 1, name: 'Upcoming', isSelected: false },
  { id: 2, name: 'Running', isSelected: false },
  { id: 3, name: 'Canceled', isSelected: false },
  { id: 4, name: 'Passed', isSelected: false },
];

const MyLiveClasses: React.FC = (props: any) => {
  const { liveClasses, categories, levels, userClasses } = useSelector((state: StateType) => state.liveClasses);

  const { message } = useSelector((state: StateType) => state.alert);
  const { user } = useSelector((state: StateType) => state.user);
  const query = new URLSearchParams(useLocation().search);
  /* const status = query.get('status[]'); */

  const [filteredClasses, setFilteredClasses] = useState(userClasses);
  const [statusClasses, setStatusClasses] = useState(statusArr);
  const [selectedStatus, setSelectedStatus] = useState({} as any);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    filtrateStatus('Upcoming');
    // dispatch(getUserClasses(null));
    // dispatch(getCategories(null));
    // dispatch(getLevels(null));
  }, []);

  useEffect(() => {
    Echo.channel('LiveClasses').listen('LiveClassUpdated', (e: any) => {
      dispatch(updateUserClasses(e.liveClass));
    });
    return () => {
      Echo.channel('LiveClasses').stopListening('LiveClassUpdated');
    };
  }, []);

  useEffect(() => {
    if (userClasses) {
      // const sortedArr = [...userClasses].sort((a: any, b: any) => {
      //   let aDate = new Date(a.gtm_start_datetime);
      //   let bDate = new Date(b.gtm_start_datetime);
      //   return aDate.getTime() - bDate.getTime();
      // });

      setFilteredClasses(userClasses);
    }
  }, [userClasses]);

  useEffect(() => {
    // message && dispatch(alertShowThunk('success', message));
  }, [message]);

  const filtrateStatus = (status: string) => {
    dispatch(
      getUserClasses({
        filter: { status: [status !== 'Any status' && status] },
      })
    );
    const mapedStatusArr = statusArr.map((el) =>
      el.name === status ? { ...el, isSelected: true } : { ...el, isSelected: false }
    );
    const selected = mapedStatusArr.find((el) => el.isSelected);
    setStatusClasses(mapedStatusArr);
    selected && setSelectedStatus(selected);
  };

  const status = [
    { id: 'any', name: 'Any status' },
    { id: 'Upcoming', name: 'Upcoming' },
    { id: 'Running', name: 'Running' },
    { id: 'Canceled', name: 'Canceled' },
    { id: 'Passed', name: 'Passed' },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchWrapper}>
        <div className={styles.title}>
          <h1>My live classes</h1>
        </div>
        {user.type === 'Instructor' && (
          <Button
            iconLeft={<Camera />}
            children={'Schedule live class'}
            customClass={styles.scheduleButton}
            onClick={() => history.push('/user/schedule-classes')}
          />
        )}
      </div>
      <aside className={styles.switcherClasses}>
        <Hidden smDown>
          {statusClasses.map((el) => {
            return (
              <div
                className={`${el.isSelected ? styles.active : styles.inactive}`}
                onClick={() => filtrateStatus(el.name)}
                key={`${el.id}`}
              >
                {el.name}
              </div>
            );
          })}
        </Hidden>
        <Hidden mdUp>
          <SelectedInput
            name={selectedStatus?.name ? selectedStatus.name : 'Any status'}
            onChange={(e: any) => {
              filtrateStatus(e.name);
            }}
            data={status}
            listStyle={styles.selectedInputList}
            customStyles={styles.selectedInput}
            wrapperStyle={styles.selectedInputWrapper}
          />
        </Hidden>
      </aside>
      <div className={styles.contentWrapper}>
        {filteredClasses && <ClassesList arr={filteredClasses} type={'recommended'} isVertical={true} />}
        {/*  <Button className={styles.loadMoreButton} onClick={loadMore}>
          Load more
        </Button> */}
      </div>
    </div>
  );
};

export default MyLiveClasses;
