import React, { useState, useEffect } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Input from '../../../../../common/Input/Input';
import { ReactComponent as DateIcon } from '../../../../../../assets/svg/date.svg';
import styles from './DatePicker.module.scss';
import moment from 'moment';

import { DateUtils } from 'react-day-picker';
import { currentDate, dateWithTimezone } from '../../../../../../helpers/functions';

const DatePicker = ({ input, defaultValue, handleStartDate, handleEndDate, currDateTime, timezone, ...props }: any) => {
  let currDate = moment().add('hour', 1).format('YYYY-MM-DD HH:mm');
  let currTime = moment().add('hour', 1).format('HH:mm');
  let currDateNtime = dateWithTimezone(currDate, currTime, timezone).toDate();

  const [selectedDate, setSelectedDate] = useState(currDateNtime);

  useEffect(() => {
    let changedDate = moment(selectedDate).format('YYYY-MM-DD');
    input && input.onChange(changedDate);
    handleStartDate && handleStartDate(changedDate);
    handleEndDate && handleEndDate(changedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (defaultValue) {
      const currHour = +moment(currDateTime).format('HH');
      const defValue = dateWithTimezone(defaultValue, currTime, timezone).toDate();
      const datePlusOne = new Date(moment(defValue).add(1, 'day').format('YYYY-MM-DD'));
      currHour === 23 ? setSelectedDate(datePlusOne) : setSelectedDate(defValue);
    }
  }, [defaultValue]);

  const dateReducer = (someDate: any) => {
    return moment(someDate).format('YYYY-MM-DD');
  };

  return (
    <div className={styles.wrapper}>
      <DayPickerInput
        dayPickerProps={{
          disabledDays: { before: currDateNtime },
          modifiers: { today: currDateNtime },
        }}
        inputProps={{ readOnly: true }}
        component={(anyProps: any) => {
          return <Input {...anyProps} defaultValue={dateReducer(selectedDate)} iconStart={<DateIcon />} {...props} />;
        }}
        onDayChange={(day) => setSelectedDate(day)}
      />
    </div>
  );
};
export default DatePicker;
