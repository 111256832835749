import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { useHistory, Link, useParams } from 'react-router-dom';

import Button from '../../../common/Button/Button';

import { signIn } from '../../../../redux/actions/authActions';
import { StateType } from '../../../../redux/store';
import { required } from '../../../../helpers/validate';

import styles from './SelectRole.module.scss';

import { setNewUser } from '../../../../redux/reducers/auth';
import { getSpecificUser } from '../../../../redux/actions/userActions';
import MainLoader from '../../../common/MainLoader/MainLoader';

const SelectRole: React.FC<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile, specificUser } = useSelector((state: StateType) => state.user);
  const { errors, user, newUser } = useSelector((state: StateType) => state.auth);
  const [loading, setLoading] = useState(true);

  const params: { id: string; email: string } = useParams();

  useEffect(() => {
    if ((!user && !specificUser.id) || (user && !user.id && !specificUser.id)) {
      setLoading(true);
      params.id &&
        //@ts-ignore
        dispatch(getSpecificUser(+params.id)).then((res) => {
          setLoading(false);
          return res.payload.data && res.payload?.data?.status === 'Inactive' ? true : history.push(`/`);
        });
    }
  }, [params]);

  useEffect(() => {
    if (newUser && newUser.role) {
      newUser.role && history.push(`/auth/create-account`);
    }
  }, [newUser]);

  const onSetProfile = (value: string) => {
    const userLocal = localStorage.getItem('user');
    const parsedUser = userLocal && JSON.parse(userLocal);

    const userObj = {
      id: params && params.id ? params.id : parsedUser ? parsedUser.id : 0,
      email: params.email && params.email ? params.email : parsedUser ? parsedUser.email : '',
      role: value,
      is_multiroled: false,
    };
    ((params && params.id) || parsedUser) && dispatch(setNewUser(userObj));
  };

  return (
    <div className={styles.wrapper}>
      <MainLoader loading={loading} />
      <h1>Select your role:</h1>
      <div className={styles.roles}>
        <div className={styles.roleWrapper}>
          <div className={styles.image}>
            <img src={require('../../../../assets/images/student.jpg')} alt='student_img' />
          </div>
          <div className={styles.info}>
            <h1>Student</h1>
            <p>Create student account and start watching live class</p>

            <Button onClick={() => onSetProfile('Student')} color='primary' variant='contained'>
              Select
            </Button>
          </div>
        </div>
        <div className={styles.roleWrapper}>
          <div className={styles.image}>
            <img src={require('../../../../assets/images/teacher.jpg')} alt='teacher_img' />
          </div>
          <div className={styles.info}>
            <h1>Instructor</h1>
            <p>Create instructor account and start earning money</p>

            <Button onClick={() => onSetProfile('Instructor')} color='primary' variant='contained'>
              Select
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectRole;
