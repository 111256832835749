import React, { useState, MutableRefObject } from 'react';
import styles from './FlatList.module.scss';
import ImageWithFallbacks from '../../../../../../components/ImageWithFallbacks/ImageWithFallbacks';
import { photoUserErrHandler } from '../../../../../../../helpers/functions';
import PersonIcon from '@material-ui/icons/Person';
import moment from 'moment';

type FlatListType = {
  data: Array<any>;
  containerStyle?: any;
  flatListRef?: MutableRefObject<any>;
  ownerId?: number;
};

const SingleMessage: React.FC<any> = ({ message, user, authorName, timestamp, key, ownerId }) => {
  return (
    <div className={`${styles.singleMessage} ${user.id === ownerId ? styles.owner : ''}`} key={key}>
      <div className={styles.userImg}>
        {user.photo ? (
          <ImageWithFallbacks
            src={`${process.env.REACT_APP_SERVER_HOST}/storage/photo/${user.sm_photo}`}
            alt='user_img'
            fallbacks={`${photoUserErrHandler(user.photo)}`}
          />
        ) : (
          <PersonIcon />
        )}
      </div>
      <p>
        <span className={styles.authorName}>
          {user.first_name + ' ' + user.last_name}
          <span className={styles.timestamp}>{moment(timestamp).format('HH:mm')}</span>
        </span>

        <span className={styles.message}>{message}</span>
      </p>
    </div>
  );
};

const FlatList: React.FC<FlatListType> = ({ data, containerStyle, flatListRef, ownerId }) => {
  return (
    <div className={`${containerStyle ? containerStyle : ''} ${styles.wrapper}`} ref={flatListRef}>
      {data.map((el, index) => {
        return (
          <SingleMessage
            message={el.content}
            key={`${index}`}
            user={el.user}
            timestamp={el.timestamp}
            ownerId={ownerId}
          />
        );
      })}
    </div>
  );
};

export default FlatList;
