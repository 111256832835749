import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, useLocation } from 'react-router-dom';
import Button from '../../../common/Button/Button';
import { StateType } from '../../../../redux/store';
import { IconButton, Hidden } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './Stripe.module.scss';
import { signIn, switchType } from '../../../../redux/actions/authActions';
import { connectWithStripe } from '../../../../redux/actions/userActions';
import { ReactComponent as StripeLogo } from '../../../../assets/svg/stripeLogo.svg';

const Stripe: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, stripe } = useSelector((state: StateType) => state.user);
  const { newUser, password } = useSelector((state: StateType) => state.auth);
  let newUserL = localStorage.getItem('newUser');
  let token = localStorage.getItem('token');
  let parsedUser = newUserL && JSON.parse(newUserL);
  let passwordL = localStorage.getItem('password');

  useEffect(() => {
    if ((passwordL && parsedUser.is_multiroled) || (password && newUser.is_multiroled)) {
      changeRoleAfterSignIn('Instructor', passwordL ? passwordL : password);
    } else if ((parsedUser && passwordL) || (newUser.email && password)) {
      dispatch(
        signIn({
          email: parsedUser ? parsedUser.email : newUser.email,
          password: passwordL ? passwordL : password,
        })
      );
    }
    return () => {
      localStorage.removeItem('password');
    };
  }, []);

  useEffect(() => {
    if (location && !location.state && !passwordL) {
      history.push('/');
    }
  }, [location]);

  useEffect(() => {
    if ((user && user.id && user.type !== 'Instructor') || (user && user.id && user.stripe_account)) {
      history.push('/');
    }
  }, [user]);

  // useEffect below checks the incoming data after click on 'Connect stripe'
  useEffect(() => {
    if (stripe && stripe.url) {
      window.location.href = stripe.url;
      // or instead open in new tab
      // window.open(stripe.link, '_blank');
    }
  }, [stripe]);

  const changeRoleAfterSignIn = async (role: string, pass: string) => {
    await dispatch(
      signIn({
        email: parsedUser.email ? parsedUser.email : newUser.email,
        password: pass,
      })
    );
    // after when user signed in, switch user`s role
    await dispatch(switchType({ type: role }));
  };

  const closeStripe = async () => {
    await history.push(`/user/home`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrapper}>
        <Hidden smDown>
          <IconButton aria-label='close' onClick={() => closeStripe()}>
            <CloseIcon />
          </IconButton>
        </Hidden>
        <div className={styles.internalWrapper}>
          <Hidden mdUp>
            <div className={styles.logo}>
              <StripeLogo />
            </div>
          </Hidden>
          <h1>Connect your account to Stripe</h1>

          <p>We use Stripe to make sure you get paid on time and to keep your personal bank and details secure</p>
          <div className={styles.buttonsWrapper}>
            <Button onClick={() => dispatch(connectWithStripe(null))} color='primary' variant='contained'>
              Connect with Stripe
            </Button>
            <Hidden mdUp>
              <Button onClick={() => closeStripe()} variant='contained' cancel>
                Close
              </Button>
               
            </Hidden>
          </div>
        </div>
      </div>
      <div className={styles.termsOfUse}>
        <span>
          By logging in or creating an account, you agree with our <Link to='/terms-of-use'>Terms of Use </Link>
          and <Link to='/privacy-policy'>Privacy Policy</Link>
        </span>
      </div>
    </div>
  );
};

export default Stripe;
