import React, { useRef, useState, useEffect } from 'react';
import styles from './Categories.module.scss';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { MenuItem, Menu, Checkbox } from '@material-ui/core';
import { ReactComponent as Adjustments } from '../../../../../assets/svg/adjustments.svg';
import { ReactComponent as Close } from '../../../../../assets/svg/close.svg';
import queryString from 'query-string';
import { Hidden, SwipeableDrawer } from '@material-ui/core';
import { usePrevious } from '../../../../../helpers/hooks';

type Categories = {
  categories: Array<any>;
  slctNumber: number;
};

const CategoriesMenu: React.FC<any> = ({ categories, slctNumber }) => {
  const [selectedNumber, setSelectedNumber] = useState(slctNumber);
  const [selectedCats, setCategories] = useState<any>(categories);
  const prevCategories = usePrevious({ categories });
  const prevNumber = usePrevious({ slctNumber });
  const history = useHistory();

  useEffect(() => {
    if (prevCategories !== categories) {
      setCategories(categories);
    }
    if (prevNumber !== slctNumber) {
      setSelectedNumber(slctNumber);
    }
  }, [prevCategories, prevNumber]);

  const selectCategoryHandler = (id: number) => {
    const mapedArr = selectedCats.map((el: any) => {
      if (id === 999) {
        return selectedCats[0].isSelected ? { ...el, isSelected: false } : { ...el, isSelected: true };
      } else {
        return el.id === id ? { ...el, isSelected: !el.isSelected } : el;
      }
    });

    const filteredArr =
      mapedArr &&
      mapedArr
        .filter((el: any) => el.isSelected)
        .map((el: any) => {
          if (el.id !== 999) return el.id;
        });
    const queryStr = queryString.stringify({ categories: filteredArr }, { arrayFormat: 'bracket' });
    history.replace(`home?${queryStr}`);
  };

  return (
    <>
      {selectedCats &&
        selectedCats.map((el: any, index: number) => {
          return (
            <MenuItem
              key={`${el.id}`}
              classes={{
                root: `${styles.categoriesMenuItem} ${el.isSelected && styles.activeItem}`,
              }}
            >
              <div className={styles.categoryTitle}>
                {el.photo && el.id === 999 ? (
                  <img
                    src={require('../../../../../assets/svg/categories/all.svg')}
                    alt={el.name}
                    className={styles.categoryIcon}
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_SERVER_HOST}/storage/category/${el.photo}`}
                    alt={el.name}
                    className={styles.categoryIcon}
                    onError={(err: any) => {
                      return (err.target.src = require('../../../../../assets/images/whiteSquare.png'));
                    }}
                  />
                )}
                <p>{el.name}</p>
              </div>
              <Checkbox
                onClick={() => selectCategoryHandler(el.id)}
                checked={el.isSelected}
                classes={{
                  root: `${styles.menuCheckbox} ${el.isSelected && styles.activeCheckbox}`,
                }}
              />
            </MenuItem>
          );
        })}
    </>
  );
};

export const Categories: React.FC<Categories> = ({ categories, slctNumber }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const EndIcon = ({ value }: any) => {
    return (
      <div className={styles.endIconWrapper}>
        <p>{value}</p>
      </div>
    );
  };

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(anchorRef.current);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    setOpen(false);
  };

  return (
    <div className={styles.categories}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        startIcon={<Adjustments className={styles.startIcon} />}
        endIcon={+slctNumber > 0 && <EndIcon value={slctNumber} className={styles.endIcon} />}
      >
        <p className={styles.text}>Categories</p>
      </Button>
      <Hidden mdUp>
        <SwipeableDrawer
          anchor={'right'}
          open={open}
          onClose={handleClose}
          onOpen={handleClose}
          disableBackdropTransition
          classes={{ root: styles.drawerRoot, paper: styles.drawerPaper }}
        >
          <div className={styles.categoriesHeader}>
            <div>
              <span>Categories </span>
              {Boolean(slctNumber) && <EndIcon value={+slctNumber} className={styles.endIcon} />}
            </div>
            <Close onClick={handleClose} className={styles.closeIcon} />
          </div>
          <ul>
            <CategoriesMenu categories={categories} slctNumber={slctNumber} />
          </ul>
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown>
        <div className={styles.categoriesWrapper}>
          {open && <div className={styles.arrowTop} />}
          <Menu
            id='fade-menu'
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            keepMounted
            open={open}
            onClose={handleClose}
            classes={{ paper: styles.categoriesMenu }}
          >
            <CategoriesMenu categories={categories} slctNumber={slctNumber} />
          </Menu>
        </div>
      </Hidden>
    </div>
  );
};
