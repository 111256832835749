import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form, submit } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';

import Input from '../../../../common/Input/Input';
import { StateType } from '../../../../../redux/store';
import { required, email, password } from '../../../../../helpers/validate';

import styles from './ProfileTab.module.scss';

import { updateProfile } from '../../../../../redux/actions/userActions';
import UserPhoto from '../UserPhoto/UserPhoto';

export type ProfileTabType = {
  firstName: string;
  lastName: string;
  aboutMe: string;
  timezone?: string;
  photo?: string;
};

const ProfileTab: React.FC<InjectedFormProps<ProfileTabType>> = ({ handleSubmit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state: StateType) => state.user);
  const [file, setFile] = useState({} as any);
  const [fileURL, setFileURL] = useState('');
  const [photoString, setPhoto] = useState('' as any);
  const [formObj, setFormObj] = useState<ProfileTabType>({} as ProfileTabType);
  const [timezone, setZone] = useState('');
  const localPhoto = localStorage.getItem('photo');
  const localNewClass = localStorage.getItem('newClass');

  useEffect(() => {
    //  document.getElementsByTagName('input')[1].maxLength = 20;
    return () => {
      setFormObj({} as ProfileTabType);
    };
  }, []);

  useEffect(() => {
    if (user.first_name) {
      setFormObj({
        firstName: user.first_name,
        lastName: user.last_name,
        aboutMe: user.about_me as string,
        timezone: user.timezone as string,
      });
    }
  }, [user]);

  const onSubmit = (zone: string) => {
    handleSubmit((value) => {
      const photoObj = photoString || localPhoto ? { photo: photoString ? photoString : localPhoto } : {};
      dispatch(
        updateProfile({
          first_name: value.firstName,
          last_name: value.lastName,
          about_me: value.aboutMe,
          timezone: value.timezone,
          ...photoObj,
        })
      );
    })();
  };

  return (
    <Form onSubmit={() => onSubmit(timezone)} className={styles.form}>
      <div className={styles.inputs}>
        <div>
          <div>
            <h3>First name *</h3>
            <Field
              component={Input}
              name='firstName'
              label='First name'
              type='text'
              validate={[required]}
              defaultValue={formObj && formObj.firstName}
              maxLength={20}
            />
          </div>
          <div>
            <h3>Last name *</h3>
            <Field
              component={Input}
              name='lastName'
              label='Last name'
              type='text'
              validate={[required]}
              defaultValue={formObj && formObj.lastName}
              maxLength={20}
            />
          </div>
        </div>
        <div>
          <h3>Timezone *</h3>
          <Field
            component={Input}
            name='timezone'
            label='Time zone'
            type='text'
            isTimezone
            setZone={(item: string) => setZone(item)}
            defaultValue={formObj && formObj.timezone}
            maxLength={20}
          />
        </div>
        <div>
          <h3>About me *</h3>
          <Field
            component={Input}
            name='aboutMe'
            label='About me info'
            type='text'
            validate={[required]}
            maxLength={500}
            parentStyle={styles.textArea}
            isMultiline={true}
            rows={5}
            defaultValue={formObj && formObj.aboutMe}
          />
        </div>
        {/*    {errors && <div className='error'>{errors}</div>} */}
      </div>
    </Form>
  );
};

export default reduxForm<ProfileTabType>({
  form: 'profileTab',
})(ProfileTab);
