import React from 'react';
import styles from './AuthorInfo.module.scss';
import { ReactComponent as Copy } from '../../../../../assets/svg/share/copy.svg';
import { ReactComponent as Download } from '../../../../../assets/svg/download.svg';
import { Link as MaterialLink } from '@material-ui/core';
import { ReactComponent as Author } from '../../../../../assets/svg/liveClasses/user.svg';
import { LiveClassType } from '../../../../../redux/reducers/liveClasses';
import { Link } from 'react-router-dom';
import { photoUserErrHandler } from '../../../../../helpers/functions';
import ImageWithFallbacks from '../../../../components/ImageWithFallbacks/ImageWithFallbacks';

type AuthorInfoType = {
  liveClass: LiveClassType;
  instrHasName: string;
  isOwner: boolean;
};

const AuthorInfo: React.FC<AuthorInfoType> = ({ liveClass, instrHasName, isOwner }) => {
  return (
    <div className={`${styles.authorDesc} ${liveClass?.title?.length > 20 ? styles.marginTop : ''}`}>
      <div className={styles.authorImg}>
        {liveClass.user && liveClass.user.sm_photo && liveClass.user.photo && !isOwner ? (
          <ImageWithFallbacks
            src={`${process.env.REACT_APP_SERVER_HOST}/storage/photo/${liveClass.user.sm_photo}`}
            alt='authorLogo'
            fallbacks={`${photoUserErrHandler(liveClass.user.photo)}`}
          />
        ) : isOwner ? (
          <span className={styles.usersCount}>{liveClass.class_users_info.length}</span>
        ) : (
          <Author />
        )}
      </div>

      {isOwner ? (
        <div className={styles.registeredUsers}>
          <h3>Registered students</h3>
        </div>
      ) : (
        <div className={styles.titleAuthor}>
          <Link to={`/user/profile/${liveClass.owner}`}>
            <h3>{instrHasName ? `${liveClass.user.first_name} ${liveClass.user.last_name}` : 'Some Author'}</h3>
          </Link>
          <div className={styles.rating}>
            {/*  <Box component='fieldset' mb={3} borderColor='transparent'>
                      <Rating
                        name='read-only'
                        value={0}
                        readOnly
                        precision={0.5}
                        classes={{
                          iconEmpty: styles.emptyIcon,
                          iconFilled: styles.filledIcon,
                        }}
                      />
                      <p className={styles.score}>{''}</p>
                    </Box> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthorInfo;
