import React, { ReactChild, useState } from 'react';
import styles from './NotSupported.module.scss';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../common/Button/Button';
import { ReactComponent as Ie } from '../../../../../assets/svg/browsers/ie.svg';
import { ReactComponent as Chrome } from '../../../../../assets/svg/browsers/chrome.svg';
import { ReactComponent as Firefox } from '../../../../../assets/svg/browsers/firefox.svg';
import { ReactComponent as Safari } from '../../../../../assets/svg/browsers/safari.svg';
import { ReactComponent as Opera } from '../../../../../assets/svg/browsers/opera.svg';
import { keys } from 'lodash';
import { useHistory } from 'react-router-dom';

const NotSupported = ({ open, onCloseModal, id, correctBrowser }: any) => {
  const [closeModal, setCloseModal] = useState(false);
  const history = useHistory();
  const browserIcons: Array<ReactChild> = [
    <Ie />,
    <Chrome />,
    <Firefox />,
    <Safari />,
    <Opera />,
  ];

  return (
    <Modal
      open={closeModal ? false : !correctBrowser}
      onCloseModal={onCloseModal}
    >
      <div className={styles.wrapper}>
        <div className={styles.textWrapper}>
          <h3>Your browser is not supported</h3>
          <p>
            Currently, the session is only available on desktop Chrome, Firefox
            and Safari.
          </p>
        </div>
        <div className={styles.browsersList}>
          {browserIcons.map((el, index) => {
            return <div key={`${index}`}>{el}</div>;
          })}
        </div>

        <Button
          customClass={styles.gotItButton}
          children={'Got it'}
          onClick={() => history.push(`/user/live-classes/${id}`)}
        />
      </div>
    </Modal>
  );
};

export default NotSupported;
