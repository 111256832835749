import { instance } from './api';

export const promo = {
  getCodes: async () => {
    const { data } = await instance.get(`/promo-codes`);
    return data;
  },
  applyCode: async (values: any) => {
    const { data } = await instance.post(`/promo-codes/apply`, values);
    return data;
  },
};
