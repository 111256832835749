import React from 'react';
import styles from './ErrorPage.module.scss';
import { ReactComponent as PageNotFound } from '../../../assets/svg/404.svg';
import { ReactComponent as Forbidden } from '../../../assets/svg/403.svg';
import { ReactComponent as BadGateway } from '../../../assets/svg/502.svg';
import { useHistory, Link, useLocation } from 'react-router-dom';

const ErrorPage = () => {
  const location: any = useLocation();
  const code: number = location.state && location.state.code;

  const errorPages = () => {
    if (location.state && location.state.code) {
      switch (code) {
        case 404:
          return <PageNotFound />;
        case 403:
          return <Forbidden />;

        case 502:
          return <BadGateway />;

        default:
          break;
      }
    }
  };

  const errorText = () => {
    switch (code) {
      case 404:
        return 'Page Not Found';

      case 403:
        return 'Forbidden';

      case 502:
        return 'BadGateway';

      default:
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      {code ? errorPages() : <PageNotFound />}
      <h1 className={styles.title}>
        <span>{code ? `Error ${code}` : 'Error 404:'}</span> {code ? errorText() : 'Page Not Found'}
      </h1>
      <div className={styles.helper}>
        <span>
          Sorry, the page you're looking for cannot be accessed.
          <br /> Either check the URL, <Link to='/user/home'>go home</Link>.
        </span>
      </div>
    </div>
  );
};

export default ErrorPage;
