import React from 'react';
import styles from '../LiveClass.module.scss';
import { Hidden } from '@material-ui/core';
import { LiveClassHeader } from '../LiveClassHeader/LiveClassHeader';
import AuthorInfo from '../AuthorInfo/AuthorInfo';
import { LiveClassType } from '../../../../../redux/reducers/liveClasses';
import { shareArr } from '../../../../../assets/styles/assets';

type MobileHeaderType = {
  liveClass: LiveClassType;
  userId: number;
};

const MobileHeader: React.FC<MobileHeaderType> = ({ liveClass, userId }) => {
  const instrHasName = liveClass?.user?.first_name && liveClass?.user?.last_name;
  return (
    <Hidden mdUp>
      <div className={`${styles.mobileHeader} ${styles.title}`}>
        <div>
          <LiveClassHeader title={liveClass.title} shareArr={shareArr} />
        </div>
        <AuthorInfo isOwner={liveClass.owner === userId} liveClass={liveClass} instrHasName={instrHasName} />
      </div>
    </Hidden>
  );
};

export default MobileHeader;
