import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { useHistory, Link } from 'react-router-dom';
import Input from '../../../common/Input/Input';
import Button from '../../../common/Button/Button';
import { StateType } from '../../../../redux/store';

import styles from './SearchBar.module.scss';
import { filterClasses } from '../../../../redux/actions/liveClassesActions';

export type SearchBarType = {
  search: string;
};

const SearchBar: React.FC<InjectedFormProps<SearchBarType>> = ({ handleSubmit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state: StateType) => state.user);
  const { errors, newUser, user } = useSelector((state: StateType) => state.auth);

  const onSubmit = (e: any) => {
    e.preventDefault();

    handleSubmit((value) => {
      dispatch(
        filterClasses({
          filter: { fulltext: value.search },
          limit: 5,
          offset: 0,
        })
      );
    })();
  };

  return (
    <Form onSubmit={onSubmit} className={styles.form}>
      <Field
        component={Input}
        name='search'
        label='Search for instructor or live class'
        type='text'
        parentStyle={styles.input}
        isSearch={true}
      />

      <Button type='submit' color='primary' variant='contained'>
        Find
      </Button>
    </Form>
  );
};

export default reduxForm<SearchBarType>({
  form: 'searchBar',
})(SearchBar);
