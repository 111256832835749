import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import DefaultRoute from './routes/DefaultRoute';
import GuestRoute from './routes/hoc/GuestRoute';
import PrivateRoute from './routes/hoc/PrivateRoute';
import MainLoader from './view/common/MainLoader/MainLoader';
import MainLayout from './view/layout/MainLayout/MainLayout';
import DetailsPages from './view/pages/DetailsPage/DetailsPage';
import AboutUsPage from './view/pages/StaticPages/AboutUsPage';
import TermsOfUsePage from './view/pages/StaticPages/TermsOfUse';
import PrivacyPolicyPage from './view/pages/StaticPages/PrivacyPolicy';
import { getProfile, getBalance, signOut, updateProfile } from './redux/actions/userActions';
import { StateType } from './redux/store';
import ErrorPage from './view/pages/ErrorPages/ErrorPage';
import { withSuspense } from './hoc/withSuspense';
import AuthPage from './view/pages/AuthPage/AuthPage';
import MainPage from './view/pages/MainPage/MainPage';
import Alert from './view/common/Alert/Alert';
import moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { alertShowThunk, alertCloseThunk } from './redux/reducers/alert';
import { instance } from './api/api';
import {Echo} from "./index";

//const MainPage = React.lazy(() => import('./view/pages/MainPage/MainPage'));

const App: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const localToken = localStorage.getItem('token');
  const { profile, user, isLoading } = useSelector((state: StateType) => state.user);
  const { token } = useSelector((state: StateType) => state.auth);
  const [loading, setLoading] = useState(true);
  const [alertOnClose, setAlertOnClose] = useState(false);

  let timezoneAlertLocal = localStorage.getItem('timezoneAlertClosed');
  let timezoneAlertClosed = timezoneAlertLocal && JSON.parse(timezoneAlertLocal);
  const getMe = async () => {
    // const token = localStorage.getItem('token');

    if (localToken) {
      localToken &&
        //@ts-ignore
        (await dispatch(getProfile()).then((res) => {
          if (!res?.payload?.timezone) {
            const timezone = momentTimezone.tz.guess(true);
            const timezoneOffset = momentTimezone.tz(timezone).format('Z');
            const timezoneString = `${timezone} (GMT${timezoneOffset})`;
            //@ts-ignore
            dispatch(updateProfile({ timezone: timezoneString })).then(() => {
              dispatch(alertCloseThunk());
            });
          }
        }));
      await dispatch(getBalance());
    }

    setLoading(false);
  };

  useEffect(() => {
    const interceptor = instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          const token = localStorage.getItem('token');
          if (token) {
            logout().then(() => dispatch(alertShowThunk('error', 'You need to be authorized to perform this action')));
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      instance.interceptors.request.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    getMe();
    /* WebSockets listeners example */
    /*Echo.channel('testChannel')
      .listen('WebsocketsTest', (e: any) => {
        console.log('WebsocketsTest');
        console.log(e);
      });

    const privateChannel = Echo.join(`privateChannel`)
      .here((users: any) => {
        console.log('privateChannel here');
        console.log(users);
      })
      .joining((user:any) => {
        console.log('privateChannel joining');
        console.log(user.name);
      })
      .leaving((user:any) => {
        console.log('privateChannel leaving');
        console.log(user.name);
      });

    //@ts-ignore
    privateChannel.listen('WebsocketsPrivateTest', (e:any) => {
      console.log('WebsocketsPrivateTest');
      console.log(e);
    });*/
    /* End of WebSockets listeners example */
  }, []);

  useEffect(() => {
    if (user.timezone) {
      const userTimezone = moment().utcOffset(user.timezone.slice(-7, -1)).utcOffset();

      const currDate = moment().utcOffset();

      if (userTimezone !== currDate && !timezoneAlertClosed) {
        dispatch(
          alertShowThunk(
            'warning',
            'Warning! Please note your browser`s time zone doesn’t match the one in your profile. This may affect the time of live classes.',
            Number(0)
          )
        );
        setAlertOnClose(true);
      }
    }
  }, [user]);

  useEffect(() => {
    if (token || localToken) {
      Echo.options.auth.headers.Authorization = `Bearer ${token??localToken}`;
      setLoading(true);
      getMe();
    }
  }, [token, localToken]);

  const closeTimezoneHandler = () => {
    localStorage.setItem('timezoneAlertClosed', String(true));
  };

  const logout = async () => {
    await dispatch(signOut());
    history.push('/auth/sign-in');
  };

  return (
    <>
      <Alert
        onClose={() => {
          alertOnClose && closeTimezoneHandler();
        }}
      />
      <MainLoader loading={loading} />
      {!loading && (
        <Switch>
          <Route path='/' exact component={DefaultRoute} />

          <GuestRoute path='/auth/:type?/:id?/:email?' exact component={AuthPage} />

          <PrivateRoute path='/user/:category?/:id?/:stream?' exact component={MainPage} />

          {/*   <Route path='/about-us' exact render={() => <AboutUsPage />} /> */}
          <Route
            path='/terms-of-use'
            exact
            render={() => (
              <MainLayout>
                <TermsOfUsePage />
              </MainLayout>
            )}
          />
          <Route
            path='/privacy-policy'
            exact
            render={() => (
              <MainLayout>
                <PrivacyPolicyPage />
              </MainLayout>
            )}
          />

          {/* 

              <PrivateRoute
                path="/details/:category?/:id?"
                exact
                component={DetailsPages}
              /> */}
          <Route
            path='*'
            render={() => (
              <MainLayout>
                <ErrorPage />
              </MainLayout>
            )}
          />

          <Redirect to='/' />
        </Switch>
      )}
    </>
  );
};

export default App;
