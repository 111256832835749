import { debug } from 'console';
import { liveClasses } from './../../api/liveClasses';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateFiltersStorage } from '../../helpers/liveClassesfilters';
import { stream } from '../../api/stream';

export const getClasses = createAsyncThunk(
  'user/home-classes',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.getClasses(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getCategories = createAsyncThunk(
  'user/home',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.getCategories();
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getLevels = createAsyncThunk(
  'classLevels',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.getLevels();
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
export const filterClasses = createAsyncThunk(
  'filterClasses',
  async (values: any | {} | null, thunkAPI) => {
    try {
      updateFiltersStorage(values.filter);
      let allValues = { ...values.filter };
      let { limit, offset, order, ...filter } = allValues;

      const data = await liveClasses.filter({ filter, offset, limit, order });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getClass = createAsyncThunk(
  'getClass',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.getClass(values);

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getInstrClasses = createAsyncThunk(
  'getInstrClasses',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const start_date = { start_date: values.startDate };
      const end_date = { end_date: values.endDate };

      const { data } = await liveClasses.filter({
        filter: {
          owner: values.id,
          status: values?.status,
          ...start_date,
          ...end_date,
        },
      });
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getUserClasses = createAsyncThunk(
  'user/user-classes',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.getUserClasses(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getCategoriesClasses = createAsyncThunk(
  'user/categories-classes',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.getCategoriesClasses(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const subscribeToClass = createAsyncThunk(
  'subscribeToClass',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.subscribeToClass(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const unsubscribeToClass = createAsyncThunk(
  'unsubscribeToClass',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.unsubscribeToClass(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const cancelInstructorClass = createAsyncThunk(
  'cancelInstructorClass',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.cancelInstructorClass(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
export const editInstructorClass = createAsyncThunk(
  'editInstructorClass',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.editInstructorClass(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
export const scheduleClass = createAsyncThunk(
  'scheduleClass',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.scheduleClass(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const createStreamSession = createAsyncThunk(
  'createStreamSession',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await stream.createSession(values);

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const saveStream = createAsyncThunk(
  'saveStream',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { id, fields } = values;
      const { data } = await stream.saveStream(id, fields);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const joinClass = createAsyncThunk(
  'joinClass',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { id, fields } = values;
      const { data } = await stream.joinClass(id, fields);

      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const leaveClass = createAsyncThunk(
  'leaveClass',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { id, fields } = values;
      const { data } = await stream.leaveClass(id, fields);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getClassUsers = createAsyncThunk(
  'getClassUsers',
  async (id: number, thunkAPI) => {
    try {
      const { data } = await stream.getClassUsers(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getClassUsersInfo = createAsyncThunk(
  'getClassUsersInfo',
  async (id: number, thunkAPI) => {
    try {
      const { data } = await liveClasses.getClass(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const updateClass = createAsyncThunk(
  'updateClass',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { id, fields } = values;
      const { data } = await liveClasses.updateClass(id, fields);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const editClass = createAsyncThunk(
  'editClass',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.editClass(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const editClassPhoto = createAsyncThunk(
  'editClassPhoto',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.editClassPhoto(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const createLiveClass = createAsyncThunk(
  'createLiveClass',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.createLiveClass(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getClassCommission = createAsyncThunk(
  'getClassCommission',
  async (values: any | {} | null, thunkAPI) => {
    try {
      const { data } = await liveClasses.getClassCommission();
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getClassChatMessages = createAsyncThunk(
  'getClassChatMessages',
  async (id: number, thunkAPI) => {
    try {
      const { data } = await liveClasses.getClassChatMessages(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const sendClassChatMessage = createAsyncThunk(
  'sendClassChatMessage',
  async (values: any, thunkAPI) => {
    try {
      const { classId, fields } = values;
      const { data } = await liveClasses.sendClassChatMessage(classId, fields);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);