import {
  getCodes,
  applyCode,
} from './../actions/promoActions';
import { createSlice } from '@reduxjs/toolkit';

type PromoCodeType = {
  id: number;
  code: string;
  cost: number,
  active: boolean,
};

type PromoType = {
  codes: Array<PromoCodeType>;
  errors: any;
}

const initialState: PromoType = {
  codes: [],
  errors: null
};

const promoSlice = createSlice({
  name: 'promo',
  initialState,
  reducers: {},
  extraReducers: {
    [getCodes.pending.toString()]: (state) => {
      state.errors = null;
    },
    [getCodes.fulfilled.toString()]: (state, { payload }) => {
      state.codes = [...payload];
    },
    [getCodes.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.errors;
    },
    [applyCode.pending.toString()]: (state) => {
      state.errors = null;

    },
    [applyCode.fulfilled.toString()]: (state, { payload }) => {
      state.errors = null;
    },
    [applyCode.rejected.toString()]: (state, { payload }) => {
      state.errors = payload;
    },
  },
});

export const {} = promoSlice.actions;

const promoReducer = promoSlice.reducer;

export default promoReducer;
