import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  Redirect,
  useParams,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import { StateType } from '../../redux/store';

type PrivateRouteProps = {
  exact: boolean;
  path: string;
  component: any;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const localToken = localStorage.getItem('token');
  const { token } = useSelector((state: StateType) => state.auth);
  const location = useLocation();
  const match: any = useRouteMatch('/user/:category/:id?/:stream?');

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          token ||
          localToken ||
          location.pathname === '/user/home' ||
          location.pathname === '/user/instructors-list' ||
          (match?.params.category === 'live-classes' &&
            !match?.params.stream) ||
          ((!token || !localToken) &&
            match?.params.category === 'profile' &&
            match?.params.id)
        ) {
          return <Component {...props} />;
        }
        {
          return <Redirect to='/user/home' />;
        }
      }}
    />
  );
};

export default PrivateRoute;
