import { password } from './../../helpers/validate';
import { setUserType, googleAuth } from './../actions/authActions';
import { signOut } from './../actions/userActions';
import { ProfileType } from './user';

import { createSlice } from '@reduxjs/toolkit';

import { signUp, signIn, updateUser, confirmUser, resetPassword } from '../actions/authActions';

type ResetPasswordType = {
  message: string;
  success: boolean;
};

type AuthStateType = {
  user: any;
  token: any;
  isLoading: boolean;
  errors: any;
  isUserConfirmed: boolean;
  newUser: ProfileType;
  confirm_code: string;
  user_created: boolean;
  resetPass: ResetPasswordType;
  googleSignUp: GoogleSignUpType;
  password: string;
};

export type GoogleSignUpType = {
  confirm_code: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  status: string;
};

type UserType = {
  about_me: string;
  card_brand: string;
  card_last_four: string;
  confirm_code: number;
  confirm_code_expire: number;
  direct_message: string;
  email: string;
  first_name: string;
  google_id: string;
  id: number;
  is_agree_terms: 0;
  last_name: string;
  password: string;
  phone_number: string;
  photo: string;
  status: string;
  stripe_id: string | number;
  timezone: string;
  trial_ends_at: number;
  twitter_id: string;
};

const initialState: AuthStateType = {
  user: null,
  token: null,
  isLoading: false,
  errors: null,
  isUserConfirmed: false,
  newUser: {} as ProfileType,
  confirm_code: '',
  user_created: false,
  resetPass: {} as ResetPasswordType,
  googleSignUp: {} as GoogleSignUpType,
  password: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    setNewUser(state, { payload }) {
      state.newUser = { ...state.newUser, ...payload };

      localStorage.setItem('newUser', JSON.stringify({ ...state.newUser, ...payload }));
    },
    setConfirmCode(state, { payload }) {
      state.confirm_code = payload;

      localStorage.setItem('confirm_code', payload);
    },

    setError(state, { payload }) {
      state.errors = payload;
    },
    setResetPass(state, { payload }) {
      state.resetPass = payload;
    },
    clearNewUser(state) {
      localStorage.removeItem('user');
      state.newUser = {} as ProfileType;
      state.user = {} as UserType;
    },
    setPassword(state, { payload }) {
      state.password = payload;
    },
  },
  extraReducers: {
    [signUp.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [signUp.fulfilled.toString()]: (state, { payload }) => {
      localStorage.setItem('user', JSON.stringify(payload));
      state.isLoading = false;
      // state.token = payload.access_token;
      state.user = payload;
    },
    [signUp.rejected.toString()]: (state, { payload }) => {
      localStorage.clear();
      state.isLoading = false;
      state.errors = payload.error;
    },
    [signIn.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [signIn.fulfilled.toString()]: (state, { payload }) => {
      localStorage.setItem('token', payload.access_token);
      localStorage.removeItem('password');
      localStorage.removeItem('confirm_code');
      localStorage.removeItem('googleUser');
      state.token = payload.access_token;
      state.isLoading = false;
      state.newUser = {} as ProfileType;
      state.password = '';
    },
    [signIn.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.error;
      state.user = null;
      state.newUser = {} as ProfileType;
      state.confirm_code = '';
      state.isLoading = false;
      state.password = '';
    },
    [signOut.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [signOut.fulfilled.toString()]: (state, { payload }) => {
      state.token = null;
      state.isLoading = false;
      state.user_created = false;
      state.newUser = {} as ProfileType;
      state.user = {} as ProfileType;
      state.googleSignUp = {} as GoogleSignUpType;
      state.password = '';
    },
    [signOut.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.error;
      state.isLoading = false;
      state.password = '';
    },
    [updateUser.pending.toString()]: (state) => {
      /*   state.isLoading = true;
      state.errors = null; */
    },
    [updateUser.fulfilled.toString()]: (state, { payload }) => {
      /*  localStorage.setItem('token', payload.access_token);
      state.token = payload.access_token;
      state.user = payload;
      state.isLoading = false; */
    },
    [updateUser.rejected.toString()]: (state, { payload }) => {
      /* state.errors = payload;
      state.isLoading = false; */
    },
    [confirmUser.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [confirmUser.fulfilled.toString()]: (state, { payload }) => {
      state.user = payload;
      state.user_created = true;
      state.isLoading = false;
    },
    [confirmUser.rejected.toString()]: (state, { payload }) => {
      state.user_created = false;
      state.errors = payload;
      state.isLoading = false;
    },
    [setUserType.pending.toString()]: (state) => {
      state.isLoading = true;
      state.user_created = false;
      state.errors = null;
    },
    [setUserType.fulfilled.toString()]: (state, { payload }) => {
      state.user = payload;
      state.user_created = true;
      state.isLoading = false;
    },
    [setUserType.rejected.toString()]: (state, { payload }) => {
      state.user_created = false;
      state.errors = payload.message;
      state.isLoading = false;
    },
    [resetPassword.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [resetPassword.fulfilled.toString()]: (state, { payload }) => {
      state.resetPass = { ...payload.data };
    },
    [resetPassword.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.message;
      state.resetPass = { ...payload };
      state.isLoading = false;
    },
    [googleAuth.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [googleAuth.fulfilled.toString()]: (state, { payload }) => {
      if (payload.access_token) {
        localStorage.setItem('token', payload.access_token);
        state.token = payload.access_token;
        state.isLoading = false;
      } else {
        if (payload.data) {
          let stringifyGoogleSignUp = JSON.stringify(payload.data);
          localStorage.setItem('googleUser', stringifyGoogleSignUp);
          state.googleSignUp = payload.data;
          state.isLoading = false;
        } else {
          localStorage.setItem('user', JSON.stringify(payload));
          state.isLoading = false;
          state.user = payload;
        }
      }
    },
    [googleAuth.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.error;
      state.user = null;
      state.newUser = {} as ProfileType;
      state.confirm_code = '';
      state.isLoading = false;
      state.googleSignUp = {} as GoogleSignUpType;
      localStorage.removeItem('googleUser');
      localStorage.removeItem('user');
    },
  },
});

export const {
  setStateValue,
  setNewUser,
  setConfirmCode,
  setError,
  setResetPass,
  clearNewUser,
  setPassword,
} = authSlice.actions;

const authReducer = authSlice.reducer;

export default authReducer;
